import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from 'react'

import { useRegisterRoomAction } from '../../hooks'

interface RoomActionProviderProps extends PropsWithChildren {}

const SetRoomActionsContext = createContext<Dispatch<SetStateAction<string>>>(() => {})
const SetRoomActionActiveContext = createContext<Dispatch<SetStateAction<boolean>>>(() => {})

export const RoomActionsProvider: FC<RoomActionProviderProps> = ({ children }) => {
    // id for room to be displayed inside command pallette
    const [roomId, setRoomId] = useState('')
    // whether or not the room has focus and should register the room actions
    const [isActive, setIsActive] = useState(true)
    useRegisterRoomAction({ roomId, isActive })

    return (
        <SetRoomActionsContext.Provider value={setRoomId}>
            <SetRoomActionActiveContext.Provider value={setIsActive}>{children}</SetRoomActionActiveContext.Provider>
        </SetRoomActionsContext.Provider>
    )
}

export const useSetRoomActionsContext = () => useContext(SetRoomActionsContext)
export const useSetRoomActionsActiveContext = () => useContext(SetRoomActionActiveContext)
