import { ComponentProps, FC, useEffect, useMemo, useRef, useState } from 'react'

import { isMessage } from '@closer/utils'
import { TimelinesQueryConfig } from '@closer/headless-components/hooks'
import { RoomTimelinesProps as HeadlessProps, RoomTimelines as HeadlessTimelines, MessageBubbleDataProps, RoomTimelinesRenderableData } from '@closer/headless-components/components/Room'

import { LoadingModal, LoadingSpinner } from '../Common'

import { MessageBubble } from './MessageBubble'
import { RoomNameChangeAnnouncement } from './RoomNameChangeAnnouncement'

interface RoomTimelinesProps extends Pick<TimelinesQueryConfig, 'roomId'> {
    //
}

/**
 * The amount of pixels, less than which, more timelines will be fetched
 */
const infiniteFetchBuffer = 200
export const RoomTimelines: FC<RoomTimelinesProps> = ({ roomId }) => {
    const headlessProps: HeadlessProps = {
        roomId,
        platform: 'web',
        render: renderableData => <RoomTimelinesContent roomId={roomId} {...renderableData} />
    }

    return <HeadlessTimelines {...headlessProps} />
}

/**
 * RoomTimelinesContent is a React Component for displaying a chat room timeline.
 *
 * It maintains its own internal state for fetching and handling loading of data and also
 * control the scroll positions within the timeline. More specifically, as messages are loaded
 * from the server, it includes features such as infinite scrolling (loading more messages as user scrolls),
 * the ability to fetch more messages, manage a collection of in-flight messages and handle different type
 * messages like normal chat messages, stickers, and changes to the room name.
 *
 * It is designed to work with data type ParsedMessageResponse from the Matrix API.
 *
 * @component
 *
 * @param {RoomTimelinesProps & RoomTimelinesRenderableData} props - The properties that define the component.
 * @param {string} props.roomId - The identifier of the room.
 * @param {InfiniteData<ParsedMessageResponse | null | undefined> | undefined} props.data - The data for the room.
 * @param {boolean} props.canFetchMore - The boolean indicative of the ability to fetch more events.
 * @param {boolean} props.isBusy - The boolean indicative of the loading state.
 * @param {Array<RoomEvent<IMessageContent>>} props.sendingEvents - Array of RoomEvents that the room is sending.
 * @param {Error} props.error - The error message.
 * @param {FetchNextPageOptions} props.fetchNextPage - Function to fetch the next page.
 * @param {(eventId: string) => Promise<number>} props.fetchUntil - Function to fetch until a certain event ID.
 *
 * @returns {JSX.Element} The RoomTimelinesContent component.
 */
const RoomTimelinesContent = ({ roomId, data, isBusy, canFetchMore, sendingEvents, fetchNextPage, fetchUntil }: RoomTimelinesProps & RoomTimelinesRenderableData): JSX.Element => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const fillerRef = useRef<HTMLDivElement>(null)
    const scrollRef = useRef<{ height: number; top: number }>({ height: 0, top: 0 })
    const [isFetchingRelated, setIsFetchingRelated] = useState(false)
    const _fetchUntil: MessageBubbleDataProps['fetchUntil'] = useMemo(
        () => ({
            start: async id => {
                if (isFetchingRelated) {
                    return -1
                }
                setIsFetchingRelated(true)
                return fetchUntil(id)
            },
            onSuccess: async fetchedEventId => {
                let counter = 10
                const interval = setInterval(() => {
                    const targetElement = containerRef.current?.querySelector(`#${fetchedEventId}`)
                    if (targetElement) {
                        setIsFetchingRelated(false)
                        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
                        const scrollListener = () => clearInterval(interval)
                        containerRef.current && containerRef.current.addEventListener('scroll', scrollListener)
                        if (targetElement.getBoundingClientRect().top >= 0) {
                            clearInterval(interval)
                            containerRef.current && containerRef.current.removeEventListener('scroll', scrollListener)
                        }
                    }
                    if (counter <= 0) {
                        setIsFetchingRelated(false)
                        clearInterval(interval)
                    }
                    counter--
                }, 500)
            }
        }),
        [fetchUntil, isFetchingRelated]
    )
    const timelines = useMemo(() => {
        if (!data) {
            return
        }

        return data.pages.map(page => {
            if (!page) {
                return []
            }

            return page.chunk.reduce((acc, event) => {
                if (isMessage(event, ['m.room.message', 'm.sticker'])) {
                    if (event.content['m.relates_to']?.rel_type !== 'm.replace') {
                        const bubbleProps: MessageBubbleDataProps = {
                            event,
                            fetchUntil: _fetchUntil
                        }
                        acc.push(<MessageBubble {...bubbleProps} key={event.event_id} />)
                    }
                }
                // handle room name change announcement
                else if (isMessage(event, ['m.room.name'])) {
                    acc.push(<RoomNameChangeAnnouncement {...event} key={event.event_id} />)
                }
                // keep around for easy debugging
                else {
                    // acc.push(
                    //     <p onDoubleClick={() => console.log(event)} key={event.event_id}>
                    //         {event.type}
                    //     </p>
                    // )
                }

                return acc
            }, [] as Array<JSX.Element>)
        })
    }, [_fetchUntil, data])
    const containerProps: ComponentProps<'div'> = {
        id: roomId,
        className: 'grid grid-rows-[1fr] p-5 overflow-x-hidden overflow-y-auto',
        onScroll: ({ currentTarget }) => {
            const prevScrollTop = scrollRef.current.top

            // store scroll information every frame
            scrollRef.current = {
                height: currentTarget.scrollHeight,
                top: currentTarget.scrollTop
            }

            if (canFetchMore && !isBusy && prevScrollTop > currentTarget.scrollTop && scrollRef.current.top < infiniteFetchBuffer) {
                fetchNextPage()
            }
        }
    }

    useEffect(() => {
        if (containerRef.current) {
            // initialise the scroll height on first paint
            if (scrollRef.current.height === 0) {
                scrollRef.current.height = containerRef.current.scrollHeight
            }

            scrollRef.current.top += containerRef.current.scrollHeight - scrollRef.current.height
            scrollRef.current.height = containerRef.current.scrollHeight

            containerRef.current.scrollTop =
                scrollRef.current.top === 0 && !data
                    ? // scroll to bottom on first fetch
                      containerRef.current.scrollHeight
                    : scrollRef.current.top
        }

        // do work only when data fetching has settled & data is contentful
        if (isBusy || !data || !data.pages[data.pages.length - 1]) {
            return
        }

        if (canFetchMore && fillerRef.current?.clientHeight) {
            fetchNextPage()
        }
    }, [canFetchMore, data, fetchNextPage, isBusy, sendingEvents.length])

    return (
        <div {...containerProps} ref={containerRef}>
            <div ref={fillerRef} />
            <LoadingSpinner size={4} hidden={!isBusy} />
            <LoadingModal visible={isFetchingRelated} title='Loading' />
            {timelines && [...timelines].reverse()}
            {sendingEvents.map(event => (
                <MessageBubble event={event} key={event.event_id} fetchUntil={_fetchUntil} />
            ))}
        </div>
    )
}
