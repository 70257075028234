import { FC, useState } from 'react'

import { ChatRoomSummary } from '@closer/watermelondb'
import { makeSendableContent } from '@closer/utils'
import { IMessageContent, RoomEvent } from '@closer/types'

import { useChatRoomSummaries, useMatrix } from '../..'

interface RenderableData {
    chatRoomSummaries: ChatRoomSummary[]
    isSending: boolean
    forwardEvent: (roomId: string) => void
}
interface RenderProps {
    render: (data: RenderableData) => any
}

export interface ForwardListDataProps {
    event: RoomEvent<IMessageContent>
    filter?: string
}

export interface ForwardListProps extends RenderProps, ForwardListDataProps {
    onOpenRoom: (roomId: string) => void
    onCaptureError: (error: unknown) => void
}

export const ForwardList: FC<ForwardListProps> = ({ render, event, filter, onOpenRoom, onCaptureError }) => {
    const { chatRoomSummaries } = useChatRoomSummaries(filter || '')
    const { client } = useMatrix()
    const [isSending, setIsSending] = useState(false)
    const forwardEvent = async (roomId: string) => {
        if (!client || isSending) {
            return
        }

        setIsSending(true)

        let content = event.content

        if ('formatted_body' in event.content) {
            const { formatted_body, format, related_event, ...rest } = event.content
            content = rest
        }

        await client
            .sendEvent(roomId, event.type, makeSendableContent(content, [], undefined, { useHtmlFormat: true }))
            .then(() => {
                setIsSending(false)
                onOpenRoom(roomId)
            })
            .catch(error => {
                onCaptureError(error)
            })
    }

    return render({ chatRoomSummaries, isSending, forwardEvent })
}
