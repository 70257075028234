import dayjs from 'dayjs'
import Calendar, { CalendarProps } from 'react-calendar'

import { ComponentProps, FC, useRef } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { formatDate } from '@closer/utils'
import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { getNextOrPassedReminderTime, ReminderEditor as HeadlessEditor, ReminderEditorProps as HeadlessProps, reminderApi, ReminderEditorRenderProps } from '@closer/headless-components/components/Reminders'
import { R, RemindItem } from '@closer/types'

import { useRouterWithBreadcrumb, useValidRoute } from '../../hooks/router'

import { Svg, SvgProps } from '../Common/Svg'

interface ReminderEditorContentProps extends Omit<HeadlessProps, keyof ReminderEditorRenderProps> {
    //
}

const buttonStyle = 'rounded-xl bg-[#00000008] text-lg font-bold p-2'
const iconProps: SvgProps = {
    stroke: 'currentColor',
    fill: 'none',
    className: 'w-4 h-4'
}

export const ReminderEditor: FC = () => {
    const { data } = useQuery([R.REMIND_ITEMS], () => reminderApi.read({ status: 'active', isDone: state => !state }))
    const { x, r, isValid } = useValidRoute([
        {
            f: 'RoomList',
            x: _x => _x === 'NewReminder' || _x === 'EditReminder'
        },
        {
            f: 'Reminders',
            x: _x => _x === 'Create' || _x === 'Edit'
        }
    ])

    if (isValid) {
        if (x === 'Create' || x === 'NewReminder') {
            return <ReminderEditorContent reminder={{ roomId: r }} />
        }

        if (data && (x === 'Edit' || x === 'EditReminder')) {
            const reminder = data.find(({ id }) => id === r)

            if (reminder) {
                return <ReminderEditorContent reminder={reminder} />
            }
        }
    }

    return null
}

// TODO: indicate whether the reminder can be saved & enable/disable the save button accordingly
const ReminderEditorContent: FC<ReminderEditorContentProps> = ({ reminder, smartflow }) => {
    const router = useRouterWithBreadcrumb()
    const queryClient = useQueryClient()
    const { f, x, r } = useValidRoute()
    const { updateChatRoomSummary } = useWDBOps()
    const hourRef = useRef<HTMLInputElement>(null)
    const minuteRef = useRef<HTMLInputElement>(null)
    const now = new Date()
    const handleReminderMutation = async (response: RemindItem) => {
        await updateChatRoomSummary(response.roomId, getNextOrPassedReminderTime(response, queryClient))

        // redirect to room after creating reminder from room list
        if (x === 'NewReminder' && r.startsWith('!')) {
            router.replace({ f, x: 'View', r })
        }
        // redirect to room after editing reminder from room screen
        else if (x === 'EditReminder') {
            const previousUrlState = router.breadcrumb.current[router.breadcrumb.current.length - 2] || {}
            const originIsRoomList = previousUrlState.f === 'RoomList' && previousUrlState.x === 'View'
            const originIsReminders = previousUrlState.f === 'Reminders' && previousUrlState.x === 'ViewRoom'

            if (originIsRoomList || originIsReminders) {
                router.back()
            }
        }
        // redirect to edit route
        else if (x === 'Create') {
            router.replace({ f, x: 'Edit', r: response.id })
        }
    }
    const headlessProps: HeadlessProps = {
        reminder,
        smartflow,
        renderEditor: ({ isMutating, roomName, title, timePreset, presetResolvers, editingTime, isPickingTime, setTitle, setTimePreset, setEditingTime, hideTimePicker, saveReminder }) => {
            const titleInputProps: ComponentProps<'textarea'> = {
                className: 'border-[#56BA8E] focus:border-[#56BA8E] focus:ring-0 rounded-xl min-h-[42px]',
                value: title,
                onChange: evt => setTitle(evt.target.value)
            }
            const saveButtonProps: ComponentProps<'button'> = {
                disabled: isMutating,
                className: `${buttonStyle} ${isMutating ? 'text-neutral-400' : 'text-[#56BA8E]'}`,
                onClick: () => {
                    const issue = saveReminder()

                    if (issue && issue.timeIsTooSoon) {
                        // TODO: use branded alert
                        // eslint-disable-next-line no-alert
                        return alert('Reminder should be set at least 1 minute from now')
                    }
                }
            }
            const confirmButtonProps: ComponentProps<'button'> = {
                className: `${buttonStyle} text-[#56BA8E] w-full mt-2`,
                onClick: () => {
                    if (!hourRef.current || !minuteRef.current) {
                        return
                    }

                    setEditingTime(dayjs(editingTime).startOf('day').add(Number(hourRef.current.value), 'hours').add(Number(minuteRef.current.value), 'minutes').toDate())
                    hideTimePicker()
                }
            }
            const cancelButtonProps: ComponentProps<'button'> = {
                className: `${buttonStyle} text-[#FF3B30] w-full mt-5`,
                onClick: hideTimePicker
            }
            const calendarProps: CalendarProps = {
                value: editingTime,
                minDate: now,
                minDetail: 'decade',
                prevLabel: <Svg {...iconProps} d='M15.75 19.5L8.25 12l7.5-7.5' />,
                prev2Label: <Svg {...iconProps} d='M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5' />,
                nextLabel: <Svg {...iconProps} d='M8.25 4.5l7.5 7.5-7.5 7.5' />,
                next2Label: <Svg {...iconProps} d='M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5' />,
                onChange: setEditingTime
            }
            const editor = (
                <div className='grid gap-4'>
                    <div className='grid grid-cols-[1fr_min-content] gap-2 items-center text-right text-[#56BA8E] cursor-pointer' onClick={() => router.push({ f: 'Reminders', x: 'ViewRoom', r: reminder.roomId })}>
                        <span>{roomName}</span>
                        <Svg {...iconProps} strokeWidth={3} className='w-3 h-3' d='M8.25 4.5l7.5 7.5-7.5 7.5' />
                    </div>
                    <textarea {...titleInputProps} />
                    <div className='bg-[#00000008] rounded-xl overflow-hidden'>
                        {presetResolvers.map(([preset, getTime], idx) => {
                            const presetProps = {
                                className: `grid grid-cols-[1fr_min-content] place-items-[center_center] gap-4 py-3 cursor-pointer ${preset === 'pick date & time' ? '' : 'border-b-[1px] border-[#00000025]'} ${preset === timePreset ? 'bg-[#00000010]' : ''}`,
                                onClick: () => {
                                    setTimePreset(preset)

                                    if (idx !== presetResolvers.length - 1) {
                                        setEditingTime(new Date(getTime()))
                                    }
                                }
                            }

                            return (
                                <div {...presetProps} key={`${idx}`}>
                                    <span className=' pl-4 whitespace-nowrap' key={`${idx}-0`}>{`${preset.charAt(0).toUpperCase()}${preset.slice(1)}`}</span>
                                    {(preset !== 'pick date & time' || preset === timePreset) && (
                                        <span className=' text-[#737D8C] text-xs pr-4 pt-[3px] whitespace-nowrap' key={`${idx}-1`}>
                                            {formatDate(preset === 'pick date & time' ? editingTime : getTime())}
                                        </span>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <button {...saveButtonProps}>Save</button>
                </div>
            )
            const timePicker = (
                <div>
                    <Calendar {...calendarProps} />
                    <div className='grid grid-cols-[42px_min-content_52px] place-content-end rounded-lg gap-1 py-2 pr-2 mt-2 ml-[160px] overflow-hidden bg-[#00000008]'>
                        <input className='bg-transparent text-lg font-medium text-center p-0 border-none focus:ring-0' defaultValue={editingTime.getHours()} type='number' min='0' max='23' ref={hourRef} />
                        <span className='font-bold'>:</span>
                        <input className='bg-transparent text-lg font-medium text-center p-0 pl-[10px] border-none focus:ring-0' defaultValue={editingTime.getMinutes()} type='number' min='0' max='59' ref={minuteRef} />
                    </div>
                    <button {...cancelButtonProps}>Cancel</button>
                    <button {...confirmButtonProps}>Confirm</button>
                </div>
            )

            return <div className='w-[300px] mx-auto px-2 py-10 h-full overflow-y-auto'>{isPickingTime ? timePicker : editor}</div>
        },
        renderError: error => <p>{error.message}</p>,
        onCreateReminder: handleReminderMutation,
        onUpdateReminder: handleReminderMutation
    }

    return <HeadlessEditor {...headlessProps} />
}
