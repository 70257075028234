import { Q } from '@nozbe/watermelondb'
import { useDrop } from 'react-dnd'
import { useMutation } from '@tanstack/react-query'
import { FC, useCallback, useEffect, useState } from 'react'

import { AccountRoomTagData } from '@closer/types'
import { api } from '@closer/api'
import { staticTags } from '@closer/headless-components/components/LabelScrollView'
import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { AccountRoomTag, RoomLabelRecord, TableName } from '@closer/watermelondb'

import { captureException } from '@sentry/nextjs'
import { LabelCreatingCard } from './LabelCreatingCard'
import { LabelCard, LabelCardProps } from './LabelCard'
interface LabelListType {
    accountRoomTags: AccountRoomTag[]
    isCreating: boolean
    onCompleteCreate: (completed: boolean) => void
    setCurrentRoomTag: (tag: AccountRoomTag) => void
}

export const LabelList: FC<LabelListType> = ({ accountRoomTags, isCreating, onCompleteCreate, setCurrentRoomTag }) => {
    const [tmpAccountRoomTags, setTempAccountRoomTags] = useState<AccountRoomTag[]>(accountRoomTags)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [targetDeleteRoomTag, setTargetDeleteRoomTag] = useState<AccountRoomTag | null>()
    const { tableCurrentAction, write, batch } = useWDBOps()

    const { mutate } = useMutation<Array<Pick<AccountRoomTagData, 'id' | 'type' | 'order'>> | undefined, Error, Array<AccountRoomTagData>>(
        tags => {
            return api.accountRoomTag.updateOrdering({ labels: tags })
        },
        {
            onSuccess: async data => {
                if (data) {
                    try {
                        for (const d of data) {
                            const updatedTag = accountRoomTags.find(tag => tag.id === d.id)
                            if (updatedTag) {
                                await updatedTag.setOrder(d.order)
                            }
                        }
                    } catch (error) {
                        captureException(error, {
                            extra: {
                                data,
                                error
                            }
                        })
                    }
                }
            },
            onError: error => console.log('LabelList order', error)
        }
    )

    useEffect(() => {
        setTempAccountRoomTags(accountRoomTags)
    }, [accountRoomTags])

    const [, drop] = useDrop(() => ({
        accept: 'LabelItem',
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }))

    const moveLabelItem = (dragIndex: number, hoverIndex: number) => {
        const dragCard = tmpAccountRoomTags[dragIndex]
        let cloneCards = [...tmpAccountRoomTags]
        cloneCards.splice(dragIndex, 1)
        cloneCards.splice(hoverIndex, 0, dragCard)
        setTempAccountRoomTags(cloneCards)
    }

    const handleOnDragEnd = useCallback(() => {
        mutate(
            tmpAccountRoomTags.map((data, i) => ({
                id: data.id,
                name: data.name,
                order: i,
                type: data.type
            }))
        )
    }, [mutate, tmpAccountRoomTags])

    const { mutate: deleteMutate } = useMutation<AccountRoomTagData | undefined, Error, AccountRoomTagData>(
        (accountRoomTag: AccountRoomTagData) => {
            return api.accountRoomTag.deleteV2(accountRoomTag.id, accountRoomTag.type)
        },
        {
            onSuccess: async data => {
                try {
                    if (!data) {
                        return
                    }
                    if (!targetDeleteRoomTag) {
                        return
                    }
                    const { all } = tableCurrentAction<RoomLabelRecord>(TableName.ROOM_LABEL_RECORDS)

                    const labelRecords = await all([Q.where('accountRoomTagId', targetDeleteRoomTag.id)])

                    await write(async () => {
                        const { findOne } = tableCurrentAction<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS)

                        const dbAccountRoomTag = await findOne(targetDeleteRoomTag.id)

                        await batch(
                            ...labelRecords.map(record => {
                                return record.prepareDestroyPermanently()
                            })
                        )

                        if (dbAccountRoomTag) {
                            await dbAccountRoomTag.destroyPermanently()
                        }
                    }, TableName.ROOM_LABEL_RECORDS)
                    setTargetDeleteRoomTag(null)
                } catch (error) {
                    captureException(error, {
                        extra: {
                            data,
                            error
                        }
                    })
                }
            },
            onError: error => console.log('LabelList delete', error)
        }
    )

    const onClickDelete = () => {
        if (targetDeleteRoomTag) {
            deleteMutate(targetDeleteRoomTag)
            setShowModal(false)
        }
    }

    return (
        <div ref={drop}>
            {isCreating && <LabelCreatingCard onCompleteCreate={onCompleteCreate} />}
            {staticTags.map(tag => {
                const cardProps: LabelCardProps = {
                    index: tag.order,
                    moveCard: () => null,
                    setCurrentRoomTag: () => null,
                    accountRoomTag: tag as AccountRoomTag,
                    handleOnDragEnd: () => null,
                    openShowModal: () => null,
                    setTargetDeleteRoomTag: () => null
                }
                return <LabelCard {...cardProps} key={tag.order} />
            })}
            {tmpAccountRoomTags.map((tmpAccountRoomTag: AccountRoomTag, index: number) => {
                return (
                    <LabelCard
                        moveCard={moveLabelItem}
                        index={index}
                        key={tmpAccountRoomTag.id}
                        setCurrentRoomTag={() => {
                            setCurrentRoomTag(tmpAccountRoomTag)
                        }}
                        accountRoomTag={tmpAccountRoomTag}
                        handleOnDragEnd={handleOnDragEnd}
                        openShowModal={() => setShowModal(true)}
                        setTargetDeleteRoomTag={setTargetDeleteRoomTag}
                    />
                )
            })}

            {showModal && targetDeleteRoomTag ? (
                <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 '>
                    <div className='relative w-full max-w-md max-h-full'>
                        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
                            <button
                                type='button'
                                onClick={() => setShowModal(false)}
                                className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'>
                                <svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        fillRule='evenodd'
                                        d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                                        clipRule='evenodd'
                                    />
                                </svg>
                                <span className='sr-only'>Close modal</span>
                            </button>
                            <div className='p-6 text-center'>
                                <svg
                                    className='mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
                                </svg>
                                <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
                                    Are you sure you want to delete this <a className='px-2 bg-slate-200'>{targetDeleteRoomTag.name}</a> label?
                                </h3>
                                <button
                                    data-testid='LabelList-delete-confirm-button'
                                    type='button'
                                    onClick={onClickDelete}
                                    className='text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2'>
                                    Yes, I &apos;m sure
                                </button>
                                <button
                                    onClick={() => setShowModal(false)}
                                    type='button'
                                    className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'>
                                    No, cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
