import axios from 'axios'
import UrlPattern from 'url-pattern'

import { createDecipheriv } from 'browser-crypto'
import { EventType, MsgType } from 'matrix-js-sdk'

import { CustomCrypto } from '@closer/types'
import { matrixToolService } from '@closer/matrix'
import { DomainParts, domainParts } from '@closer/utils'


export const crypto: CustomCrypto = {
    createDecipheriv
}
export async function getReplyFromChatBot(baseUrl: string, roomId: string, eventId: string, matrixToken: string) {
    try {
        const response = await axios.get(`${baseUrl}/_matrix/client/v3/rooms/${roomId}/context/${eventId}`, {
            params: { limit: 20 },
            headers: { Authorization: `Bearer ${matrixToken}` }
        })
        return response.data
    } catch (error) {
        // captureMessage(`WhatsappBot reply error. matrixToken:${matrixToken}`, 'warning')
        // throw new WhatsappBotException()
    }
}

export function getContent(data: any) {
    const result = { url: null, notice: null }

    if (data['type'] === EventType.RoomMessage) {
        const content = data['content']

        switch (content.msgtype) {
            case MsgType.Image:
                return { ...result, url: content['url'] }
            case MsgType.Notice:
                return { ...result, notice: content['body'] }
        }
    }
    return result
}

export const getImageUrl = (avatar: string | null) => {
    return avatar ? matrixToolService.getImageUrl(avatar, 50, 50) : null
}

// FIXME: have to implement type checking for MxcMediaUrl for all media components
export function getHttpUriForMxc(mxc: MxcMediaUrl | string | null, width?: number, height?: number, resizeMethod?: 'crop' | 'scale', allowDirectLinks = false): string {
    if (typeof mxc !== 'string' || !mxc) {
        return ''
    }
    if (mxc.indexOf('mxc://') !== 0) {
        if (allowDirectLinks) {
            return mxc
        } else {
            return ''
        }
    }
    const baseUrl = abstractBaseUrl(mxc) || 'https://production.closer.contact'
    let serverAndMediaId = mxc.slice(6) // strips mxc://
    let prefix = '/_matrix/media/r0/download/'
    const params: Record<string, string> = {}

    if (width) {
        params['width'] = Math.round(width).toString()
    }
    if (height) {
        params['height'] = Math.round(height).toString()
    }
    if (resizeMethod) {
        params['method'] = resizeMethod
    }
    if (Object.keys(params).length > 0) {
        // these are thumbnailing params so they probably want the
        // thumbnailing API...
        prefix = '/_matrix/media/r0/thumbnail/'
    }

    const fragmentOffset = serverAndMediaId.indexOf('#')
    let fragment = ''
    if (fragmentOffset >= 0) {
        fragment = serverAndMediaId.slice(fragmentOffset)
        serverAndMediaId = serverAndMediaId.slice(0, fragmentOffset)
    }

    const urlParams = Object.keys(params).length === 0 ? '' : '?' + encodeParams(params)
    return baseUrl + prefix + serverAndMediaId + urlParams + fragment
}

function encodeParams(params: Record<string, string | number | boolean>): string {
    const searchParams = new URLSearchParams()
    for (const [key, val] of Object.entries(params)) {
        if (val !== undefined && val !== null) {
            searchParams.set(key, String(val))
        }
    }
    return searchParams.toString()
}

type MxcMediaUrl = `mxc://${DomainParts['env']}.${DomainParts['app']}.${DomainParts['tld']}/${string}`
type MxcUrlMatch = Partial<Record<typeof mxcUrlParts[number], string>>
const mxcUrlParts = [...domainParts, 'mediaId'] as const
const mxcUrlPattern = new UrlPattern(/mxc:\/\/(production|staging).(closer).(contact)\/([a-zA-Z0-9]+)/, [...mxcUrlParts])

// FIXME: have to implement type checking for MxcMediaUrl for all media components
export function abstractBaseUrl(mxc: MxcMediaUrl | string | null): string | null {
    if (!mxc) {
        return null
    }
    const mxcMatch = mxcUrlPattern.match(mxc) as MxcUrlMatch
    if (!mxcMatch) {
        return null
    }
    return 'https://' + mxcMatch.env + '.' + mxcMatch.app + '.' + mxcMatch.tld
}

export function isMxcMediaUrl(target: string): target is MxcMediaUrl
export function isMxcMediaUrl(target: string) {
    const mxcMatch = mxcUrlPattern.match(target) as MxcUrlMatch
    if (!mxcMatch) {
        return false
    }
    const { env, app, tld, mediaId } = mxcMatch
    return Boolean(env && app && tld && mediaId && mediaId !== '')
}
