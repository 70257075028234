import { FC } from 'react'
import { GoArchive } from 'react-icons/go'
// import { useTranslation } from 'react-i18next'

// import { I18nKey } from '@closer/i18n'

export interface ArchiveBannerProps {
    numberOfArchived: number
    onClick: () => void
}

export const ArchiveBanner: FC<ArchiveBannerProps> = ({ numberOfArchived, onClick }) => {
    // const { t } = useTranslation()
    const tintGreen = '#56BA8E'

    return (
        <div>
            <div className={`flex flex-row text-[${tintGreen}] h-10 p-2 bg-gray-100`} onClick={onClick}>
                <button className='flex grow flex-row w-full'>
                    <GoArchive className='mx-2 my-auto h-5 w-5' />
                    <div className='flex grow'>Archived conversations</div>
                    <div>{numberOfArchived}</div>
                </button>
            </div>
        </div>
    )
}
