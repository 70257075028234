import { FC } from 'react'
import { MdLabelOutline } from 'react-icons/md/'
import { useKBar } from 'kbar'

interface LabelButtonProps {
    roomId: string
    closeMenu: () => void
    containerStyle: string
    iconStyle: string
}

export const LabelButton: FC<LabelButtonProps> = ({ roomId, closeMenu, containerStyle, iconStyle }) => {
    const { query } = useKBar()
    const onClick = () => {
        query.setCurrentRootAction(`label-${roomId}`)
        query.toggle()
        closeMenu()
    }

    return (
        <button onClick={onClick} className={containerStyle}>
            <MdLabelOutline className={iconStyle} />
            Label
        </button>
    )
}
