import { FC } from 'react'

import i18n from 'apps/web/i18n'
import { LoadingSpinner } from '../Common'
import { captureErrorToSentry, captureMessageToSentry } from 'apps/web/utils/sentry'
import { InitialSyncView, InitialSyncViewProps } from '@closer/headless-components/components'
import { labelService, syncBackendDataService } from 'apps/web/pages/_app'

export const InitialSyncScreen: FC = () => {
    const initialSyncViewProps: InitialSyncViewProps = {
        captureErrorToSentry,
        captureMessageToSentry,
        render({ canRetry, matrixIsSynced, initialSyncPersist, onPressRetry, currentTime, totalChatRoomSummary, progressCount, progressing }) {
            return (
                <div className='flex-row shrink-1 h-full w-full justify-center items-center bg-white'>
                    <div className='flex flex-col px-4 justify-center items-center h-full w-full'>
                        <div className='mb-12 md:mb-0 md:w-8/12 lg:w-6/12'>
                            <img src='https://d1pnnwteuly8z3.cloudfront.net/images/30ba08ff-7870-4ce4-855e-cc07414e7915/b345644c-a350-44b9-bd80-4332b12161f5.png' className='w-full' alt='Phone image' />
                        </div>
                        <div className='text-slate-900'>Please keep the Closer app in the foreground while we initialize your website</div>
                        {progressing && <div className='text-slate-900'>{matrixIsSynced && initialSyncPersist ? 'Updating Rooms...' : 'Initial Sync...'}</div>}
                        {matrixIsSynced && progressing && initialSyncPersist && (
                            <>
                                {process.env['NEXT_PUBLIC_APP_ENV'] === 'Production' ? null : (
                                    <>
                                        <div className='text-slate-900'>{`${progressCount.currentRoom}`}</div>
                                        <div className='text-slate-900'>{`${currentTime / 1000}'S`}</div>
                                    </>
                                )}
                                <div className='text-slate-900'>{`${progressCount.count}/${totalChatRoomSummary}`}</div>
                            </>
                        )}
                        {canRetry && !progressing && (
                            <button
                                onClick={onPressRetry}
                                className='mb-3 inline-block w-full rounded px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]'
                                type='submit'
                                data-te-ripple-init
                                data-te-ripple-color='light'
                                style={{
                                    width: 100,
                                    background: 'linear-gradient(90deg, #354dc0,#813ba8,#a52a8a)'
                                }}>
                                Retry
                            </button>
                        )}
                        <div>
                            <LoadingSpinner />
                        </div>
                    </div>
                </div>
            )
        },
        labelService,
        syncBackendDataService,
        i18n
    }

    return <InitialSyncView {...initialSyncViewProps} />
}
