import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { ComponentProps, FC, useState } from 'react'

import { ChatRoomSummary, TableName } from '@closer/watermelondb'
import { ReminderListItem as HeadlessItem, ReminderListItemProps as HeadlessProps, updateUpcomingReminder } from '@closer/headless-components/components'
import { ReminderSubCategory, RemindItem } from '@closer/types'

import { composeUrlState } from '../../hooks/router/useValidRoute'
import { watermelondb } from '../../db/watermelon_db'

import { hiTrash } from '../Common'

export interface ReminderListItemProps {
    reminder: RemindItem
    subCategory: ReminderSubCategory
    showRoomName: boolean
}

const iconStyle = 'w-6 h-6 p-1 rounded-md text-white transition-opacity cursor-pointer'

export const ReminderListItem: FC<ReminderListItemProps> = ({ reminder, subCategory, showRoomName }) => {
    const router = useRouter()
    const queryClient = useQueryClient()
    const [isHovered, setIsHovered] = useState(false)
    const handleReminderMutation = async (response: RemindItem) => {
        const chatRoomSummary = await watermelondb.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES).find(response.roomId)
        await updateUpcomingReminder(chatRoomSummary, response, queryClient)
        router.push(composeUrlState({ f: 'Reminders' }))
    }
    const headlessProps: HeadlessProps = {
        reminder,
        subCategory,
        renderItem: ({ room, isMutating, title, time, accessibilityLabel, completeReminder, removeReminder }) => {
            const editReminder = !isMutating && subCategory === 'Pending' ? () => router.push(composeUrlState({ f: 'Reminders', x: 'Edit', r: reminder.id })) : undefined
            const itemProps: ComponentProps<'div'> = {
                id: accessibilityLabel,
                className: `relative pl-4 pr-1 py-3 border-b-[1px] grid gap-x-1 grid-cols-[1fr_min-content_min-content_min-content] grid-rows-[min-content] items-center ${isMutating ? 'opacity-25' : ''}`,
                onPointerOver: () => setIsHovered(true),
                onPointerOut: () => setIsHovered(false)
            }
            const iconProps: ComponentProps<'svg'> = {
                xmlns: 'http://www.w3.org/2000/svg',
                fill: 'none',
                viewBox: '0 0 24 24',
                stroke: 'currentColor',
                strokeWidth: 1.5
            }
            const iconHoverStyle = isHovered ? 'opacity-100' : 'opacity-0'
            const cursorStyle = subCategory === 'Pending' ? 'cursor-pointer' : ''

            return (
                <div {...itemProps}>
                    <p className={cursorStyle} onClick={editReminder}>
                        {title.text}
                    </p>
                    <p className={`row-start-2 pt-1 text-[10px] text-[#AF52DE] ${cursorStyle}`} onClick={editReminder}>
                        {time.text}
                    </p>
                    {showRoomName && room && (
                        <p className={`col-start-2 row-start-2 col-span-3 px-1 text-right text-[10px] text-[#737D8C] whitespace-nowrap ${cursorStyle}`} onClick={editReminder}>
                            {room.name}
                        </p>
                    )}
                    {subCategory === 'Pending' && (
                        <svg {...iconProps} className={`${iconStyle} ${iconHoverStyle} col-start-3 bg-[#56BA8E]/80`} onClick={completeReminder}>
                            <path strokeLinecap='round' strokeLinejoin='round' d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
                        </svg>
                    )}
                    <svg {...iconProps} className={`${iconStyle} ${iconHoverStyle} col-start-4 bg-[#FF3B30]/80`} onClick={removeReminder}>
                        <path strokeLinecap='round' strokeLinejoin='round' d={hiTrash} />
                    </svg>
                </div>
            )
        },
        renderError: error => <p>{JSON.stringify(error)}</p>,
        onComplete: handleReminderMutation,
        onRemove: handleReminderMutation
    }

    return <HeadlessItem {...headlessProps} />
}
