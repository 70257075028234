import { Q } from '@nozbe/watermelondb'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useMutation } from '@tanstack/react-query'

import { api } from '@closer/api'
import { ChatRoomSummary, RoomLabelRecord, TableName } from '@closer/watermelondb'
import { LabelUpdateByTagIdsV2Body, PinUpdateBody, RoomAccountData, RoomLabelRecordData } from '@closer/types'

export const usePinMutation = (onComplete?: () => void) => {
    const database = useDatabase()
    return useMutation<RoomAccountData, Error, PinUpdateBody>(
        body => {
            return api.pin.update(body)
        },
        {
            onSuccess: async data => {
                const chatRoomSummaryTemp = await database.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES).find(data.roomId)
                await chatRoomSummaryTemp.setPinTime(data.pin !== null ? new Date(data.pin) : null)
                onComplete && onComplete()
            }
        }
    )
}

export const useLabelMutationByTagIds = (onComplete?: () => void) => {
    const database = useDatabase()
    return useMutation<RoomLabelRecordData[], Error, LabelUpdateByTagIdsV2Body>(
        body => {
            return api.labelRecords.updateByTagIdsV2(body)
        },
        {
            onSuccess: async (createdRecords, body) => {
                const recordTable = database.get<RoomLabelRecord>(TableName.ROOM_LABEL_RECORDS)
                const toDelete = await recordTable.query(Q.where('roomId', body.roomId)).fetch()
                const toDeleteRecords = toDelete.map(record => record.prepareDestroyPermanently()) as any
                const toCreateRecords = createdRecords.map(record => {
                    return recordTable.prepareCreate(toCreate => {
                        toCreate._raw.id = record.id
                        toCreate.accountRoomTagId = record.accountRoomTagId ? record.accountRoomTagId : record.teamLabelId
                        toCreate.roomId = record.roomId
                        toCreate.roomAccountDatumId = record.roomAccountDatumId ? record.roomAccountDatumId : ''
                    })
                }) as any
                await database.write(async () => await database.batch(toDeleteRecords), TableName.ROOM_LABEL_RECORDS)
                await database.write(async () => await database.batch(toCreateRecords), TableName.ROOM_LABEL_RECORDS)
                const room = await database.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES).find(body.roomId).catch()
                if (createdRecords.length === 0) {
                    await room.setTag(null)
                } else {
                    await room.setTag('labelled')
                }
                onComplete && onComplete()
            }
        }
    )
}
