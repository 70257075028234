import { Component, FC } from 'react'
import { EventType, MsgType } from 'matrix-js-sdk'

import { ContentProps } from '@closer/headless-components/components'
import { IAudioContent, IFileContent, IImageContent, ILocationContent, IVideoContent } from '@closer/types'

import { Stub } from '../../Common/Stub'

import { AudioContent } from './AudioContent'
import { FileContent } from './FileContent'
import { ImageContent } from './ImageContent'
import { LocationContent } from './LocationContent'
import { TextContent } from './TextContent'
import { VideoContent } from './VideoContent'

export class MessageContent extends Component {
    static [MsgType.Audio]: FC<ContentProps> = props => <AudioContent {...(props as ContentProps<IAudioContent>)} />
    static [MsgType.Emote] = Stub
    static [MsgType.File]: FC<ContentProps> = props => <FileContent {...(props as ContentProps<IFileContent>)} />
    static [MsgType.Image]: FC<ContentProps> = props => <ImageContent {...(props as ContentProps<IImageContent>)} />
    static [MsgType.KeyVerificationRequest] = Stub
    static [MsgType.Location]: FC<ContentProps> = props => <LocationContent {...(props as ContentProps<ILocationContent>)} />
    static [MsgType.Notice] = TextContent
    static [MsgType.Text] = TextContent
    static [MsgType.Video]: FC<ContentProps> = props => <VideoContent {...(props as ContentProps<IVideoContent>)} />

    static [EventType.Sticker]: FC<ContentProps> = props => <ImageContent {...(props as ContentProps<IImageContent>)} />
    static [EventType.RoomMessage] = Stub

    static RelatedEvent = Stub
}
