import { useRouter } from 'next/router'
import { ComponentProps, FC, useState } from 'react'

import { useContactOrGroupName, useMatrixRoom, useRoomAvatar } from '@closer/headless-components/hooks'

import { composeUrlState } from '../../hooks/router/useValidRoute'

import { ArchiveButton, ArchiveButtonProps } from '../Archive/ArchiveButton'
import { Avatar, AvatarProps, mdClipboardTextClock, mdClockPlus, ModalPortal, Svg, SvgProps } from '../Common'

import { RoomDetail } from './RoomDetail'

interface RoomHeaderProps {
    roomId: string
}

const commonButtonStyle = 'px-3'
const commonSvgStyle = 'w-6 h-6'

// TODO: move to headless if align with mobile later
export const RoomHeaderTitle: FC<RoomHeaderProps> = ({ roomId }) => {
    const router = useRouter()
    const { room } = useMatrixRoom(roomId)
    const { avatarData } = useRoomAvatar(room)
    const { contactOrGroupName } = useContactOrGroupName({ roomId })
    const [detailIsOpen, setDetailIsOpen] = useState(false)
    const avatarProps: AvatarProps = {
        imageUrl: avatarData?.url,
        name: contactOrGroupName || ''
    }
    const archiveProps: ArchiveButtonProps = {
        roomId,
        buttonStyle: commonButtonStyle,
        spinnerSize: 6,
        svgStyle: commonSvgStyle,
        showLabel: false
    }
    const reminderListButtonProps: ComponentProps<'button'> = {
        className: commonButtonStyle,
        onClick: () => router.push(composeUrlState({ f: 'Reminders', x: 'ViewRoom', r: roomId }))
    }
    const reminderListSvgProps: SvgProps = {
        d: mdClipboardTextClock,
        fill: 'currentColor',
        className: `text-[#65BA8E] ${commonSvgStyle}`
    }
    const newReminderButtonProps: ComponentProps<'button'> = {
        className: commonButtonStyle,
        onClick: () => router.push(composeUrlState({ f: 'Reminders', x: 'Create', r: roomId }))
    }
    const newReminderSvgProps: SvgProps = {
        d: mdClockPlus,
        fill: 'currentColor',
        className: `text-[#65BA8E] ${commonSvgStyle}`
    }

    return (
        <div className='grid grid-cols-[min-content,1fr,repeat(4,min-content)] items-center px-4 py-2 bg-slate-100'>
            <div className='cursor-pointer' onClick={() => setDetailIsOpen(true)}>
                <Avatar {...avatarProps} />
            </div>
            <div className='px-2 truncate grid grid-rows-2 items-center'>
                <div className='truncate'>{contactOrGroupName}</div>
                <div className='truncate text-sm'>
                    <em>{room?.topic || ''}</em>
                </div>
            </div>
            <ArchiveButton {...archiveProps} />
            <button {...reminderListButtonProps}>
                <Svg {...reminderListSvgProps} />
            </button>
            <button {...newReminderButtonProps}>
                <Svg {...newReminderSvgProps} />
            </button>
            {detailIsOpen && (
                <ModalPortal selector={'#modal'}>
                    <RoomDetail roomId={roomId} onClose={() => setDetailIsOpen(false)} />
                </ModalPortal>
            )}
        </div>
    )
}
