import { SiPinboard } from 'react-icons/si/'
import { ClockIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { ComponentProps, forwardRef, MouseEvent, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { ChatRoomSummary } from '@closer/watermelondb'
import { roomEventReadableDate } from '@closer/utils'
import { useAppSelector } from '@closer/redux-storage'
import { useRoomStore } from '@closer/headless-components/components/Room'

import { getImageUrl } from '../../utils/util'

import { Avatar } from '../Common'
import { useFocusElementRefContext, useSetRoomActionsActiveContext, useSetRoomActionsContext } from '../CommandPalette'

import { RoomContextMenuProps } from './RoomContextMenu'

export interface RoomListRowProps {
    chatRoomSummary: ChatRoomSummary
    isSelected: boolean
    openMenu: (xCoordinate: number, yCoordinate: number, room: RoomContextMenuProps['room']) => void
    handleClick: () => void
}

export const RoomListRow = forwardRef<HTMLDivElement, RoomListRowProps>(({ chatRoomSummary, openMenu, handleClick, isSelected }, ref) => {
    const { id, name, avatar, timestamp, snippet, nextReminderTime, passedReminderTime, nextScheduleSendTime, pinTime, archive } = chatRoomSummary
    const { content: snippetContent, notificationCount } = snippet
    const [imageUrl, setImageUrl] = useState('')
    const unread = notificationCount && notificationCount > 0
    const elementRef = useRef<HTMLDivElement>(null)
    const focusElementRef = useFocusElementRefContext()
    const isArchiving = useRoomStore(state => state.isArchiving[id])
    const matrixIsReady = useAppSelector(state => state.matrix.matrixIsReady)
    const setRoomId = useSetRoomActionsContext()
    const setIsActive = useSetRoomActionsActiveContext()
    const containerProps: ComponentProps<'div'> = {
        className: `flex flex-row items-center px-1 py-1.5 truncate border-[1px] ${isArchiving ? 'opacity-50' : ''} ${pinTime ? 'bg-gray-100' : 'bg-white'} focus:outline-none ${isSelected ? 'border-[#56BA8E] opacity-100' : 'border-b-gray-200 opacity-90'}`,
        tabIndex: isSelected ? 0 : -1,
        ref: elementRef,
        onClick: handleClick,
        onFocus: () => {
            if (elementRef.current?.getBoundingClientRect().bottom && elementRef.current?.getBoundingClientRect().bottom > window.innerHeight) {
                elementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
            focusElementRef.current = { element: elementRef.current, type: 'room' }
            setRoomId(id)
            setIsActive(true)
        },
        onContextMenu: (event: MouseEvent) => {
            event.preventDefault()
            openMenu(event.pageX, event.pageY, { roomId: id, name: name, pinTime, archive: !!archive })
        }
    }

    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => elementRef.current)

    useEffect(() => {
        if (matrixIsReady) {
            setImageUrl(getImageUrl(avatar ?? null) ?? '')
        }
    }, [avatar, matrixIsReady])

    useEffect(() => {
        const element = elementRef.current
        if (isSelected && document.activeElement !== element && focusElementRef.current.type !== 'search') {
            elementRef.current?.focus()
        }
        return () => element?.blur()
    }, [focusElementRef, isSelected])

    return (
        <div {...containerProps}>
            <div>
                <Avatar imageUrl={imageUrl} name={name} />
            </div>
            <div className='flex grow flex-col ml-2 truncate'>
                <div className='flex flex-row mb-2'>
                    <div className={`flex grow min-w-0 mr-2 ${unread ? 'font-bold' : 'font-normal'}`}>
                        <div className='truncate'>{name}</div>
                    </div>
                    <div className='flex flex-row items-center'>
                        {nextScheduleSendTime && <PaperAirplaneIcon className={'w-3 h-3 mx-1 text-[#56BA8E]'} />}
                        {(nextReminderTime || passedReminderTime) && <ClockIcon className={`w-3 h-3 mx-1 ${passedReminderTime ? 'text-[#56BA8E]' : 'text-[#888]'}`} />}
                        {pinTime && <SiPinboard className={'w-3 h-3 mx-1 text-[#56BA8E]'} />}
                        <div className={`text-[12px] ${unread ? 'text-[#56BA8E]' : ''}`}>{timestamp.getTime() !== 0 ? roomEventReadableDate({ datetime: timestamp, dateOnly: true, preciseSameDay: true }) : ''}</div>
                    </div>
                </div>
                <div className='flex flex-row items-center'>
                    <div className='flex grow min-w-0 mr-2'>
                        <div className='truncate text-[14px]'>{snippetContent}</div>
                    </div>
                    {archive && <div className='px-2 bg-gray-600 text-white text-[12px] rounded-full mr-1'>Archived</div>}
                    {unread ? <div className='px-1 bg-[#56BA8E] text-white text-[12px] rounded-full'>{notificationCount}</div> : null}
                </div>
            </div>
        </div>
    )
})
