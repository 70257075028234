import { FC } from 'react'

import { RemindItem } from '@closer/types'
import { RoomInfo } from '@closer/headless-components/hooks'
import { ReminderGroup as HeadlessGroup, ReminderGroupProps as HeadlessProps, PartialReminderListProps } from '@closer/headless-components/components'

import { Avatar, AvatarProps, Svg, SvgProps } from '../Common'

import { ReminderListItem, ReminderListItemProps } from './ReminderListItem'

export interface ReminderGroupProps extends Pick<PartialReminderListProps, 'subCategory'> {
    room: RoomInfo
    reminders: Array<RemindItem>
}

export const ReminderGroup: FC<ReminderGroupProps> = ({ room, reminders, subCategory }) => {
    const headlessProps: HeadlessProps = {
        room,
        render: ({ avatarData, isLoadingAvatar, expanded, toggleGroup }) => {
            const { url, title } = avatarData || {}
            const { name } = room || {}
            const arrowProps: SvgProps = {
                fill: 'none',
                stroke: 'currentColor',
                strokeWidth: 3,
                d: expanded ? 'M4.5 15.75l7.5-7.5 7.5 7.5' : 'M19.5 8.25l-7.5 7.5-7.5-7.5',
                className: 'w-3 h-3'
            }
            const avatarProps: AvatarProps = {
                imageUrl: url,
                name: title || name,
                isLoading: isLoadingAvatar
            }

            return (
                <div>
                    <div className='py-4 border-b-[1px]'>
                        <div className='grid grid-cols-[min-content,_1fr,_min-content] gap-2 items-center cursor-pointer px-4' onClick={toggleGroup}>
                            <Avatar {...avatarProps} />
                            <p>{room.name}</p>
                            <Svg {...arrowProps} />
                        </div>
                    </div>
                    {expanded &&
                        reminders.map(reminder => {
                            const props: ReminderListItemProps = { reminder, subCategory, showRoomName: false }
                            return <ReminderListItem {...props} key={reminder.id} />
                        })}
                </div>
            )
        },
        onPress: () => {}
    }

    return <HeadlessGroup {...headlessProps} />
}
