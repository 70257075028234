import { FC, useRef } from 'react'

import { label } from '@closer/types'

import { useSearchHotKey } from '../../hooks'

import { SearchBar, SearchBarProps } from '../Common'
import { useFocusElementRefContext, useSetRoomActionsActiveContext } from '../CommandPalette'

export interface RoomListSearchBarProps {
    setSearch: (input: string) => void
    scope: string
}

export const RoomListSearchBar: FC<RoomListSearchBarProps> = ({ scope, setSearch }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const restoreFocusElement = useFocusElementRefContext()
    const setRoomActionIsActive = useSetRoomActionsActiveContext()
    const props: SearchBarProps = {
        scope,
        label: label.textInput.RoomListSearchBar,
        onChangeText: setSearch,
        onFocus: () => {
            restoreFocusElement.current = { element: inputRef.current, type: 'search' }
            setRoomActionIsActive(false)
        }
    }
    useSearchHotKey(inputRef.current)

    return <SearchBar {...props} ref={inputRef} />
}
