import { ComponentProps, FC } from 'react'

import { convertTextToColour } from '@closer/utils'
import { FileContentDataProps, FileContent as HeadlessContent, FileContentProps as HeadlessProps } from '@closer/headless-components/components'

import { Stub } from '../../Common/Stub'
import { faVcard, miAttachment, Svg, SvgProps } from '../../Common'

import { MessageContent } from '.'

export const FileContent: FC<FileContentDataProps> = dataProps => {
    const headlessProps: HeadlessProps = {
        ...dataProps,
        render: ({ url, extension, label, timestamp, senderName, isMine }) => {
            const svgProps: SvgProps = {
                fill: '#00000050',
                stroke: 'none',
                className: 'w-6 h-6 cursor-pointer'
            }
            const icon = extension.match(/vcf/i) ? <Svg {...svgProps} box={{ x: 0, y: 0, w: 2048, h: 1536 }} d={faVcard} /> : <Svg {...svgProps} d={miAttachment} />
            const senderNameProps: ComponentProps<'p'> = {
                className: 'font-bold text-left text-sm',
                style: { color: senderName && (isMine ? '#56BA8E' : convertTextToColour(senderName)) }
            }
            const handleFileClick = () => {
                if (dataProps.isRelatedContent) {
                    return
                }

                window.open(url)
            }

            return (
                <div className={`grid ${dataProps.isRelatedContent ? 'p-2' : ''}`}>
                    {dataProps.isRelatedContent && <p {...senderNameProps}>{senderName}</p>}
                    <div className='grid grid-flow-col gap-x-2 place-items-center px-3 py-2 bg-[#00000010] rounded-lg cursor-pointer' onClick={handleFileClick}>
                        {icon}
                        <p className='text-[#00000075]'>{label}</p>
                    </div>
                    {timestamp && <span className='pl-2 text-right text-sm text-[#737D8C]'>{timestamp}</span>}
                    {(dataProps.event.co_events || []).map(coEvent => {
                        const coEventContentKey = coEvent.content.msgtype || coEvent.type
                        const CoEventContent = coEventContentKey in MessageContent ? MessageContent[coEventContentKey] : Stub

                        return (
                            <div className='px-1' key={coEvent.event_id} >
                                <CoEventContent event={coEvent} />
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    return <HeadlessContent {...headlessProps} />
}
