import { FC } from 'react'

import { useMatrix } from '@closer/headless-components/hooks'
import { Filter, RemindItem } from '@closer/types'
import { Reminders, RemindersProps, ReminderTabProps } from '@closer/headless-components/components'

import { LoadingSpinner } from '../Common'

import { ReminderList } from './ReminderList'
import { ReminderSearchBar } from './ReminderSearchBar'

interface RemindersScreenProps {
    filter?: Filter<RemindItem>
}

export const RemindersScreen: FC<RemindersScreenProps> = ({ filter }) => {
    const { client } = useMatrix()
    const headlessProps: RemindersProps = {
        filter,
        render: ({ tabIndex, tabProps, listProps, changeTab }) => {
            const getTabProps = ({ active, category }: ReminderTabProps) => ({
                className: `text-sm text-center rounded-md p-0.5 cursor-pointer ${active ? 'bg-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.25)]' : ''}`,
                onClick: () => changeTab(category === 'Completed' ? 1 : 0)
            })

            return (
                <div id='RemindersScreen' className='grid grid-rows-[min-content_min-content_1fr] gap-2 overflow-hidden'>
                    <ReminderSearchBar />
                    <div id='tabsContainer' className='grid grid-cols-2 mx-2 p-[2px] rounded-md bg-neutral-100'>
                        {/* use tabProps.pending.title after setting up i18n */}
                        <div {...getTabProps(tabProps.pending)}>{tabProps.pending.category}</div>
                        <div {...getTabProps(tabProps.completed)}>{tabProps.completed.category}</div>
                    </div>
                    <div id='listsContainer' className='overflow-y-auto'>
                        {tabIndex === 0 && <ReminderList {...listProps.pending} />}
                        {tabIndex === 1 && <ReminderList {...listProps.completed} />}
                    </div>
                </div>
            )
        }
    }

    return client ? <Reminders {...headlessProps} /> : <LoadingSpinner />
}
