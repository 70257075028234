import { useRouter } from 'next/router'
import { ComponentProps, FC, useState } from 'react'

import { convertEventIdToValidHtmlId, convertTextToColour } from '@closer/utils'
import { MessageBubble as HeadlessBubble, MessageBubbleProps as HeadlessProps, MessageBubbleDataProps } from '@closer/headless-components/components'

import { composeUrlState } from '../../hooks/router/useValidRoute'
import { Stub } from '../../components/Common/Stub'

import { hiArrowUturnRight, riShareForwardFill, Svg, SvgProps } from '../Common'

import { MessageContent } from './MessageContent'

export interface MessageBubbleProps extends MessageBubbleDataProps {
    //
}

/**
 * `MessageBubble` is a functional React component that provides the message bubble rendering with different styling and some interaction options.
 * The component displays a chat message in a bubble and varies the presentation depending on several conditions.
 * In addition to just rendering the message text, it supports sender highlighting, click-to-fetch related events and different kinds of messages like reply or stickers.
 * Double-click logs the event to the console.
 * An additional "options" button that triggers replying or forwarding actions shows up when hovering over the bubble.
 * If a `dateHeader` is passed in the data properties, the bubble will be accompanied by the date of the message.
 *
 * @component
 * @param {MessageBubbleProps} dataProps - The data properties for the component.
 * @param {RoomEvent<T>} dataProps.event - The room event to be displayed
 * @param {boolean} [dataProps.isRelatedContent=false] - Whether the message is part of related content
 * @param {boolean} [dataProps.isReplyEvent=false] - Whether the message is a reply to another event
 * @param {boolean} [dataProps.compact=false] - Whether to render the message in a compact view
 * @param {Object} [dataProps.fetchUntil] - A fetchUntil object which includes a callback (onSuccess) triggered after fetching
 * @param {Function} [dataProps.fetchUntil.start] - The function to trigger the fetching of the message
 * @param {Function} [dataProps.fetchUntil.onSuccess] - The callback function after successful fetch
 *
 * @returns {JSX.Element} The rendered MessageBubble component
 */
export const MessageBubble = (dataProps: MessageBubbleProps): JSX.Element => {
    const { event, isRelatedContent, isReplyEvent } = dataProps
    const [isHovered, setIsHovered] = useState(false)
    const router = useRouter()
    const forwardMessage = () => {
        router.push(composeUrlState({ f: 'RoomList', x: 'Forward', r: JSON.stringify([event.room_id, event.event_id]) }))
    }
    const headlessProps: HeadlessProps = {
        ...dataProps,
        render: ({ isMine, dateHeader, senderName, replyToEvent, fetchRelatedEvent }) => {
            const tw_isMine = isMine ? 'place-self-end border-l-[#56BA8E]' : 'place-self-start border-l-[#AF52DE]'
            const tw_bg = event.type === 'm.sticker' && !isRelatedContent && !isReplyEvent ? '' : isMine ? (isRelatedContent ? 'bg-[#00000010]' : 'bg-[#DDF1E8]') : 'bg-[#00000015]'
            const tw_margin = senderName ? (!isRelatedContent && !isReplyEvent ? 'mt-3' : isReplyEvent ? 'mb-1' : 'my-1') : 'mt-2'
            const tw_isRelatedContent = isRelatedContent ? `max-h-[76px] truncate text-sm border-l-8${isReplyEvent ? '' : ' cursor-pointer'}` : 'max-w-prose px-3 py-2'
            const bubbleContainerProps: ComponentProps<'div'> = {
                className: `${tw_isMine} ${tw_margin} ${tw_bg} ${tw_isRelatedContent} relative rounded-lg`,
                id: isRelatedContent ? '' : convertEventIdToValidHtmlId(event.event_id),
                onPointerOver: () => setIsHovered(true),
                onPointerOut: () => setIsHovered(false),
                onClick: evt => {
                    evt.stopPropagation()
                    evt.preventDefault()
                    fetchRelatedEvent && fetchRelatedEvent()
                },
                onDoubleClick: () => {
                    // for debug purposes
                    console.log('MessageBubble', event)
                }
            }
            const senderNameProps: ComponentProps<'p'> = {
                className: 'font-bold text-left',
                style: { color: senderName && (isMine ? '#56BA8E' : convertTextToColour(senderName)) }
            }
            const contentKey = event.content.msgtype || event.type
            const Content = contentKey in MessageContent ? MessageContent[event.content.msgtype || event.type] : Stub
            // TODO: maybe need to align with mobile later
            const isRedacted = !!event.unsigned?.redacted_because
            const shouldDisplaySender = !!senderName && !isRelatedContent
            const shouldDisplayOptions = !isRelatedContent && !isReplyEvent && !isRedacted && isHovered
            const bubble = (
                <div {...bubbleContainerProps}>
                    {shouldDisplayOptions && <RoomMessageOptions onPressReply={replyToEvent} onPressForward={forwardMessage} />}
                    {shouldDisplaySender && <p {...senderNameProps}>{senderName}</p>}
                    {/* TODO: styling and icon */}
                    {isRedacted ? <div className='italic text-[#737D8C]'>This message was deleted</div> : <Content {...dataProps} />}
                </div>
            )

            if (dateHeader) {
                const headerProps: ComponentProps<'div'> = {
                    className: 'px-3 py-1 rounded-lg text-sm text-[#00000050] bg-[#DDF1E8]'
                }

                return (
                    <>
                        <div className='mt-2 text-center'>
                            <span {...headerProps}>{dateHeader}</span>
                        </div>
                        {bubble}
                    </>
                )
            }

            return bubble
        }
    }

    return <HeadlessBubble {...headlessProps} />
}

interface RoomMessageOptionsProps {
    onPressReply: () => void
    onPressForward: () => void
}

const RoomMessageOptions: FC<RoomMessageOptionsProps> = ({ onPressReply, onPressForward }) => {
    const svgProps: Omit<ComponentProps<'svg'>, 'strokeWidth'> = {
        color: 'white',
        stroke: 'currentColor',
        fill: 'none',
        className: 'w-4 h-4 mx-1 cursor-pointer hover:text-[#b9e9cb]'
    }
    const replyProps: SvgProps = {
        ...svgProps,
        d: hiArrowUturnRight,
        onClick: onPressReply
    }
    const forwardProps: SvgProps = {
        ...svgProps,
        d: riShareForwardFill,
        onClick: onPressForward
    }

    return (
        <div className='absolute grid grid-cols-2 right-2 -top-4 px-1 py-1 rounded-md bg-[#00000050]'>
            <Svg {...replyProps} />
            <Svg {...forwardProps} />
        </div>
    )
}
