import { ActionId, ActionImpl } from 'kbar'
import { forwardRef, Fragment, Ref, useMemo } from 'react'

import { Svg } from '../Common'

interface ResultItemProps {
    action: ActionImpl
    active: boolean
    currentRootActionId: ActionId
}

export const ResultItem = forwardRef<HTMLDivElement, ResultItemProps>(({ action, active, currentRootActionId }, ref: Ref<HTMLDivElement>) => {
    const ancestors = useMemo(() => {
        if (!currentRootActionId) {
            return action.ancestors
        }
        const index = action.ancestors.findIndex(ancestor => ancestor.id === currentRootActionId)
        // +1 removes the currentRootAction; e.g.
        // if we are on the "Set theme" parent action,
        // the UI should not display "Set theme… > Dark"
        // but rather just "Dark"
        return action.ancestors.slice(index + 1)
    }, [action.ancestors, currentRootActionId])

    return (
        <div ref={ref} className={`${active ? 'rounded-lg bg-gray-500 text-gray-100' : 'text-gray-600'} flex cursor-pointer items-center justify-between rounded-lg px-4 py-2`}>
            <div className='flex items-center gap-2 text-base'>
                {typeof action.icon === 'string' ? <Svg d={action.icon} className='w-4 h-4' fill={active ? '#F3F4F6' : '#4b5563'} /> : action.icon}
                <div className='flex flex-col'>
                    <div className='line-clamp-1'>
                        {ancestors.length > 0 &&
                            ancestors.map(ancestor => (
                                <Fragment key={ancestor.id}>
                                    <span className='mr-3 opacity-70'>{ancestor.name}</span>
                                    <span className='mr-3'>›</span>
                                </Fragment>
                            ))}
                        <span>{action.name}</span>
                    </div>
                    {action.subtitle && <span className='text-sm'>{action.subtitle}</span>}
                </div>
            </div>
            {action.shortcut?.length ? (
                <div aria-hidden className='grid grid-flow-col gap-2'>
                    {action.shortcut.map(sc => (
                        <kbd key={sc} className={`${active ? 'bg-white  text-[#56BA8E]' : 'bg-gray-200 text-gray-500 '} flex cursor-pointer items-center justify-between rounded-md px-2 py-1`}>
                            {sc}
                        </kbd>
                    ))}
                </div>
            ) : null}
        </div>
    )
})
