import { FC } from 'react'

import { ArchiveStateHookParams, useArchiveState } from '../../hooks/useArchiveState'

type RenderableData = ReturnType<typeof useArchiveState> & {
    label: 'Archive' | 'Unarchive' | 'Loading...' | ''
}

type RenderProps = {
    render: (data: RenderableData) => any
}

export type ArchiveButtonDataProps = ArchiveStateHookParams & {
    showLabel?: boolean
}

export type ArchiveButtonProps = ArchiveButtonDataProps & RenderProps

export const ArchiveButton: FC<ArchiveButtonProps> = ({ roomId, showLabel, onToggle, render }) => {
    const { archiveState, isLoading, ...rest } = useArchiveState({ roomId, onToggle })

    return render({
        archiveState,
        label: showLabel ? (isLoading ? 'Loading...' : archiveState ? 'Unarchive' : 'Archive') : '',
        isLoading,
        ...rest
    })
}
