import { FC } from 'react'
import { IoClose } from 'react-icons/io5'

import { useContactOrGroupName, useMatrixRoom, useRoomAvatar } from '@closer/headless-components/index'

import { Avatar, AvatarProps } from '../Common/Avatar'

import { RoomMember } from './RoomMemberItem'

type RoomDetailProps = {
    roomId: string
    onClose: () => void
}

export const RoomDetail: FC<RoomDetailProps> = ({ roomId, onClose }) => {
    const { room } = useMatrixRoom(roomId)
    const { avatarData } = useRoomAvatar(room)
    const { contactOrGroupName } = useContactOrGroupName({ roomId })
    const avatarProps: AvatarProps = {
        imageUrl: avatarData?.url,
        name: contactOrGroupName || ''
    }

    return (
        <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50'>
            <div className='w-auto my-6 mx-auto max-w-3xl'>
                <div className='opacity-25 fixed inset-0 bg-black' onClick={onClose} />
                <div className='w-[400px] border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-4 overflow-y-auto'>
                    <div className='absolute top-2 right-2 rounded-md shadow-md cursor-pointer hover:bg-gray-200' onClick={onClose}>
                        <IoClose size={32} />
                    </div>
                    <div className='grid w-full justify-center'>
                        <Avatar {...avatarProps} largeMode />
                    </div>
                    <div className='text-xl font-bold text-center mt-4'>{contactOrGroupName}</div>
                    <div className='text-lg p-4'>Description:</div>
                    <div className='w-full bg-slate-100 rounded-lg max-h-24 overflow-auto p-4 pt-2'>{room?.topic}</div>
                    <div className='text-lg p-4'>Members:</div>
                    <div className='w-full bg-slate-100 rounded-lg max-h-[500px] overflow-auto'>
                        {room?.members.map(member => (
                            <RoomMember member={member} key={member.id} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
