import Image, { ImageProps } from 'next/image'

import { createPortal } from 'react-dom'
import { ComponentProps, FC, useEffect, useMemo, useRef, useState } from 'react'

import { Rect } from '@closer/types'
import { convertTextToColour, isMessage } from '@closer/utils'
import { ImageContent as HeadlessContent, ImageContentProps as HeadlessProps, ImageContentDataProps } from '@closer/headless-components/components'
import { useContactOrGroupName, useMatrix } from '@closer/headless-components/hooks'

import { Stub } from '../../Common/Stub'
import { hiPhoto, hiXMark, LoadingSpinner, Svg } from '../../Common'

import { MessageContent } from '.'

export const ImageContent: FC<ImageContentDataProps> = dataProps => {
    const { event, isReplyEvent } = dataProps
    const [isFullScreen, setIsFullScreen] = useState(false)
    const headlessProps: HeadlessProps = {
        ...dataProps,
        maxSize: {
            w: innerWidth - 40,
            h: innerHeight - 40
        },
        render: ({ url, isSticker, thumbnailUrl, thumbnailSize, size, timestamp }) => {
            if (isReplyEvent) {
                return <CompactImageContent event={event} />
            }

            const imageProps: ImageProps = {
                src: thumbnailUrl || url,
                width: thumbnailSize.w,
                height: thumbnailSize.h,
                alt: event.event_id,
                className: `rounded-lg ${isSticker ? '' : 'cursor-pointer'}`,
                onClick: isSticker ? undefined : () => setIsFullScreen(true)
            }

            return (
                <div className='rounded-lg overflow-hidden'>
                    <Image {...imageProps} alt={event.event_id} />
                    {timestamp && (
                        <div className='relative'>
                            {!isSticker && <div className='absolute rounded-full -right-5 -bottom-2 bg-black w-24 h-10 blur-lg -rotate-12 opacity-50' />}
                            <div className={`absolute right-2 bottom-1 text-right text-sm  ${isSticker ? 'rounded-full px-3 py-1 text-white bg-slate-400' : 'pl-2 text-[#ffffff90]'}`}>
                                <span className='z-10'>{timestamp}</span>
                            </div>
                        </div>
                    )}
                    {(event.co_events || []).map(coEvent => {
                        const coEventContentKey = coEvent.content.msgtype || coEvent.type
                        const CoEventContent = coEventContentKey in MessageContent ? MessageContent[coEventContentKey] : Stub
                        const tw_isCaption = isMessage(coEvent.content, ['m.notice']) ? 'px-1' : ''
                        const tw_isImage = isMessage(coEvent.content, ['m.image']) ? 'pt-1' : ''

                        return (
                            <div className={`${tw_isCaption} ${tw_isImage}`} style={{ width: thumbnailSize.w }} key={coEvent.event_id}>
                                <CoEventContent event={coEvent} />
                            </div>
                        )
                    })}
                    {isFullScreen && createPortal(<FullScreenImage url={url} size={size} onDismiss={() => setIsFullScreen(false)} />, document.body)}
                </div>
            )
        }
    }

    return <HeadlessContent {...headlessProps} />
}

export const CompactImageContent: FC<ImageContentDataProps> = ({ event }) => {
    const { info, url } = event.content
    const { client } = useMatrix()
    const { contactOrGroupName } = useContactOrGroupName({ matrixId: event.sender })
    const isMine = !!(client && client.getUserId() === event.sender)
    const senderName = isMine ? 'You' : contactOrGroupName || event.sender_name
    const previewProps = useMemo<Omit<ImageProps, 'alt'> | undefined>(() => {
        const src = (info.thumbnail_url && client?.mxcUrlToHttp(info.thumbnail_url)) || client?.mxcUrlToHttp(url)

        if (!src) {
            return
        }

        return { src, fill: true, style: { objectFit: 'cover' } }
    }, [client, info.thumbnail_url, url])
    const senderNameProps: ComponentProps<'p'> = {
        className: 'font-bold text-left col-span-2 text-sm truncate',
        style: { color: senderName && (isMine ? '#56BA8E' : convertTextToColour(senderName)) }
    }

    return (
        <div className='grid grid-cols-[1fr,min-content] gap-x-1'>
            <div className='grid grid-rows-[min-content,_1fr] grid-cols-[min-content,_1fr] p-2'>
                <p {...senderNameProps}>{senderName}</p>
                <Svg d={hiPhoto} className='w-5 h-5' stroke='none' fill='#65BA8E' fillRule='evenodd' clipRule='evenodd' />
                <p className='text-[#737D8C] px-2'>Image</p>
            </div>
            <div className='relative w-12 h-14'>{previewProps && <Image {...previewProps} alt='image preview' />}</div>
        </div>
    )
}

type FullScreenImageProps = {
    url: string
    size: Rect
    onDismiss: () => void
}

const FullScreenImage: FC<FullScreenImageProps> = ({ url, size, onDismiss }) => {
    const [isLoading, setIsLoading] = useState(true)
    const imageRef = useRef<HTMLImageElement>(null)

    const finishLoading = () => setIsLoading(false)

    useEffect(() => {
        const imageElement = imageRef.current

        if (!imageElement) {
            return
        }

        imageElement.addEventListener('load', finishLoading)

        return () => {
            imageElement.removeEventListener('load', finishLoading)
        }
    }, [])

    return (
        <div className='absolute top-0 left-0 w-full h-full z-20 bg-[#ffffff]/90'>
            <div className='relative grid place-items-center h-full'>
                <button className='absolute right-2 top-2 p-2 rounded-full bg-neutral-200' onClick={onDismiss}>
                    <Svg d={hiXMark} fill='none' stroke='currentColor' className='w-6 h-6 text-neutral-600' />
                </button>
                <Image src={url} width={size.w} height={size.h} alt='full screen image' ref={imageRef} />
                {isLoading && (
                    <div className='absolute'>
                        <LoadingSpinner />
                    </div>
                )}
            </div>
        </div>
    )
}
