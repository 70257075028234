import { EventHint } from '@sentry/types'
import { captureEvent, captureException, captureMessage, Event } from '@sentry/nextjs'

import { log } from '@closer/logger'

// TODO: may add conditional handling for server status
export function captureErrorToSentry(error: unknown) {
    captureException(error)
    log.error(error)
}

export function captureMessageToSentry(message: string, warn?: boolean) {
    captureMessage(message)
    if (warn) {
        log.error(message)
    } else {
        log.info(message)
    }
}

export function captureEventToSentry(event: Event, hint?: EventHint) {
    captureEvent(event, hint)
}
