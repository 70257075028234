import * as Sentry from '@sentry/nextjs'

import { FC, useEffect, useMemo, useState } from 'react'

import { chatService } from '@closer/matrix'
import { useMatrix } from '@closer/headless-components/hooks'

import { useValidRoute } from '../../hooks/router/useValidRoute'

import { ReminderBanner } from '../Reminders'
import { ScheduleSnippet } from '../ScheduleSend'
import { DropBox, DropBoxProps, FilesPreview, FilesPreviewProps, LoadingSpinner } from '../Common'

import { RoomHeaderTitle } from './RoomHeaderTitle'
import { RoomInput } from './RoomInput'
import { RoomTimelines } from './RoomTimelines'

export const RoomScreen: FC = () => {
    const { client } = useMatrix()
    const { f, x, r, isValid } = useValidRoute({
        f: _f => _f === 'RoomList' || _f === 'Reminders' || _f === 'Contact' || _f === 'Archive',
        x: _x => _x === 'View' || _x === 'ViewRoom' || _x === 'Forward',
        r: _r => !!_r
    })
    const [droppedFilesAsFromData, setDroppedFilesAsFormData] = useState<FormData>()
    const roomId = useMemo(() => {
        if (!isValid) {
            return
        }

        if (((f === 'RoomList' || f === 'Archive') && x === 'View') || (f === 'Reminders' && x === 'ViewRoom')) {
            return r
        } else if (f === 'RoomList' && x === 'Forward' && r) {
            return JSON.parse(r)[0]
        }
    }, [f, isValid, r, x])
    const chatRoom = useMemo(() => chatService.findChatRoomById(roomId), [roomId])
    const matrixRoom = useMemo(() => client?.getRoom(roomId), [client, roomId])

    useEffect(() => {
        if (!matrixRoom) {
            return
        }

        for (const pendingEvent of matrixRoom.getPendingEvents()) {
            pendingEvent.event.event_id && matrixRoom.removePendingEvent(pendingEvent.event.event_id)
            console.warn('pendingEvent', pendingEvent)
        }
    }, [matrixRoom])

    useEffect(() => {
        if (!chatRoom) {
            return
        }

        chatRoom.sendPendingEvents()
        chatRoom.sendReadReceipt().catch(error => Sentry.captureException(error))
    }, [chatRoom])

    if (!client) {
        return <LoadingSpinner />
    }

    if (!roomId) {
        return <div>No room data to display</div>
    }

    const dropBoxProps: DropBoxProps = {
        isEnabled: !droppedFilesAsFromData,
        className: 'relative h-full grid grid-rows-[repeat(2,min-content),1fr,repeat(2,min-content)]',
        onFilesDropped: setDroppedFilesAsFormData
    }
    const filePreviewProps: FilesPreviewProps = {
        roomId,
        formData: droppedFilesAsFromData,
        onSend: () => {
            console.log('sending...')
        },
        onSendResponse: res => {
            console.log('done sending', res)
        },
        onDismiss: setDroppedFilesAsFormData
    }

    return (
        <DropBox {...dropBoxProps}>
            <RoomHeaderTitle roomId={roomId} />
            <ReminderBanner roomId={roomId} />
            <RoomTimelines roomId={roomId} />
            <ScheduleSnippet roomId={roomId} />
            <RoomInput roomId={roomId} onAddFiles={setDroppedFilesAsFormData} />
            {droppedFilesAsFromData && <FilesPreview {...filePreviewProps} />}
        </DropBox>
    )
}
