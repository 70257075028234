import _ from 'lodash'
import { AccountRoomTagData } from '@closer/types'
import { LabelModalView } from './LabelModalView'
import { ModalPortal } from '../Common'
import { Q } from '@nozbe/watermelondb'
import { TagIcon } from '@heroicons/react/24/outline'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useRouter } from 'next/router'
import { AccountRoomTag, TableName } from '@closer/watermelondb'
import { Action, useRegisterActions } from 'kbar'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
    LabelItem as HeadlessLabelItem,
    LabelScrollView as HeadlessLabelScrollView,
    LabelItemProps,
    useScrollViewTagStore
} from '@closer/headless-components/components'

import { composeUrlState, useValidRoute } from '../../hooks/router/useValidRoute'
import { getLabelName } from '@closer/utils'

const iconStyle = 'w-4 h-4'
const labelButtonStyle =
    'min-w-[70px] flex justify-center items-center my-1 text-center px-1 py-2 text-xs font-medium leading-tight text-[#4b5563] hover:isolate rounded border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent '
const unreadBadgeStyle =
    'h-[15px] w-[35px] flex items-center justify-center whitespace-nowrap overflow-hidden text-ellipsis bg-[#56BA8E] p-1 rounded text-white text-xs ml-1.5'

export const LabelScrollView: FC = () => {
    const Render = (data: (AccountRoomTag | AccountRoomTagData)[]) => {
        const [openModal, setOpenModal] = useState(false)
        const [accountRoomTags, setAccountRoomTags] = useState<AccountRoomTag[]>([])
        // FIXME: remove store from front-end
        const [setCurrentTag] = useScrollViewTagStore(state => [state.setCurrentTag])
        const database = useDatabase()
        const router = useRouter()
        const { f } = useValidRoute()

        useEffect(() => {
            const subscribe = database.get<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS).query(Q.sortBy('order', Q.asc)).observe().subscribe(setAccountRoomTags)
            return () => subscribe.unsubscribe()
        }, [database])

        useEffect(() => {
            const label = router.query['m']

            if (_.isString(label)) {
                const { name, id } = JSON.parse(label)
                setCurrentTag(name, id)
            }
        }, [router, setCurrentTag])

        const navigateToLabel = useCallback(
            (name: string, id: string) => {
                const urlQuery = JSON.stringify({ name, id })
                router.push(composeUrlState({ f: f ? f : 'RoomList', m: urlQuery }))
            },
            [f, router]
        )

        const labelAction: Action[] = useMemo(
            () => [
                {
                    id: 'labels',
                    name: 'Go to label',
                    shortcut: ['g', 'l'],
                    keywords: 'labels',
                    icon: <TagIcon className={iconStyle} />,
                    section: 'Pages'
                },
                {
                    id: 'labels-all',
                    parent: 'labels',
                    name: 'All',
                    perform: () => {
                        navigateToLabel('All', 'tag-all')
                        setCurrentTag('All', 'tag-all')
                    }
                },
                {
                    id: 'labels-inbox',
                    parent: 'labels',
                    name: 'Untagged',
                    perform: () => {
                        navigateToLabel('Untagged', 'tag-inbox')
                        setCurrentTag('Untagged', 'tag-inbox')
                    }
                },
                ...accountRoomTags.map(accountRoomTag => ({
                    id: `switch-labels-${accountRoomTag.id}`,
                    parent: 'labels',
                    name: `${accountRoomTag.name}`,
                    perform: () => {
                        navigateToLabel(accountRoomTag.name, accountRoomTag.id)
                        setCurrentTag(accountRoomTag.name, accountRoomTag.id)
                    }
                }))
            ],
            [accountRoomTags, navigateToLabel, setCurrentTag]
        )

        useRegisterActions(labelAction, [labelAction])

        return (
            <>
                <div className='flex border-t-[1px]'>
                    <button onClick={() => setOpenModal(true)}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                            <path fill='currentColor' d='M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z' />
                        </svg>
                    </button>
                    <ul className='flex flex-row w-full overflow-x-auto'>
                        {data.map(accountRoomTag => {
                            const props: LabelItemProps = {
                                accountRoomTag,
                                render: ({ unreadNumber, accountRoomTagName, handleOnPressTag, isCurrentTag, accountRoomTagId, type }) => {
                                    const onClickLabel = () => {
                                        navigateToLabel(accountRoomTagName, accountRoomTagId)
                                        handleOnPressTag()
                                    }
                                    return (
                                        <li className='flex flex-col h-full justify-center max-w-[110px]'>
                                            <button
                                                onClick={onClickLabel}
                                                className={labelButtonStyle + `${type === 'team' ? 'bg-slate-200 hover:bg-slate-300' : ''}`}>
                                                <a
                                                    className={`whitespace-nowrap overflow-hidden text-ellipsis ${
                                                        unreadNumber ? unreadNumber > 0 && 'max-w-[65px]' : 'max-w-full'
                                                    }`}>
                                                    {getLabelName(accountRoomTag as AccountRoomTag)}
                                                </a>
                                                {unreadNumber
                                                    ? unreadNumber > 0 && <span className={unreadBadgeStyle}>{unreadNumber > 99 ? '99+' : unreadNumber}</span>
                                                    : null}
                                            </button>
                                            <span
                                                className={`relative bottom-0 right-0 left-0 rounded-full w-full h-1 mb-1 bg-[#56BA8E] ${
                                                    isCurrentTag ? 'opacity-100' : 'opacity-0'
                                                }`}
                                            />
                                        </li>
                                    )
                                }
                            }
                            return <HeadlessLabelItem {...props} key={accountRoomTag.id} />
                        })}
                    </ul>
                </div>
                {openModal && (
                    <ModalPortal selector={'#modal'}>
                        <LabelModalView setOpenModal={setOpenModal} />
                    </ModalPortal>
                )}
            </>
        )
    }

    return <HeadlessLabelScrollView render={Render} />
}
