import tinykeys from 'tinykeys'
import withObservables from '@nozbe/with-observables'

import { IoChevronBackOutline } from 'react-icons/io5/'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useRouter } from 'next/router'
import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react'
import { Database, Q } from '@nozbe/watermelondb'

import { hiddenUserList } from '@closer/utils'
import { ChatRoomSummary, RoomLabelRecord, TableName } from '@closer/watermelondb'

import { composeUrlState, useValidRoute } from '../../hooks/router/useValidRoute'

import { ModalPortal } from '../Common/ModalPortal'
import { RoomContextMenu } from '../RoomList/RoomContextMenu/RoomContextMenu'
import { RoomListRow } from '../RoomList/RoomListRow'
import { useRoomContextMenu } from '../RoomList/hooks/useRoomContextMenu'

interface ArchivedListProps {
    archivedChatRoomSummaries: ChatRoomSummary[]
}
const _EnhancedArchivedList: React.FC<ArchivedListProps> = ({ archivedChatRoomSummaries }) => {
    const roomListRef = useRef<HTMLDivElement | null>(null)
    const { openMenu, closeMenu, menuOpened, selectedRoom, point } = useRoomContextMenu()
    const { r } = useValidRoute()
    const routeIndex = r ? archivedChatRoomSummaries.findIndex(chat => chat.id === r) : 0
    const [selectedIndex, setSelectedIndex] = useState(routeIndex > -1 ? routeIndex : 0)

    const router = useRouter()
    const navigateToRoom = useCallback((roomId: string) => router.push(composeUrlState({ f: 'Archive', x: 'View', r: roomId })), [router])

    const archivedListContainerProps: ComponentProps<'div'> = {
        className: 'h-full overflow-y-auto',
        ref: element => (roomListRef.current = element),
        tabIndex: 0
    }

    useEffect(() => {
        const focusElement = (index: number) => {
            const element = roomListRef.current?.children[index]
            if (element instanceof HTMLElement) {
                element.focus()
            }
        }
        const unsubscribe = tinykeys(window, {
            ['$mod+ArrowLeft']: event => {
                event.preventDefault()
                if (selectedIndex < 0 || selectedIndex > archivedChatRoomSummaries.length - 1) {
                    setSelectedIndex(0)
                    return focusElement(0)
                }
                focusElement(selectedIndex)
            }
        })

        return () => unsubscribe()
    }, [archivedChatRoomSummaries.length, selectedIndex])

    useEffect(() => {
        if (!roomListRef.current) {
            return
        }
        const unsubscribe = tinykeys(roomListRef.current, {
            ['ArrowUp']: event => {
                event.preventDefault()
                setSelectedIndex(prev => (prev === 0 ? 0 : prev - 1))
            },
            ['ArrowDown']: event => {
                event.preventDefault()
                setSelectedIndex(prev => (prev === archivedChatRoomSummaries.length - 1 ? archivedChatRoomSummaries.length - 1 : prev + 1))
            },
            ['Enter']: event => {
                event.preventDefault()
                navigateToRoom(archivedChatRoomSummaries[selectedIndex].id)
            }
        })
        return () => unsubscribe()
    }, [selectedIndex, navigateToRoom, archivedChatRoomSummaries])

    return (
        <>
            <div>
                <button onClick={() => router.push(composeUrlState({ f: 'RoomList' }))} className='grid grid-cols-[min-content,_min-content] items-center h-10 bg-white'>
                    <IoChevronBackOutline />
                    Back
                </button>
            </div>
            {menuOpened && (
                <ModalPortal selector='#modal'>
                    <RoomContextMenu point={point} room={selectedRoom} closeMenu={closeMenu} />
                </ModalPortal>
            )}
            <div {...archivedListContainerProps}>
                {archivedChatRoomSummaries.map((chatRoomSummary, i) => {
                    const isSelected = i === selectedIndex
                    const roomListRowProps = {
                        handleClick: () => {
                            setSelectedIndex(i)
                            navigateToRoom(chatRoomSummary.id)
                        },
                        chatRoomSummary,
                        openMenu,
                        isSelected
                    }
                    return <RoomListRow {...roomListRowProps} key={chatRoomSummary.id} />
                })}
            </div>
        </>
    )
}

const archivedListQuery = (database: Database, tagId: string, search: string) => {
    const baseQ = database.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
    const filterHiddenQ = Q.where('name', Q.notIn(hiddenUserList))
    const archivedQOrSearchQ = search.length > 0 ? Q.where('name', Q.like(`%${Q.sanitizeLikeString(search)}%`)) : Q.where('archive', true)
    const sort = Q.sortBy('timestamp', Q.desc)
    const inboxQ = Q.unsafeLokiTransform((rawRecords, loki) => {
        const labelRecords = loki.getCollection(TableName.ROOM_LABEL_RECORDS).data.map((record: Partial<RoomLabelRecord>) => record.roomId)
        return rawRecords.filter(rawRecord => {
            return !labelRecords.includes(rawRecord.id)
        })
    })
    const typeQ = tagId === 'tag-all' ? undefined : tagId === 'tag-inbox' ? inboxQ : Q.on(TableName.ROOM_LABEL_RECORDS, 'accountRoomTagId', tagId)
    const queries = [filterHiddenQ, archivedQOrSearchQ, typeQ || [], sort].flat()

    return {
        archivedChatRoomSummaries: baseQ.query(...queries).observeWithColumns(['timestamp', 'pinTime', 'archive', 'passedReminderTime', 'nextReminderTime', 'nextScheduleSendTime']) as any
    }
}

const enhance = withObservables(['search', 'tagId'], ({ database, search, tagId }: { database: Database; search: string; tagId: string }) => {
    return archivedListQuery(database, tagId, search)
})
const EnhancedArchivedList = enhance(_EnhancedArchivedList)

interface ArchivedListObserveProps {
    search: string
    tagId: string
}

export const ArchivedList: React.FC<ArchivedListObserveProps> = ({ search, tagId }) => {
    // const { t } = useTranslation()
    const database = useDatabase()

    return <EnhancedArchivedList database={database} search={search} tagId={tagId} />
}
