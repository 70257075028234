import { captureException } from '@sentry/nextjs'
import { useMutation } from '@tanstack/react-query'
import { FC, useRef, useState } from 'react'

import { AccountRoomTagData } from '@closer/types'
import { api } from '@closer/api'
import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { AccountRoomTag, TableName } from '@closer/watermelondb'

import { LoadingSpinner } from '../Common/LoadingSpinner'
import { Switch } from '@headlessui/react'
import { Svg, SvgProps } from '../Common/Svg'

interface LabelCreatingCardProps {
    onCompleteCreate: (complete: boolean) => void
}

export const LabelCreatingCard: FC<LabelCreatingCardProps> = ({ onCompleteCreate }) => {
    const { tableCurrentAction, write } = useWDBOps()
    const inputNameRef = useRef<HTMLInputElement>(null)
    const [isTeam, setIsTeam] = useState<boolean>(false)
    const [isTooltipShow, setIsTooltipShow] = useState<boolean>()

    const { isLoading: isLoadingCreate, mutate: createMutate } = useMutation<AccountRoomTagData, Error, Partial<AccountRoomTagData>>(
        tag => {
            return api.accountRoomTag.create({ name: tag.name, type: tag.type })
        },
        {
            onSuccess: async createdTag => {
                const { create } = tableCurrentAction<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS)
                await write(async () => {
                    await create({
                        _raw: { id: createdTag.id, _changed: '', _status: 'created' },
                        name: createdTag.name,
                        order: createdTag.order,
                        type: createdTag.type
                    })
                }, TableName.ACCOUNT_ROOM_TAGS)
            },
            onError: error => {
                captureException(error)
            }
        }
    )

    const iconProps: SvgProps = {
        className: 'p-1 rounded-md hover:bg-[#00000010] cursor-pointer w-8 h-8 text-black',
        d: 'M21 7v12q0 .825-.588 1.413T19 21H5q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h12l4 4Zm-9 11q1.25 0 2.125-.875T15 15q0-1.25-.875-2.125T12 12q-1.25 0-2.125.875T9 15q0 1.25.875 2.125T12 18Zm-6-8h9V6H6v4Z'
    }

    const onCreate = (e: any) => {
        e.preventDefault()
        if (inputNameRef.current) {
            createMutate({
                name: inputNameRef.current.value,
                type: isTeam ? 'team' : 'private'
            })
        }
        onCompleteCreate(true)
    }

    return (
        <li className='p-4 mb-3 flex justify-between items-center bg-white border border-white shadow rounded-lg cursor-move'>
            <form className='flex justify-between items-center w-full' onSubmit={onCreate}>
                <div className='flex items-center'>
                    <input
                        data-testid='LabelCreatingCard-input'
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                        placeholder='name'
                        required
                        ref={inputNameRef}
                    />
                </div>
                <div className='flex items-center gap-4'>
                    <span className='bg-teal-600 text-white px-2 py-1 w-20 text-center rounded'>{isTeam ? 'team' : 'private'}</span>
                    <Switch
                        checked={isTeam}
                        defaultValue='true'
                        onChange={setIsTeam}
                        className={`${isTeam ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                        onMouseEnter={() => setIsTooltipShow(true)}
                        onMouseLeave={() => setIsTooltipShow(false)}>
                        <div
                            className={`${
                                isTooltipShow ? 'opacity-100' : 'opacity-0 none'
                            } min-w-max -top-8 z-20 max-w-max absolute transition duration-500 ease-in-out shadow-lg bg-black p-1 rounded`}>
                            <p className='text-xs leading-4 text-white'>Toggle to set as team shared label</p>
                        </div>
                        <span className='sr-only'>Tag Type</span>
                        <span className={`${isTeam ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                    </Switch>
                </div>
                <div className='flex items-center'>
                    <button
                        data-testid='LabelCreatingCard-saveButton'
                        type='submit'
                        className='action-button p-1 focus:outline-none focus:shadow-outline text-teal-500 hover:text-teal-600'>
                        {isLoadingCreate ? <LoadingSpinner /> : <Svg {...iconProps} />}
                    </button>
                </div>
            </form>
        </li>
    )
}
