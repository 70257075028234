import { api } from '@closer/api'
import { getImageUrl } from 'apps/web/utils/util'
import { Q } from '@nozbe/watermelondb'
import { SelectRoomScrollViewItem } from './SelectRoomScrollViewItem'
import { useMutation } from '@tanstack/react-query'
import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { AccountRoomTag, ChatRoomSummary, RoomLabelRecord, TableName } from '@closer/watermelondb'
import { FC, MutableRefObject, useEffect, useState } from 'react'
import {
    LabelSelectRoomItem as HeadlessLabelSelectRoomItem,
    LabelSelectRoomScrollView as HeadLessLabelSelectRoomScrollView,
    LabelSelectRoomList as HeadlessSelectRoomList,
    labelRoomListStore
} from '@closer/headless-components/components'
import { LabelUpdateByRoomIdsV2Body, RoomLabelRecordData } from '@closer/types'

import { Avatar } from '../Common/Avatar'
import { captureException } from '@sentry/nextjs'
import { getLabelName } from '@closer/utils'
import { SearchBar } from '../Common/SearchBar'

interface LabelSelectRoomListProps {
    tag: AccountRoomTag
    onComplete: () => void
}

export const LabelSelectRoomList: FC<LabelSelectRoomListProps> = ({ tag, onComplete }) => {
    const Render = (chatRoomSummaries: ChatRoomSummary[], selectedRoomIdsRef: MutableRefObject<string[]>) => {
        const [setSearch] = labelRoomListStore(state => [state.setSearch])
        const [isLoading, setIsLoading] = useState<boolean>(false)
        const [selectedRoomIds, setSelectRoomIds] = labelRoomListStore(state => [state.selectedRoomIds, state.setSelectRoomIds])
        const { tableCurrentAction, write, batch } = useWDBOps()
        const { all, preCreate } = tableCurrentAction<RoomLabelRecord>(TableName.ROOM_LABEL_RECORDS)

        const { mutate } = useMutation<RoomLabelRecordData[] | undefined, Error, LabelUpdateByRoomIdsV2Body>(
            body => {
                return api.labelRecords.updateByRoomIdsV2(body)
            },
            {
                onSuccess: async newRecords => {
                    if (!newRecords) {
                        return
                    }
                    try {
                        const toDelete = await all([Q.where('accountRoomTagId', tag.id)])
                        const toDeleteRecords = toDelete.map(record => record.prepareDestroyPermanently())
                        const toCreateRecords = newRecords.map(record => {
                            return preCreate({
                                _raw: { id: record.id, _changed: '', _status: 'created' },
                                accountRoomTagId: record.accountRoomTagId ? record.accountRoomTagId : record.teamLabelId,
                                roomId: record.roomId,
                                roomAccountDatumId: record.roomAccountDatumId ? record.roomAccountDatumId : ''
                            })
                        })
                        await write(async () => await batch(toDeleteRecords), TableName.ROOM_LABEL_RECORDS)
                        await write(async () => await batch(toCreateRecords), TableName.ROOM_LABEL_RECORDS)
                        setIsLoading(false)
                        onComplete()
                    } catch (error) {
                        captureException(error, {
                            extra: {
                                newRecords,
                                error
                            }
                        })
                    } finally {
                        setSelectRoomIds([])
                        setIsLoading(false)
                        setSearch('')
                    }
                },
                onError: error => {
                    //todo
                }
            }
        )

        const handleConfirm = async () => {
            setIsLoading(true)
            const roomIdsCreate = selectedRoomIds.filter(roomId => !selectedRoomIdsRef.current.includes(roomId))
            const roomIdsDelete = selectedRoomIdsRef.current.filter(roomId => !selectedRoomIds.includes(roomId))
            mutate({ roomIdsCreate: roomIdsCreate, roomIdsDelete: roomIdsDelete, accountRoomTagId: tag.id, type: tag.type })
        }

        const RenderItem = (chatRoomSummary: ChatRoomSummary, labels: AccountRoomTag[]) => {
            const [checked, setChecked] = useState<boolean>(() => {
                const existed = labels.find(label => label.id === tag.id)
                return existed ? true : false
            })
            const [handleSelect, handleUnSelect] = labelRoomListStore(state => [state.handleSelect, state.handleUnSelect])

            useEffect(() => {
                if (selectedRoomIds.includes(chatRoomSummary.id)) {
                    setChecked(true)
                } else {
                    setChecked(false)
                }
            }, [chatRoomSummary.id])

            const handlePress = () => {
                if (checked) {
                    handleUnSelect(chatRoomSummary.id)
                } else {
                    handleSelect(chatRoomSummary.id)
                }
                setChecked(!checked)
            }

            return (
                <div className='flex p-3 items-center'>
                    <Avatar imageUrl={chatRoomSummary.avatar ? getImageUrl(chatRoomSummary.avatar) ?? undefined : undefined} name={chatRoomSummary.name} />
                    <div className='justify-between items-start grow p-3'>
                        <span>{chatRoomSummary.name}</span>
                        <div className='flex flex-wrap'>
                            {labels.map(label => (
                                <p
                                    key={label.id}
                                    className='whitespace-nowrap overflow-hidden text-ellipsis max-w-[60px] bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300'>
                                    {getLabelName(label)}
                                </p>
                            ))}
                        </div>
                    </div>
                    <input
                        data-testid={`LabelSelectRoomList-${chatRoomSummary.id}-checkBox`}
                        type='checkbox'
                        onChange={handlePress}
                        checked={checked}
                        className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                    />
                </div>
            )
        }

        const renderSelectRoomScrollView = (roomIds: string[]) => {
            return (
                <div className='min-h-[55px] flex items-center overflow-x-scroll'>
                    {roomIds.map(id => (
                        <SelectRoomScrollViewItem key={`selected-${id}`} id={id} />
                    ))}
                </div>
            )
        }
        return (
            <div className='flex flex-col h-[500px]'>
                <SearchBar scope='conversations' onChangeText={setSearch} />
                <div className='h-full overflow-auto'>
                    {chatRoomSummaries.map(chatRoomSummary => (
                        <HeadlessLabelSelectRoomItem chatRoomSummary={chatRoomSummary} render={RenderItem} key={chatRoomSummary.id} tagId={tag.id} />
                    ))}
                </div>
                <HeadLessLabelSelectRoomScrollView render={renderSelectRoomScrollView} />
                <button
                    onClick={handleConfirm}
                    disabled={isLoading}
                    type='button'
                    className='h-[50px] bg-indigo-500 px-2 py-1 text-xs rounded-b-lg font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'>
                    Confirm
                </button>
            </div>
        )
    }

    return <HeadlessSelectRoomList tagId={tag.id} render={Render} />
}
