import { FC, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { R, RemindItem } from '@closer/types'

import { useTimer } from '../../hooks/useTimer'

import { getNextOrPassedReminderTime, reminderApi } from './index'

export interface PartialSnippetProps extends RemindItem {
    onEdit: () => void
    onDelayedComplete: () => void
}

interface RenderableBannerData {
    reminderSnippets: PartialSnippetProps[]
}

interface RenderBannerProps {
    renderBanner: (data: RenderableBannerData) => any
    renderLoading: () => any
    renderError: (Error: unknown) => any
    renderUpdateError: (Error: unknown) => any
}

export interface ReminderBannerProps extends RenderBannerProps {
    roomId: string
    handlePress: (reminder: RemindItem) => void
    handleComplete?: () => void
}

export const ReminderBanner: FC<ReminderBannerProps> = ({ roomId, handlePress, handleComplete, renderBanner, renderLoading, renderError, renderUpdateError }) => {
    const queryClient = useQueryClient()
    const { updateChatRoomSummary } = useWDBOps()

    const query = {
        read: useQuery([R.REMIND_ITEMS], () => reminderApi.read({ status: 'active', isDone: state => !state }), {
            select: _reminders => _reminders?.filter(_reminder => _reminder.roomId === roomId && !_reminder.isDone)
        }),
        update: useMutation<RemindItem | undefined, Error, RemindItem>(
            _reminder => {
                return reminderApi.update(_reminder.id, Object.assign(_reminder, { isDone: true }))
            },
            {
                onSuccess: async response => {
                    if (!response) {
                        return
                    }

                    await queryClient.refetchQueries([R.REMIND_ITEMS])
                    await updateChatRoomSummary(roomId, getNextOrPassedReminderTime(response, queryClient))
                }
            }
        )
    }

    if (!query.read.data) {
        return renderLoading()
    }

    if (query.read.error) {
        return renderError(query.read.error)
    }

    if (query.update.error) {
        return renderUpdateError(query.update.error)
    }

    const reminderSnippets = query.read.data.map(_reminder => {
        return {
            ..._reminder,
            onEdit: () => handlePress(_reminder),
            onDelayedComplete: () => {
                query.update.mutate(_reminder)
                handleComplete && handleComplete()
            }
        }
    })

    return renderBanner({ reminderSnippets })
}

interface PartialReminder extends Pick<RemindItem, 'remindTime'> {
    //
}

interface RenderableSnippetData {
    completed: boolean
    remindTimeNotPassed: boolean
    onPressDone: () => void
}

interface RenderSnippetProps {
    renderSnippet: (data: RenderableSnippetData) => any
}

export interface ReminderSnippetProps extends PartialReminder, RenderSnippetProps {
    onDelayedComplete: () => void
}

export const ReminderSnippet: FC<ReminderSnippetProps> = ({ remindTime, renderSnippet, onDelayedComplete }) => {
    const { computeShouldTick } = useTimer({ every: 'minute', until: remindTime })
    const [delayComplete, setDelayComplete] = useState<NodeJS.Timeout>()
    const [completed, setCompleted] = useState(false)

    useEffect(() => {
        setDelayComplete(undefined)
        setCompleted(false)
    }, [remindTime])

    const remindTimeNotPassed = computeShouldTick()
    const onPressDone = () => {
        if (delayComplete) {
            clearTimeout(delayComplete)
            setDelayComplete(undefined)
            setCompleted(false)
        }
        //
        else {
            setDelayComplete(setTimeout(onDelayedComplete, 1000))
            setCompleted(true)
        }
    }

    useEffect(() => {
        setDelayComplete(undefined)
        setCompleted(false)
    }, [remindTime])

    return renderSnippet({
        completed,
        remindTimeNotPassed,
        onPressDone
    })
}
