import { FC } from 'react'

import { ArchiveButton, ArchiveButtonProps } from '../../Archive/ArchiveButton'

import { LabelButton } from './LabelButton'
import { NewReminder } from './NewReminder'
import { NewSchedule } from './NewSchedule'
import { NewSmartflow } from './NewSmartflow'
import { PinButton } from './PinButton'

export interface RoomContextMenuProps {
    point: {
        x: number
        y: number
    }
    room: {
        roomId: string
        name: string
        pinTime: Date | null
        archive: boolean
    }
    closeMenu: () => void
}

const commonStyle = 'w-full items-center border-b-2 border-gray-100 text-[#56BA8E] hover:bg-gray-100'
const containerStyle = `flex flex-row p-2 ${commonStyle}`
const iconStyle = 'mx-2'

export const RoomContextMenu: FC<RoomContextMenuProps> = ({ point = { x: 0, y: 0 }, room, closeMenu }) => {
    const buttonsProps = {
        ...room,
        containerStyle,
        iconStyle,
        closeMenu
    }
    const archiveProps: ArchiveButtonProps = {
        roomId: room.roomId,
        showLabel: true,
        buttonStyle: `grid grid-cols-[min-content,1fr] gap-2 px-4 py-2 text-left ${commonStyle}`,
        spinnerSize: 4,
        svgStyle: 'w-4 h-4',
        onToggle: closeMenu
    }

    return (
        <div className={'w-40 h-75 absolute border-2 rounded-md border-gray-100 bg-white z-20'} style={{ top: point.y, left: point.x }}>
            <div className='w-full p-2 border-b-2 border-gray-100 truncate'>{room.name}</div>
            <ArchiveButton {...archiveProps} />
            <PinButton {...buttonsProps} />
            <LabelButton {...buttonsProps} />
            <NewReminder {...buttonsProps} />
            <NewSchedule {...buttonsProps} />
            <NewSmartflow {...buttonsProps} />
        </div>
    )
}
