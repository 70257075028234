import tinykeys from 'tinykeys'

import { useEffect } from 'react'

export const useSearchHotKey = (element: HTMLElement | null) => {
    useEffect(() => {
        const unsubscribe = tinykeys(window, {
            ['$mod+f']: (event: KeyboardEvent) => {
                event.preventDefault()
                element?.focus()
            }
        })

        return () => unsubscribe()
    }, [element])
}

export const useModRightFocusHotKey = (element: HTMLElement | null, callback?: () => void) => {
    useEffect(() => {
        const unsubscribe = tinykeys(window, {
            ['$mod+ArrowRight']: event => {
                event.preventDefault()
                element?.focus()
                callback && callback()
            }
        })

        return () => unsubscribe()
    }, [callback, element])
}

export const useModLeftFocusHotKey = (element: HTMLElement | null, callback?: () => void) => {
    useEffect(() => {
        const unsubscribe = tinykeys(window, {
            ['$mod+ArrowLeft']: event => {
                event.preventDefault()
                element?.focus()
                callback && callback()
            }
        })

        return () => unsubscribe()
    }, [callback, element])
}
