import { useCallback, useEffect, useState } from 'react'

import { RoomContextMenuProps } from '../RoomContextMenu'

export const useRoomContextMenu = () => {
    const [menuOpened, setMenuOpened] = useState(false)
    const [selectedRoom, setSelectedRoom] = useState<RoomContextMenuProps['room']>({
        roomId: '',
        name: '',
        pinTime: null,
        archive: false
    })
    const [point, setPoint] = useState({ x: 0, y: 0 })
    const handleClick = useCallback(
        (e: MouseEvent) => {
            if (e.pageX > point.x && e.pageX < point.x + 160 && e.pageY > point.y && e.pageY < point.y + 300) {
                return
            }
            setMenuOpened(false)
        },
        [point]
    )
    useEffect(() => {
        if (!window) {
            return
        }
        window.addEventListener('click', handleClick)
        window.addEventListener('wheel', handleClick)
        return () => {
            window.removeEventListener('click', handleClick)
            window.removeEventListener('wheel', handleClick)
        }
    }, [handleClick])
    const closeMenu = () => setMenuOpened(false)
    const openMenu = (xCoordinate: number, yCoordinate: number, room: RoomContextMenuProps['room']) => {
        setMenuOpened(true)
        setPoint({ x: xCoordinate, y: window.innerHeight - yCoordinate < 300 ? yCoordinate - 300 : yCoordinate })
        setSelectedRoom(room)
    }

    return { openMenu, closeMenu, menuOpened, selectedRoom, point }
}
