import { captureException } from '@sentry/nextjs'
import { Q } from '@nozbe/watermelondb'
import { useMutation } from '@tanstack/react-query'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { AccountRoomTagData } from '@closer/types'
import { api } from '@closer/api'
import { getLabelName, hiddenUserList } from '@closer/utils'
import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { AccountRoomTag, ChatRoomSummary, RoomLabelRecord, TableName } from '@closer/watermelondb'

import { Svg, SvgProps } from '../Common/Svg'

export interface LabelCardProps {
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void
    setCurrentRoomTag: () => void
    accountRoomTag: AccountRoomTag
    handleOnDragEnd: () => void
    openShowModal: () => void
    setTargetDeleteRoomTag: (accountRoomTag: AccountRoomTag) => void
}

export const LabelCard: FC<LabelCardProps> = ({
    accountRoomTag,
    index,
    moveCard,
    setCurrentRoomTag,
    handleOnDragEnd,
    setTargetDeleteRoomTag,
    openShowModal
}) => {
    const cardRef = useRef<any>(null)
    const [isEdit, setIsEdit] = useState(false)
    const [currentText, setCurrentText] = useState(accountRoomTag.name)
    const { tableCurrentAction } = useWDBOps()
    const { observe } = useMemo(() => {
        return tableCurrentAction<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
    }, [tableCurrentAction])
    const [totalChatRoomSummary, setTotalChatRoomSummary] = useState<number>(0)

    useEffect(() => {
        const queries: Array<Q.Clause> = [Q.where('name', Q.notIn(hiddenUserList))]

        if (index === -1) {
            queries.push(
                Q.unsafeLokiTransform((rawRecords, loki) => {
                    const labelRecords = loki.getCollection(TableName.ROOM_LABEL_RECORDS).data.map((record: Partial<RoomLabelRecord>) => record.roomId)
                    return rawRecords.filter(rawRecord => {
                        return !labelRecords.includes(rawRecord.id)
                    })
                })
            )
        }

        if (index >= 0) {
            queries.push(Q.on(TableName.ROOM_LABEL_RECORDS, 'accountRoomTagId', accountRoomTag.id))
        }

        const sub = observe([...queries]).subscribe(x => setTotalChatRoomSummary(x.length))
        return () => sub.unsubscribe()
    }, [accountRoomTag.id, index, observe])

    const { isLoading: isLoadingUpdate, mutate: updateMutate } = useMutation<AccountRoomTagData, Error, Partial<AccountRoomTagData>>(
        (tag: AccountRoomTagData) => {
            return api.accountRoomTag.update(accountRoomTag.id, { name: currentText, order: tag.order, type: tag.type })
        },
        {
            onSuccess: async (updatedTag: AccountRoomTag) => {
                try {
                    if (accountRoomTag) {
                        await accountRoomTag.setName(updatedTag.name)
                    } else {
                    }
                } catch (error) {
                    captureException(error)
                }
            },
            onError: error => {
                captureException(error)
            }
        }
    )

    let [{ isDragging }, drag] = useDrag({
        type: 'LabelCard',
        item: () => ({ id: accountRoomTag.id, index }),
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        end: () => {
            handleOnDragEnd()
        }
    })

    let [, drop] = useDrop({
        accept: 'LabelCard',
        hover(item: any, monitor) {
            const dragIndex = item.index
            const hoverIndex = index
            if (hoverIndex === dragIndex) {
                return
            }
            const { top, bottom } = cardRef.current?.getBoundingClientRect()!
            const halfOfHoverHeight = (bottom - top) / 2
            const { y } = monitor.getClientOffset()!
            const hoverClientY = y - top
            if ((dragIndex < hoverIndex && hoverClientY > halfOfHoverHeight) || (dragIndex > hoverIndex && hoverClientY < halfOfHoverHeight)) {
                moveCard(dragIndex, hoverIndex)
                item.index = hoverIndex
            }
        }
    })

    const opacity = isDragging ? 0.1 : 1
    index >= 0 && drag(cardRef)
    index >= 0 && drop(cardRef)

    const onEdit = () => {
        if (isEdit) {
            updateMutate({ name: currentText, order: accountRoomTag.order, type: accountRoomTag.type })
            setIsEdit(false)
        } else {
            setIsEdit(true)
        }
    }

    const openModal = () => {
        setTargetDeleteRoomTag(accountRoomTag)
        openShowModal()
    }

    const iconProps: SvgProps = {
        className: 'p-1 rounded-md hover:bg-[#00000010] cursor-pointer w-8 h-8 text-black',
        d: isEdit
            ? 'M21 7v12q0 .825-.588 1.413T19 21H5q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h12l4 4Zm-9 11q1.25 0 2.125-.875T15 15q0-1.25-.875-2.125T12 12q-1.25 0-2.125.875T9 15q0 1.25.875 2.125T12 18Zm-6-8h9V6H6v4Z'
            : 'M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83l3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75L3 17.25Z'
    }

    return (
        <li
            ref={cardRef}
            className={`p-4 mb-3 flex justify-between items-center border border-white shadow rounded-lg cursor-move ${
                accountRoomTag.type === 'team' ? 'bg-slate-200' : 'bg-white'
            }`}
            style={{ opacity }}>
            <div className='flex items-center'>
                {isEdit ? (
                    <input
                        data-testid={`labelCard-${accountRoomTag.id}-input`}
                        type='text'
                        onChange={e => setCurrentText(e.currentTarget.value)}
                        value={currentText}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    />
                ) : (
                    <p className='max-w-[360px] whitespace-nowrap overflow-hidden text-ellipsis ml-2 text-gray-700 font-semibold font-sans tracking-wide'>
                        {getLabelName(accountRoomTag)}
                    </p>
                )}
            </div>
            <div className='flex items-center'>
                <div className='bg-[#56BA8E] h-[20px] inline-block rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full py-1 px-2.5 text-center align-baseline text-xs font-bold leading-none text-white'>
                    {totalChatRoomSummary}
                </div>
                {index >= 0 && (
                    <>
                        <button
                            data-testid={`labelCard-${accountRoomTag.id}-editButton`}
                            onClick={onEdit}
                            className='action-button p-1 focus:outline-none focus:shadow-outline text-teal-500 hover:text-teal-600'>
                            <Svg {...iconProps} />
                        </button>
                        <button aria-label='Delete user' className='action-button p-1 focus:outline-none focus:shadow-outline' onClick={setCurrentRoomTag}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                <path fill='currentColor' fillRule='evenodd' d='M16.67 13.13C18.04 14.06 19 15.32 19 17v3h4v-3c0-2.18-3.57-3.47-6.33-3.87z' />
                                <circle cx='9' cy='8' r='4' fill='currentColor' fillRule='evenodd' />
                                <path
                                    fill='currentColor'
                                    fillRule='evenodd'
                                    d='M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4c-.47 0-.91.1-1.33.24a5.98 5.98 0 0 1 0 7.52c.42.14.86.24 1.33.24zm-6 1c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z'
                                />
                            </svg>
                        </button>
                        <button
                            data-testid={`labelCard-${accountRoomTag.id}-deleteButton`}
                            aria-label='Delete user'
                            className='action-button p-1 focus:outline-none focus:shadow-outline'
                            onClick={openModal}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 32 32'>
                                <g transform='translate(0 32) scale(1 -1)'>
                                    <path
                                        fill='currentColor'
                                        d='m24.778 21.42l-5.502-5.503l5.5-5.502l-2.827-2.83l-5.503 5.502l-5.502-5.502l-2.828 2.83l5.5 5.502l-5.5 5.502l2.83 2.828l5.5-5.502l5.5 5.502z'
                                    />
                                </g>
                            </svg>
                        </button>
                    </>
                )}
            </div>
        </li>
    )
}
