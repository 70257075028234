import { FC } from 'react'
import { MdHourglassEmpty } from 'react-icons/md/'
import { useRouter } from 'next/router'

import { composeUrlState } from '../../../hooks/router/useValidRoute'

interface NewScheduleProps {
    roomId: string
    closeMenu: () => void
    containerStyle: string
    iconStyle: string
}

export const NewSchedule: FC<NewScheduleProps> = ({ roomId, closeMenu, containerStyle, iconStyle }) => {
    const router = useRouter()
    const onClick = () => {
        router.push(composeUrlState({ f: 'RoomList', x: 'NewSchedule', r: roomId }))
        closeMenu()
    }

    return (
        <button onClick={onClick} className={containerStyle}>
            <MdHourglassEmpty className={iconStyle} />
            Schedule
        </button>
    )
}
