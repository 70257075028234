import { localStorage } from '../../pages/_app'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import _ from 'lodash'
import { api } from '@closer/api'
import { LoadingSpinner } from '@closer/web/components'
import { LocalStorageKey } from '@closer/watermelondb'
import { TenantUserAuth } from '@closer/types'
import { useAppSelector } from '@closer/redux-storage'
import { useMutation } from '@tanstack/react-query'

interface SalesforceOauthPageProps {
    type: 'salesforce' | 'hubspot'
}

export const OAuthComponent: React.FC<SalesforceOauthPageProps> = ({ type }) => {
    const router = useRouter()
    const tenantUser = useAppSelector(state => state.closerUser.tenantUser)
    const [closerAuth, setCloserAuth] = useState<TenantUserAuth | null | undefined>()

    const { isLoading, mutate, data } = useMutation(() => {
        const code = router.query['code'] as string
        if (type === 'salesforce') {
            return api.oauth.salesforceRequest({ code })
        } else {
            return api.oauth.hubSpotRequest({ code })
        }
    })

    useEffect(() => {
        const getCloserAuthFromLocal = async () => {
            const _closerAuth = await localStorage.getItem(LocalStorageKey.CLOSER_AUTH)
            if (_closerAuth) {
                tenantUser && router.query['code'] && mutate()
                setCloserAuth(_closerAuth)
            } else {
                setCloserAuth(null)
                if (router.query['code']) {
                    if (type === 'salesforce') {
                        router.push({ pathname: '/login', query: { redirect: 'oauth', code: router.query['code'], type: 'salesforce' } })
                    }
                    if (type === 'hubspot') {
                        router.push({ pathname: '/login', query: { redirect: 'oauth', code: router.query['code'], type: 'hubspot' } })
                    }
                }
            }
        }
        getCloserAuthFromLocal()
    }, [router, mutate, tenantUser, type])

    return (
        <div className='flex-row shrink-1 h-full w-full justify-center items-center bg-white'>
            <div className='flex flex-col px-4 justify-center items-center h-full w-full'>
                <div className='mb-12 md:mb-0 md:w-8/12 lg:w-6/12'>
                    <img
                        src='https://d1pnnwteuly8z3.cloudfront.net/images/30ba08ff-7870-4ce4-855e-cc07414e7915/b345644c-a350-44b9-bd80-4332b12161f5.png'
                        className='w-full'
                        alt='Phone image'
                    />
                </div>

                {_.isString(router.query['code']) ? (
                    closerAuth ? (
                        isLoading ? (
                            <>
                                <div className='text-slate-900'>OAuth Loading...</div>
                                <div>{isLoading && <LoadingSpinner />}</div>
                            </>
                        ) : !data ? (
                            <div className='text-slate-900'>OAuth Error</div>
                        ) : (
                            <div className='text-slate-900'>OAuth Success</div>
                        )
                    ) : (
                        <div>
                            <LoadingSpinner />
                        </div>
                    )
                ) : (
                    'Url Error'
                )}
            </div>
        </div>
    )
}
