import { useRouter } from 'next/router'
import { ComponentProps, FC } from 'react'

import { composeUrlState } from '@closer/web/hooks/router/useValidRoute'
import { ScheduleSnippetProps as HeadlessProps, ScheduleSnippet as HeadlessSnippet, ScheduleSnippetDataProps } from '@closer/headless-components/components/ScheduleSend/ScheduleSnippet'

import { hiBell, hiPencil } from '../Common/svgPaths'
import { Svg, SvgProps } from '../Common/Svg'

import { LoadingSpinner } from '../Common/LoadingSpinner'

interface ScheduleSnippetProps extends ScheduleSnippetDataProps {
    //
}

export const ScheduleSnippet: FC<ScheduleSnippetProps> = ({ roomId }) => {
    const router = useRouter()
    const headlessProps: HeadlessProps = {
        roomId,
        render: ({ schedule, sendTimeString, isExpanded, toggleExpansion }) => {
            const { message, messageType } = schedule
            const containerProps: ComponentProps<'div'> = {
                className: 'grid grid-cols-[min-content_1fr_min-content_min-content] gap-x-2 items-center px-4 py-2 text-right'
            }
            const viewAllProps: ComponentProps<'button'> = {
                className: 'row-span-2 text-sm text-neutral-400 whitespace-nowrap',
                onClick: () => router.push(composeUrlState({ f: 'ScheduledMessages' }))
            }
            const arrowProps: SvgProps = {
                fill: 'none',
                stroke: 'currentColor',
                strokeWidth: 3,
                d: isExpanded ? 'M4.5 15.75l7.5-7.5 7.5 7.5' : 'M19.5 8.25l-7.5 7.5-7.5-7.5',
                className: 'w-4 h-4 col-start-4 text-[#65BA8E] cursor-pointer',
                onClick: toggleExpansion
            }
            const editProps: SvgProps = {
                d: hiPencil,
                fill: 'currentColor',
                strokeWidth: 1.5,
                className: 'w-4 h-4 text-neutral-400 cursor-pointer',
                onClick: () => router.push(composeUrlState({ f: 'RoomList', x: 'EditSchedule', r: schedule.id }))
            }

            return (
                <div {...containerProps}>
                    {isExpanded && <button {...viewAllProps}>View All</button>}
                    {messageType === 'confirm_send' && <Svg d={hiBell} fill='#65BA8E' className='col-start-2 w-4 h-4 justify-self-end' />}
                    <span className='col-start-3 whitespace-nowrap text-sm text-[#65BA8E]'>{sendTimeString}</span>
                    <Svg {...arrowProps} />
                    {isExpanded && (
                        <div className='col-span-3 grid grid-cols-[1fr,_min-content] gap-2 items-center'>
                            <p>{message}</p>
                            <Svg {...editProps} />
                        </div>
                    )}
                </div>
            )
        },
        renderLoading: () => (
            <div className='py-2'>
                <LoadingSpinner size={4} />
            </div>
        ),
        renderEmpty: () => null,
        renderError: error => error.message
    }

    return <HeadlessSnippet {...headlessProps} />
}
