import Image from 'next/image'

import { ComponentProps, FC, Fragment } from 'react'

import { IPreviewItem } from '@closer/types'
import { clipRect, convertTextToColour, isMessage } from '@closer/utils'
import { TextContent as HeadlessContent, TextContentProps as HeadlessProps, MessageBubbleDataProps, TextContentDataProps } from '@closer/headless-components/components'

import { LoadingSpinner } from '../../Common'

import { MessageBubble } from '../MessageBubble'

import { CompactAudioContent } from './AudioContent'
import { CompactImageContent } from './ImageContent'
import { CompactLocationContent } from './LocationContent'
import { CompactVideoContent } from './VideoContent'
import { FileContent } from './FileContent'

export const TextContent: FC<TextContentDataProps> = dataProps => {
    const { event, isRelatedContent } = dataProps
    const headlessProps: HeadlessProps = {
        ...dataProps,
        render: ({ preview, body, timestamp, relatedEvent, senderName, isMine }) => {
            if (isRelatedContent && relatedEvent) {
                if (isMessage(relatedEvent, ['m.video'])) {
                    return <CompactVideoContent event={relatedEvent} isRelatedContent />
                }

                if (isMessage(relatedEvent, ['m.image'])) {
                    return <CompactImageContent event={relatedEvent} isRelatedContent />
                }

                if (isMessage(relatedEvent, ['m.location'])) {
                    return <CompactLocationContent event={relatedEvent} isRelatedContent />
                }

                if (isMessage(relatedEvent, ['m.audio'])) {
                    return <CompactAudioContent event={relatedEvent} isRelatedContent />
                }

                if (isMessage(relatedEvent, ['m.file'])) {
                    return <FileContent event={relatedEvent} isRelatedContent />
                }
                // TODO: support other msgType
            }

            const senderNameProps: ComponentProps<'p'> = {
                className: 'font-bold text-left text-sm',
                style: { color: senderName && (isMine ? '#56BA8E' : convertTextToColour(senderName)) }
            }

            const previewElement = preview && (preview['og:image'] || preview['og:title'] || preview['og:description']) && (
                <div className='grid grid-cols-[auto,1fr] my-1 rounded-md bg-[#00000010] overflow-hidden cursor-pointer' onClick={() => window.open(preview['og:url'])} key={`${dataProps.event.event_id}-preview`}>
                    {preview['og:image'] && <Image {...getPreviewImageSize(preview)} src={preview['og:image']} alt='link preview' />}
                    <div className='max-w-full self-center p-2 overflow-hidden'>
                        <p className='truncate font-semibold'>{preview['og:title']}</p>
                        <p className='truncate text-sm text-[#737D8C]'>{preview['og:title'] || preview['og:description'] ? preview['og:description'] : 'No description'}</p>
                    </div>
                </div>
            )
            const bodyElements = body.map((segment, idx) => {
                let segmentElement
                const commonProps = {
                    className: isRelatedContent ? 'text-[#00000075] truncate' : ''
                }

                // render string
                if (typeof segment === 'string') {
                    segmentElement = segment.startsWith('\n') ? <p {...commonProps}>{segment}</p> : <span {...commonProps}>{segment}</span>
                }
                // render as formatted text
                else if ('format' in segment) {
                    segmentElement = segment.text.startsWith('\n') ? <p {...commonProps}>{segment.text}</p> : <span {...commonProps}>{segment.text}</span>
                    if (segment.format.includes('em')) {
                        segmentElement = <em>{segmentElement}</em>
                    }
                    if (segment.format.includes('strong')) {
                        segmentElement = <strong>{segmentElement}</strong>
                    }
                    if (segment.format.includes('del')) {
                        segmentElement = <del>{segmentElement}</del>
                    }
                    if (segment.format.includes('code')) {
                        segmentElement = <code>{segmentElement}</code>
                    }
                }
                // render as link
                else {
                    const isUrl = 'url' in segment
                    const url = isUrl ? segment.url : `https://matrix.to/#/${segment.matrixId}`
                    const innerText = isUrl ? segment.url : segment.text

                    segmentElement = isRelatedContent ? (
                        <span {...commonProps}>{innerText}</span>
                    ) : (
                        <span className={`break-all ${isUrl ? 'text-blue-500 cursor-pointer' : 'text-purple-500'}`} onClick={isUrl ? () => window.open(url) : undefined}>
                            {innerText}
                        </span>
                    )
                }

                return <Fragment key={idx}>{segmentElement}</Fragment>
            })
            const timestampElement = (timestamp || !isRelatedContent) && (
                <span className='pl-1 text-right text-sm text-[#737D8C]' key={`${event.event_id}-timestamp`}>
                    {timestamp || (
                        <>
                            {/* this span is here to make room for the loading spinner */}
                            <span className='opacity-0'>.</span>
                            <div className='absolute p-1 right-0 bottom-0'>
                                <LoadingSpinner size={3} />
                            </div>
                        </>
                    )}
                </span>
            )

            // message bubble with a related event
            if (!isRelatedContent && event.content.related_event) {
                const relatedProps: MessageBubbleDataProps = {
                    event: event.content.related_event,
                    isRelatedContent: true,
                    fetchUntil: dataProps.fetchUntil
                }

                return (
                    <>
                        <MessageBubble {...relatedProps} />
                        {[previewElement, bodyElements, timestampElement]}
                    </>
                )
            }

            // messgae bubble that is the related event (i.e. inside another bubble)
            if (isRelatedContent) {
                return (
                    <div className='grid grid-rows-[min-content,_1fr] px-3 py-2'>
                        <p {...senderNameProps}>{senderName}</p>
                        <div className='overflow-hidden max-h-11'>{[previewElement, bodyElements]}</div>
                    </div>
                )
            }

            // message bubble without related event
            return [previewElement, bodyElements, timestampElement]
        }
    }

    return <HeadlessContent {...headlessProps} />
}

function getPreviewImageSize(preview: IPreviewItem) {
    const clippedSize = clipRect({
        w: preview['og:image:width'] || 42,
        h: preview['og:image:height'] || 42,
        min: { w: 42, h: 42 },
        max: { w: 98, h: 98 }
    })

    return {
        width: clippedSize.w,
        height: clippedSize.h
    }
}
