import Image, { ImageProps } from 'next/image'

import { ComponentProps, FC, useMemo } from 'react'

import { convertTextToColour } from '@closer/utils'
import { LocationContent as HeadlessContent, LocationContentProps as HeadlessProps, LocationContentDataProps } from '@closer/headless-components/components'
import { useContactOrGroupName, useMatrix } from '@closer/headless-components/hooks'

import { hiMapPin, Svg } from '../../Common'

export const LocationContent: FC<LocationContentDataProps> = dataProps => {
    const { event, isReplyEvent } = dataProps
    const headlessProps: HeadlessProps = {
        ...dataProps,
        render: ({ title, subtitle, loc: { lat, long }, timestamp, thumbnail }) => {
            if (isReplyEvent) {
                return <CompactLocationContent event={event} />
            }

            const imageProps: Omit<ImageProps, 'alt'> = {
                src: thumbnail.url,
                fill: true,
                sizes: '256px',
                className: 'rounded-lg cursor-pointer',
                style: { objectFit: 'cover' },
                onClick: () => window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${long}`)
            }

            return (
                <div className='grid grid-cols-[min-content]'>
                    <div className='relative w-64 h-48'>
                        <Image {...imageProps} alt={event.event_id} />
                    </div>
                    <div className='w-64'>
                        {title && <p className='pt-1 font-semibold'>{title}</p>}
                        {(subtitle || []).map((text, idx) => (
                            <p className='truncate text-[#737D8C]' key={idx}>
                                {text}
                            </p>
                        ))}
                    </div>
                    {timestamp && <span className='pl-2 text-right text-sm text-[#737D8C]'>{timestamp}</span>}
                </div>
            )
        }
    }

    return <HeadlessContent {...headlessProps} />
}

export const CompactLocationContent: FC<LocationContentDataProps> = ({ event }) => {
    const { info } = event.content
    const { client } = useMatrix()
    const { contactOrGroupName } = useContactOrGroupName({ matrixId: event.sender })
    const isMine = !!(client && client.getUserId() === event.sender)
    const senderName = isMine ? 'You' : contactOrGroupName || event.sender_name
    const previewProps = useMemo<Omit<ImageProps, 'alt'> | undefined>(() => {
        const src = info.thumbnail_url && client?.mxcUrlToHttp(info.thumbnail_url)

        if (!src) {
            return
        }

        return { src, fill: true, style: { objectFit: 'cover' } }
    }, [client, info.thumbnail_url])
    const senderNameProps: ComponentProps<'p'> = {
        className: 'font-bold text-left col-span-2 text-sm truncate',
        style: { color: senderName && (isMine ? '#56BA8E' : convertTextToColour(senderName)) }
    }

    return (
        <div className='grid grid-cols-[1fr,min-content] gap-1 min-h-[50px]'>
            <div className='grid grid-rows-[min-content,_1fr] grid-cols-[min-content,_1fr] p-2'>
                <div {...senderNameProps}>{senderName}</div>
                <Svg d={hiMapPin} className='w-5 h-5' stroke='none' fill='#65BA8E' fillRule='evenodd' clipRule='evenodd' />
                <p className='text-[#737D8C] px-2'>Location</p>
            </div>
            <div className='relative w-12 h-14'>{previewProps && <Image {...previewProps} alt='image preview' />}</div>
        </div>
    )
}
