import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { ComponentProps, FC, useState } from 'react'

import { getNextScheduleSendTime } from '@closer/headless-components/components/ScheduleSend'
import { ScheduleMessage } from '@closer/types'
import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { ScheduleListItem as HeadlessItem, ScheduleListItemProps as HeadlessProps, ScheduleListItemDataProps } from '@closer/headless-components/components/ScheduleSend/ScheduleListItem'

import { composeUrlState } from '../../hooks/router/useValidRoute'

import { LoadingSpinner } from '../Common/LoadingSpinner'
import { hiBell, hiPaperPlane, hiTrash } from '../Common/svgPaths'
import { Svg, SvgProps } from '../Common/Svg'

interface ScheduleListItemProps extends ScheduleListItemDataProps {
    //
}

const iconProps: SvgProps = { stroke: 'white', fill: 'none' }
const iconStyle = 'w-6 h-6 p-1 rounded-md text-white transition-opacity cursor-pointer'

export const ScheduleListItem: FC<ScheduleListItemProps> = ({ id, tenantUserId }) => {
    const router = useRouter()
    const queryClient = useQueryClient()
    const { readChatRoomSummary, updateChatRoomSummary } = useWDBOps()
    const [isHovered, setIsHovered] = useState(false)
    const iconHoverStyle = isHovered ? 'opacity-100' : 'opacity-0'
    const editSchedule = () => router.push(composeUrlState({ f: 'ScheduledMessages', x: 'Edit', r: id }))
    const handleScheduleMutation = async (schedule: ScheduleMessage) => {
        const chatRoomSummary = await readChatRoomSummary<{ nextScheduleSendTime: Date | null }>(schedule.roomId)

        await updateChatRoomSummary(schedule.roomId, getNextScheduleSendTime(schedule, chatRoomSummary?.nextScheduleSendTime || null, queryClient))

        router.push(composeUrlState({ f: 'ScheduledMessages' }))
    }
    const headlessProps: HeadlessProps = {
        id,
        tenantUserId,
        renderItem: ({ room, subCategory, message, type, time, isMutating, sendSchedule, deleteSchedule }) => {
            const containerProps: ComponentProps<'div'> = {
                className: `grid grid-cols-[1fr_repeat(3,_min-content)] gap-x-2 items-center px-5 py-2 border-b-[1px] ${isMutating ? 'opacity-50 pointer-events-none' : ''}`,
                onPointerOver: () => setIsHovered(true),
                onPointerOut: () => setIsHovered(false)
            }
            const messageProps: ComponentProps<'p'> = {
                className: `col-span-2 text-[#737D8C] ${subCategory === 'Active' ? 'cursor-pointer' : ''}`,
                onClick: subCategory === 'Active' ? editSchedule : undefined
            }
            const sendProps: SvgProps = {
                ...iconProps,
                d: hiPaperPlane,
                className: `${iconStyle} ${iconHoverStyle} bg-[#56BA8E]/80`,
                onClick: sendSchedule
            }
            const deleteProps: SvgProps = {
                ...iconProps,
                d: hiTrash,
                className: `${iconStyle} ${iconHoverStyle} bg-[#FF3B30]/80`,
                onClick: deleteSchedule
            }

            return (
                <div {...containerProps}>
                    <h1 className={`text-lg ${room.name ? '' : 'text-neutral-400'}`}>{room.name || 'Unkown'}</h1>
                    <p className={`${type === 'confirm_send' ? 'col-span-2' : 'col-span-3'} text-xs text-[#AF52DE] whitespace-nowrap`}>{time.text}</p>
                    {type === 'confirm_send' && <Svg paths={[{ d: hiBell, fillRule: 'evenodd', clipRule: 'evenodd' }]} fill='#65BA8E' className='w-3 h-3' />}
                    <p {...messageProps}>{message.text}</p>
                    <div className='col-start-3 col-span-2 grid grid-cols-[1fr_min-content] gap-1 place-items-end'>
                        {subCategory === 'Active' && <Svg {...sendProps} />}
                        <Svg {...deleteProps} />
                    </div>
                </div>
            )
        },
        renderLoading: () => <LoadingSpinner />,
        renderError: () => <p className='px-5 py-2 border-b text-neutral-400'>Conversation Not Found</p>,
        onSend: handleScheduleMutation,
        onRemove: handleScheduleMutation
    }

    return <HeadlessItem {...headlessProps} />
}
