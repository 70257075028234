import { Q } from '@nozbe/watermelondb'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useRouter } from 'next/router'
import { Action, Priority, useRegisterActions } from 'kbar'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { AccountRoomTag, TableName } from '@closer/watermelondb'
import { useArchiveState, useChatRoomSummary } from '@closer/headless-components/hooks'

import { useLabelMutationByTagIds, usePinMutation } from '../components/RoomList/hooks'

import { composeUrlState } from './router/useValidRoute'
import { getLabelName } from '@closer/utils'

interface Props {
    roomId: string
    isActive: boolean
    onCompletePin?: () => void
    onCompleteArchive?: () => void
    onCompleteLabel?: () => void
}
export const useRegisterRoomAction = ({ roomId, isActive, onCompletePin, onCompleteArchive, onCompleteLabel }: Props) => {
    const { room } = useChatRoomSummary(roomId)
    const [labeledTags, setLabeledTags] = useState<{ id: string; type: 'private' | 'team' | 'default' }[]>([])
    const [accountRoomTags, setAccountRoomTags] = useState<AccountRoomTag[]>([])
    const database = useDatabase()
    const router = useRouter()
    const pinTime = room?.pinTime
    const archive = room?.archive

    const { isLoading: isLoadingPin, mutate: mutatePin } = usePinMutation(onCompletePin)
    const { isArchiving, toggleState } = useArchiveState({ roomId, onToggle: onCompleteArchive })
    const { isLoading: _isLoadingLabel, mutate: mutateLabel } = useLabelMutationByTagIds(onCompleteLabel)

    const performLabel = useCallback(
        (checked: boolean, accountRoomTag: AccountRoomTag) => {
            if (checked) {
                mutateLabel({ roomId, labels: labeledTags.filter(v => v.id !== accountRoomTag.id) })
            } else {
                mutateLabel({ roomId, labels: [...labeledTags, { id: accountRoomTag.id, type: accountRoomTag.type }] })
            }
        },
        [labeledTags, mutateLabel, roomId]
    )
    const selectedChatRoomActions = useMemo(() => {
        if (!isActive || !room) {
            return []
        }
        const { id, name } = room
        const actions: Action[] = [
            {
                id: `pin-${id}`,
                name: pinTime ? `Unpin ${name}` : `Pin ${name}`,
                priority: 2,
                shortcut: ['m', 'p'],
                perform: () => mutatePin({ roomId: id, pin: pinTime === null ? new Date() : null })
            },
            {
                id: `archive-${id}`,
                name: archive ? `Unarchive ${name}` : `Archive ${name}`,
                priority: 2,
                shortcut: ['m', 'a'],
                perform: toggleState
            },
            {
                id: `label-${id}`,
                name: `Move ${name} to a label`,
                priority: 2,
                shortcut: ['m', 'l']
            },
            // TODO: add reminder options for different time options
            {
                id: `remind-${id}`,
                name: `Create new reminder for ${name}`,
                priority: 2,
                shortcut: ['c', 'r'],
                perform: () => router.push(composeUrlState({ f: 'Reminders', x: 'Create', r: id }))
            },
            {
                id: `schedule-${id}`,
                name: `Schedule a message for ${name}`,
                shortcut: ['c', 's'],
                priority: 2,
                perform: () => router.push(composeUrlState({ f: 'ScheduledMessages', x: 'Create', r: id }))
            },
            // {
            //     id: `smartflow-${id}`,
            //     name: `Create new smartflow for ${name}`,
            //     priority: 2
            // },
            ...accountRoomTags.map(accountRoomTag => {
                const checked = labeledTags.some(tag => tag.id === accountRoomTag.id)
                return {
                    parent: `label-${id}`,
                    name: getLabelName(accountRoomTag),
                    priority: Priority.HIGH,
                    id: accountRoomTag.id,
                    icon: <input type='checkbox' defaultChecked={checked} />,
                    perform: () => performLabel(checked, accountRoomTag)
                }
            })
        ]
        return actions
    }, [accountRoomTags, archive, isActive, labeledTags, mutatePin, performLabel, pinTime, room, router, toggleState])

    useEffect(() => {
        const labelTable = database.get<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS)

        const labeledTagsSubscribe = labelTable
            .query(Q.on(TableName.ROOM_LABEL_RECORDS, 'roomId', roomId))
            .observe()
            .subscribe(v => setLabeledTags(v.map(tag => ({ id: tag.id, type: tag.type }))))

        return () => labeledTagsSubscribe.unsubscribe()
    }, [database, roomId])

    useEffect(() => {
        const labelTable = database.get<AccountRoomTag>(TableName.ACCOUNT_ROOM_TAGS)
        const accountRoomTagsSubscribe = labelTable.query(Q.sortBy('order', Q.asc)).observe().subscribe(setAccountRoomTags)
        return () => accountRoomTagsSubscribe.unsubscribe()
    }, [database])

    useRegisterActions(selectedChatRoomActions, [selectedChatRoomActions])

    return {
        isLoading: isArchiving || isLoadingPin || _isLoadingLabel
    }
}
