import Image from 'next/image'

import { FC } from 'react'
import { useRouter } from 'next/router'
// import { useTranslation } from 'react-i18next'

import { SideMenu as HeadlessSideMenu, SideMenuProps as HeadlessSideMenuProps } from '@closer/headless-components/components'

import { useTenantUserAuth } from '../../hooks/auth'
import { composeUrlState, useValidRoute } from '../../hooks/router/useValidRoute'

import { hiExclamationCircle, LoadingSpinner, Svg } from '../Common'

import header from './header.png'

interface SideMenuProps {
    //
}

export const SideMenu: FC<SideMenuProps> = () => {
    // const { t } = useTranslation()
    const auth = useTenantUserAuth()

    if (!auth) {
        return <LoadingSpinner />
    }

    return <AuthorisedSideMenu tenantUserId={auth.id} />
}

type AuthorisedSideMenuProps = {
    tenantUserId: string
}

type MenuItemPillProps = {
    message?: string | number
    errorMessage?: null | Error | Array<Error>
}

const MenuItemPill: FC<MenuItemPillProps> = ({ message, errorMessage }) => {
    if (Array.isArray(errorMessage) ? errorMessage.length : errorMessage) {
        return <Svg d={hiExclamationCircle} fillRule='evenodd' fill='currentColor' className='w-5 h-5 text-red-400 opacity-50' />
    }

    if (!message) {
        return null
    }

    return <div className='min-w-[20px] h-5 grid place-items-center text-xs px-1.5 rounded-full text-white bg-red-400'>{message}</div>
}

const AuthorisedSideMenu: FC<AuthorisedSideMenuProps> = ({ tenantUserId }) => {
    const router = useRouter()
    const { f, isValid } = useValidRoute({ f: _f => !!_f })
    const props: HeadlessSideMenuProps = {
        tenantUserId,
        render: ({ items, remindersCount, schedulesCount, remindersError, schedulesError, roomErrors }) => {
            const menuItems = items.map(({ item, label }, idx) => {
                if (item === 'Smartflows') {
                    return null
                }

                const isActive = isValid && f === item
                const remindersPill = item === 'Reminders' && <MenuItemPill message={remindersCount} errorMessage={roomErrors || remindersError} />
                const schedulesPill = item === 'ScheduledMessages' && <MenuItemPill message={schedulesCount} errorMessage={roomErrors || schedulesError} />

                return (
                    <div className={`grid grid-cols-[1fr,min-content] gap-x-2 items-center cursor-pointer px-3 py-2 rounded-lg ${isActive ? 'bg-neutral-100' : ''}`} onClick={() => router.push(composeUrlState({ f: item }))} key={idx}>
                        <p className='text-[15px] whitespace-nowrap'>{label}</p>
                        {remindersPill}
                        {schedulesPill}
                        {/* <p className='text-[15px] whitespace-nowrap'>{t(label)}</p> */}
                    </div>
                )
            })

            return (
                <div className='grid grid-rows-[min-content_1fr] gap-5 w-full h-full py-5'>
                    <Image src={header.src} className='m-auto w-40 max-w-none' width={160} height={0} alt='closer logo' />
                    <div className='overflow-x-hidden px-2 overflow-y-auto'>{menuItems}</div>
                </div>
            )
        }
    }

    return <HeadlessSideMenu {...props} />
}
