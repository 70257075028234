import { BiAlarmAdd } from 'react-icons/bi'
import { FC } from 'react'
import { useRouter } from 'next/router'

import { composeUrlState } from '../../../hooks/router/useValidRoute'

interface NewReminderProps {
    roomId: string
    closeMenu: () => void
    containerStyle: string
    iconStyle: string
}

export const NewReminder: FC<NewReminderProps> = ({ roomId, closeMenu, containerStyle, iconStyle }) => {
    const router = useRouter()
    const onClick = () => {
        router.push(composeUrlState({ f: 'RoomList', x: 'NewReminder', r: roomId }))
        closeMenu()
    }

    return (
        <button onClick={onClick} className={containerStyle}>
            <BiAlarmAdd className={iconStyle} />
            Reminder
        </button>
    )
}
