import { ComponentProps, FC } from 'react'

import { ArchiveButtonDataProps, ArchiveButton as HeadlessButton, ArchiveButtonProps as HeadlessProps } from '@closer/headless-components/components/Archive'

import { LoadingSpinner } from '../Common/LoadingSpinner'
import { biArchiveIn, biArchiveOut } from '../Common/svgPaths'
import { Svg, SvgProps } from '../Common/Svg'

export interface ArchiveButtonProps extends ArchiveButtonDataProps {
    buttonStyle: string
    spinnerSize: number
    svgStyle: string
}

export const ArchiveButton: FC<ArchiveButtonProps> = ({ roomId, buttonStyle, spinnerSize, svgStyle, showLabel, onToggle }) => {
    const headlessProps: HeadlessProps = {
        roomId,
        showLabel,
        onToggle,
        render: ({ archiveState, label, isLoading, isArchiving, toggleState }) => {
            const buttonProps: ComponentProps<'button'> = {
                className: buttonStyle,
                onClick: () => !isLoading && !isArchiving && toggleState()
            }
            const svgProps: SvgProps = {
                paths: (archiveState ? biArchiveOut : biArchiveIn).map(d => ({ d, fill: 'currentColor' })),
                className: `text-[#65BA8E] ${svgStyle}`
            }

            return (
                <button {...buttonProps}>
                    {isLoading || isArchiving ? <LoadingSpinner size={spinnerSize} /> : <Svg {...svgProps} />}
                    {label}
                </button>
            )
        }
    }

    return <HeadlessButton {...headlessProps} />
}
