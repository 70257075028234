import { createContext, FC, MutableRefObject, PropsWithChildren, useContext, useRef } from 'react'

interface FocusProviderProps extends PropsWithChildren {
    //
}

interface RestoreFocusElementRef {
    element: HTMLElement | null
    type: 'room' | 'search'
}

// FIXME: have to refactor
const FocusElementRefContext = createContext<MutableRefObject<RestoreFocusElementRef>>({ current: { element: null, type: 'room' } })

// FIXME: this context manages nothing internally, need to remove & refactor
export const RestoreFocusProvider: FC<FocusProviderProps> = ({ children }) => {
    const focusElementRef = useRef<RestoreFocusElementRef>({ element: null, type: 'room' })

    return <FocusElementRefContext.Provider value={focusElementRef}>{children}</FocusElementRefContext.Provider>
}

export const useFocusElementRefContext = () => useContext(FocusElementRefContext)
