import tinykeys from 'tinykeys'
import withObservables from '@nozbe/with-observables'

import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useRouter } from 'next/router'
import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react'
import { Database, Q } from '@nozbe/watermelondb'

import { hiddenUserList } from '@closer/utils'
import { ChatRoomSummary, RoomLabelRecord, TableName } from '@closer/watermelondb'

import { composeUrlState, useValidRoute } from '../../hooks/router/useValidRoute'

import { ArchiveBanner } from '../Archive/ArchiveBanner'
import { ModalPortal } from '../Common/ModalPortal'

import { RoomContextMenu } from './RoomContextMenu'
import { useRoomContextMenu } from './hooks/useRoomContextMenu'
import { RoomListRow, RoomListRowProps } from './RoomListRow'

interface RoomListProps {
    chatRoomSummaries: ChatRoomSummary[]
    tagId: string
    numberOfArchived: number
}

const _EnhancedRoomList: React.FC<RoomListProps> = ({ chatRoomSummaries, numberOfArchived }) => {
    const roomListRef = useRef<HTMLDivElement | null>(null)
    const { openMenu, closeMenu, menuOpened, selectedRoom, point } = useRoomContextMenu()
    const { r } = useValidRoute()
    const routeIndex = r ? chatRoomSummaries.findIndex(chat => chat.id === r) : 0
    const [selectedIndex, setSelectedIndex] = useState(routeIndex > -1 ? routeIndex : 0)

    const router = useRouter()
    const navigateToRoom = useCallback((roomId: string) => router.push(composeUrlState({ f: 'RoomList', x: 'View', r: roomId })), [router])

    const roomListContainerProps: ComponentProps<'div'> = {
        className: 'h-full overflow-y-auto',
        ref: element => (roomListRef.current = element),
        tabIndex: 0
    }

    useEffect(() => {
        if (selectedIndex > chatRoomSummaries.length - 1 && chatRoomSummaries.length !== 0) {
            setSelectedIndex(chatRoomSummaries.length - 1)
        }
        const focusElement = (index: number) => {
            const element = roomListRef.current?.children[index]
            if (element instanceof HTMLElement) {
                element.focus()
            }
        }
        const unsubscribe = tinykeys(window, {
            ['$mod+ArrowLeft']: event => {
                event.preventDefault()
                if (selectedIndex < 0 || selectedIndex > chatRoomSummaries.length - 1) {
                    setSelectedIndex(0)
                    return focusElement(0)
                }
                focusElement(selectedIndex)
            }
        })

        return () => unsubscribe()
    }, [chatRoomSummaries.length, selectedIndex])

    useEffect(() => {
        if (!roomListRef.current) {
            return
        }
        const unsubscribe = tinykeys(roomListRef.current, {
            ['ArrowUp']: event => {
                event.preventDefault()
                setSelectedIndex(prev => (prev === 0 ? 0 : prev - 1))
            },
            ['ArrowDown']: event => {
                event.preventDefault()
                setSelectedIndex(prev => (prev === chatRoomSummaries.length - 1 ? chatRoomSummaries.length - 1 : prev + 1))
            },
            ['Enter']: event => {
                event.preventDefault()
                navigateToRoom(chatRoomSummaries[selectedIndex].id)
            }
        })
        return () => unsubscribe()
    }, [chatRoomSummaries, navigateToRoom, selectedIndex])

    return (
        <>
            <ArchiveBanner numberOfArchived={numberOfArchived} onClick={() => router.push(composeUrlState({ f: 'Archive' }))} />
            {menuOpened && (
                <ModalPortal selector={'#modal'}>
                    <RoomContextMenu point={point} room={selectedRoom} closeMenu={closeMenu} />
                </ModalPortal>
            )}
            <div {...roomListContainerProps}>
                {chatRoomSummaries.map((chatRoomSummary, i) => {
                    const isSelected = i === selectedIndex
                    const roomListRowProps: RoomListRowProps = {
                        handleClick: () => {
                            setSelectedIndex(i)
                            navigateToRoom(chatRoomSummary.id)
                        },
                        chatRoomSummary,
                        openMenu: (x, y, room) => {
                            setSelectedIndex(i)
                            openMenu(x, y, room)
                        },
                        isSelected
                    }
                    return <RoomListRow key={chatRoomSummary.id} {...roomListRowProps} />
                })}
            </div>
        </>
    )
}

const roomListQuery = (database: Database, tagId: string, search: string) => {
    const baseQ = database.get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
    const filterHiddenQ = Q.where('name', Q.notIn(hiddenUserList))
    const filterArchivedOrSearchQ = search.length > 0 ? Q.where('name', Q.like(`%${Q.sanitizeLikeString(search)}%`)) : Q.where('archive', Q.notEq(true))
    const reminderSortQ = Q.sortBy('passedReminderTime', Q.desc)
    const pinSortQ = Q.sortBy('pinTime', Q.desc)
    const sort = Q.sortBy('timestamp', Q.desc)
    const archivedCountQ = Q.where('archive', true)
    const inboxQ = Q.unsafeLokiTransform((rawRecords, loki) => {
        const labelRecords = loki.getCollection(TableName.ROOM_LABEL_RECORDS).data.map((record: Partial<RoomLabelRecord>) => record.roomId)
        return rawRecords.filter(rawRecord => {
            return !labelRecords.includes(rawRecord.id)
        })
    })
    const typeQ = tagId === 'tag-all' ? undefined : tagId === 'tag-inbox' ? inboxQ : Q.on(TableName.ROOM_LABEL_RECORDS, 'accountRoomTagId', tagId)

    const chatRoomSummariesQueries = [filterHiddenQ, typeQ || [], filterArchivedOrSearchQ, reminderSortQ, pinSortQ, sort].flat()
    const numberOfArchivedQueries = [archivedCountQ, typeQ || []].flat()

    return {
        chatRoomSummaries: baseQ.query(...chatRoomSummariesQueries).observeWithColumns(['timestamp', 'pinTime', 'archive', 'passedReminderTime', 'nextReminderTime', 'nextScheduleSendTime', 'snippet', 'avatar', 'tag']) as any,
        numberOfArchived: baseQ.query(...numberOfArchivedQueries).observeCount(false) as any
    }
}

const enhance = withObservables(['search', 'tagId'], ({ database, search, tagId }: { database: Database; search: string; tagId: string }) => {
    return roomListQuery(database, tagId, search)
})
const EnhancedRoomList = enhance(_EnhancedRoomList)

interface RoomListObserveProps {
    search: string
    tagId: string
}

export const RoomList: React.FC<RoomListObserveProps> = ({ search, tagId }) => {
    // const { t } = useTranslation()
    const database = useDatabase()

    return <EnhancedRoomList database={database} search={search} tagId={tagId} />
}
