import { useRouter } from 'next/router'
import { FC, MouseEvent, useEffect, useState } from 'react'

import { formatDate, getTimeStringFromNow } from '@closer/utils'

import { ReminderBanner as HeadlessBanner, ReminderBannerProps as HeadlessBannerProps, ReminderSnippet as HeadlessSnippet, ReminderSnippetProps as HeadlessSnippetProps, PartialSnippetProps } from '@closer/headless-components/components/Reminders/ReminderBanner'

import { composeUrlState } from '../../hooks/router/useValidRoute'

import { LoadingSpinner } from '../Common/LoadingSpinner'
import { Svg } from '../Common/Svg'
import { msArrowBackIos, msArrowForwardIos, msCheckRounded } from '../Common/svgPaths'

interface ReminderBannerProps {
    roomId: string
}

export const ReminderBanner: FC<ReminderBannerProps> = ({ roomId }) => {
    const router = useRouter()
    const [displayingIndex, setDisplayingIndex] = useState(0)
    const headlessBannerProps: HeadlessBannerProps = {
        roomId,
        handlePress: reminder => router.push(composeUrlState({ f: 'RoomList', x: 'EditReminder', r: reminder.id })),
        handleComplete: () => setDisplayingIndex(prev => (prev === 0 ? 0 : prev - 1)),
        renderBanner: ({ reminderSnippets }) => {
            const snippets = reminderSnippets.map((props, index) => {
                const snippetProps = {
                    ...props,
                    showArrows: reminderSnippets.length > 1,
                    onPressLast: () => setDisplayingIndex(prev => (prev === 0 ? reminderSnippets.length - 1 : prev - 1)),
                    onPressNext: () => setDisplayingIndex(prev => (prev === reminderSnippets.length - 1 ? 0 : prev + 1))
                }

                return <ReminderSnippet {...snippetProps} key={index} />
            })
            return <div>{snippets.length > 0 ? snippets[displayingIndex] : null}</div>
        },
        renderLoading: () => (
            <div className='py-2'>
                <LoadingSpinner size={4} />
            </div>
        ),
        renderError: error => <div>{(error as Error).message}</div>,
        renderUpdateError: error => <div>{(error as Error).message}</div>
    }

    useEffect(() => setDisplayingIndex(0), [roomId])

    return <HeadlessBanner {...headlessBannerProps} />
}

interface ReminderSnippetProps extends PartialSnippetProps {
    showArrows: boolean
    onPressLast: () => void
    onPressNext: () => void
}

const ReminderSnippet: FC<ReminderSnippetProps> = ({ title, remindTime, showArrows, onDelayedComplete, onEdit, onPressNext, onPressLast }) => {
    const handlePressLast = (event: MouseEvent) => {
        event.stopPropagation()
        onPressLast()
    }
    const handlePressNext = (event: MouseEvent) => {
        event.stopPropagation()
        onPressNext()
    }

    const headlessProps: HeadlessSnippetProps = {
        remindTime,
        onDelayedComplete,
        renderSnippet: ({ completed, remindTimeNotPassed, onPressDone }) => {
            const handlePressDone = (event: MouseEvent) => {
                event.stopPropagation()
                onPressDone()
            }

            return (
                <div className={`${remindTimeNotPassed ? 'bg-[#00000025]' : 'bg-[#b9e9cb]'} grid grid-cols-[min-content,_minmax(100px,_1fr),_min-content] items-center`}>
                    <div className='w-8 h-8'>{showArrows && <Svg onClick={handlePressLast} d={msArrowBackIos} className='w-8 h-8 cursor-pointer' fill='gray' />}</div>
                    <div onClick={onEdit} className='grid grid-rows-2 grid-cols-[1fr,_min-content] m-2 items-center overflow-hidden cursor-pointer'>
                        <div className='truncate'>{title}</div>
                        <div className='row-start-2 text-[14px] truncate'>{remindTimeNotPassed ? formatDate(remindTime) : getTimeStringFromNow(remindTime)}</div>
                        <div className='col-start-2 row-span-2 px-2'>
                            <div onClick={handlePressDone} className={`w-8 h-8 rounded-full border-2 border-solid cursor-pointer relative ${completed ? 'border-[#00000025] bg-[#56BA8E]' : 'border-[#00000015] bg-white'}`}>
                                <Svg d={msCheckRounded} className='w-8 h-8 cursor-pointer absolute -top-0.5 -left-0.5' fill={completed ? 'white' : 'transparent'} />
                            </div>
                        </div>
                    </div>
                    <div className='w-8 h-8'>{showArrows && <Svg onClick={handlePressNext} d={msArrowForwardIos} className='w-8 h-8 cursor-pointer' fill='gray' />}</div>
                </div>
            )
        }
    }

    return <HeadlessSnippet {...headlessProps} />
}
