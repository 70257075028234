import { useRouter } from 'next/router'
import { Action, KBarAnimator, KBarOptions, KBarPortal, KBarPositioner, KBarProvider, KBarResults, useMatches } from 'kbar'
import { AdjustmentsHorizontalIcon, ClockIcon, ListBulletIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { FC, PropsWithChildren, useCallback, useEffect } from 'react'

import { composeUrlState } from '../../hooks/router/useValidRoute'

import { KBarSearch } from './KBarSearch'
import { ResultItem } from './ResultItem'
import { RestoreFocusProvider, RoomActionsProvider, useFocusElementRefContext } from '.'

export const CommandPalette: FC<PropsWithChildren> = ({ children }) => {
    const router = useRouter()
    const iconStyle = 'w-4 h-4'
    const initialActions: Action[] = [
        {
            id: 'roomlist',
            name: 'Go to Room List',
            shortcut: ['g', 'l'],
            keywords: 'rooms room message chat conversation',
            icon: <ListBulletIcon className={iconStyle} />,
            section: 'Pages',
            perform: () => router.push(composeUrlState({ f: 'RoomList' }))
        },
        {
            id: 'reminders',
            name: 'Go to Reminders',
            shortcut: ['g', 'r'],
            keywords: 'reminder remind',
            icon: <ClockIcon className={iconStyle} />,
            section: 'Pages',
            perform: () => router.push(composeUrlState({ f: 'Reminders' }))
        },
        {
            id: 'schedules',
            name: 'Go to Scheduled Messages',
            shortcut: ['g', 'm'],
            keywords: 'schedule message',
            icon: <PaperAirplaneIcon className={iconStyle} />,
            section: 'Pages',
            perform: () => router.push(composeUrlState({ f: 'ScheduledMessages' }))
        },
        // {
        //     id: 'smartflows',
        //     name: 'Go to Smartflow',
        //     shortcut: ['g', 's'],
        //     keywords: 'smartflow',
        //     icon: <MdShowChart className={iconStyle} />,
        //     section: 'Pages',
        //     perform: () => router.push(composeUrlState({ f: 'Smartflows' }))
        // },
        {
            id: 'settings',
            name: 'Go to Settings',
            shortcut: ['g', 't'],
            keywords: 'setting',
            icon: <AdjustmentsHorizontalIcon className={iconStyle} />,
            section: 'Pages',
            perform: () => router.push(composeUrlState({ f: 'Settings' }))
        }
        // todo add switch to different label
    ]
    const kBarOption: KBarOptions = {
        disableScrollbarManagement: true
    }

    return (
        <RestoreFocusProvider>
            <KBarProvider actions={initialActions} options={kBarOption}>
                <KBarPortal>
                    <KBarPositioner className='z-20 flex items-center bg-gray-400/70 p-2 backdrop-blur-sm'>
                        <KBarAnimator className='box-content w-full max-w-[600px] overflow-hidden rounded-xl border border-gray-400 bg-white/80 p-2'>
                            <KBarSearch className='flex h-16 w-full bg-transparent px-4 outline-none' />
                            <RenderResult />
                        </KBarAnimator>
                    </KBarPositioner>
                </KBarPortal>
                <RoomActionsProvider>{children}</RoomActionsProvider>
            </KBarProvider>
        </RestoreFocusProvider>
    )
}

const RenderResult: FC = () => {
    const { results, rootActionId } = useMatches()
    const focusElementRef = useFocusElementRefContext()

    const restoreFocus = useCallback(() => {
        focusElementRef.current.element?.focus()
    }, [focusElementRef])

    useEffect(() => {
        return () => restoreFocus()
    }, [restoreFocus])

    return (
        <KBarResults
            items={results}
            onRender={({ item, active }) =>
                typeof item === 'string' ? (
                    //
                    <div className='px-4 pt-3 pb-1 font-medium text-gray-500'>{item}</div>
                ) : (
                    //
                    <ResultItem action={item} active={active} currentRootActionId={rootActionId || ''} />
                )
            }
        />
    )
}
