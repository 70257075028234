import { FC } from 'react'

import { sanitiseName } from '@closer/utils'
import { RoomInfo, useAvatar } from '@closer/headless-components/index'

import { Avatar, AvatarProps } from '../Common/Avatar'

type RoomMemberProps = {
    member: RoomInfo['members'][number]
}
export const RoomMember: FC<RoomMemberProps> = ({ member }) => {
    const { avatarData } = useAvatar(member.id)
    const avatarProps: AvatarProps = {
        imageUrl: avatarData?.url,
        name: member.name
    }

    return (
        <div className='grid grid-cols-[max-content,1fr] p-2 gap-2' key={member.id}>
            <Avatar {...avatarProps} />
            <div className='grid grid-rows-2'>
                <div className='font-bold text-[16px]'>{member.isSelf ? 'You' : member.name}</div>
                <div>{sanitiseName(member.id)}</div>
            </div>
        </div>
    )
}
