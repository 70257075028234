import { Q } from '@nozbe/watermelondb'
import { useDatabase } from '@nozbe/watermelondb/hooks'
import { useRouter } from 'next/router'
import { useScrollViewTagStore } from '@closer/headless-components/components'
import { Action, Priority, useRegisterActions } from 'kbar'
import { FC, useEffect, useMemo, useState } from 'react'

import { hiddenUserList } from '@closer/utils'
import { ChatRoomSummary, TableName } from '@closer/watermelondb'

import { composeUrlState, useValidRoute } from '../../hooks/router/useValidRoute'

import { LabelScrollView } from '../LabelItem'
import { mdChatOutline } from '../Common/svgPaths'

import { ArchivedList } from '../Archive/ArchivedList'
import { ForwardList } from './ForwardList'
import { RoomList } from './RoomList'
import { RoomListSearchBar } from './RoomListSearchBar'

export const RoomListScreen: FC = () => {
    const { f, x } = useValidRoute({ f: _f => !_f || /^(RoomList|Archive)$/.test(_f) })
    const [search, setSearch] = useState('')
    // FIXME: remove store from front-end
    const [currentTagId] = useScrollViewTagStore(state => [state.currentTagId])
    const showArchive = f === 'Archive'
    const showForward = x === 'Forward'
    useKBarSearchChat()

    return (
        <div className='h-full overflow-hidden flex flex-col'>
            {showForward ? (
                <ForwardList />
            ) : (
                <>
                    <RoomListSearchBar scope={showArchive ? 'Archived Conversations' : 'Conversations'} setSearch={setSearch} />
                    {!showArchive ? <RoomList search={search} tagId={currentTagId} /> : <ArchivedList search={search} tagId={currentTagId} />}
                    <LabelScrollView />
                </>
            )}
        </div>
    )
}

const useKBarSearchChat = () => {
    const database = useDatabase()
    const router = useRouter()
    const [chatRoomSummaries, setChatRoomSummaries] = useState<ChatRoomSummary[]>([])
    const searchRoomActions = useMemo(() => {
        const actions: Action[] = [
            {
                id: 'conversations',
                name: 'Go to Conversation',
                icon: mdChatOutline,
                section: 'Conversations',
                priority: 1
            },
            ...chatRoomSummaries.map(chat => ({
                parent: 'conversations',
                id: chat.id,
                name: chat.name,
                priority: Priority.NORMAL,
                perform: () => router.push(composeUrlState({ f: chat.archive ? 'Archive' : 'RoomList', x: 'View', r: chat.id }))
            }))
        ]

        return actions
    }, [chatRoomSummaries, router])

    useEffect(() => {
        const subscribe = database
            .get<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
            .query(Q.where('name', Q.notIn(hiddenUserList)), Q.sortBy('timestamp', Q.desc))
            .observe()
            .subscribe(setChatRoomSummaries)

        return () => subscribe.unsubscribe()
    }, [database])

    useRegisterActions(searchRoomActions, [searchRoomActions])
}
