import { useRouter } from 'next/router'
import { ComponentProps, FC } from 'react'

import { ScheduleList as HeadlessList, ScheduleListProps as HeadlessProps, ScheduleListDataProps } from '@closer/headless-components/components/ScheduleSend/ScheduleList'

import { useTenantUserAuth } from '../../hooks/auth/useTenantUserAuth'
import { composeUrlState, useValidRoute } from '../../hooks/router/useValidRoute'

import { LoadingSpinner } from '../Common/LoadingSpinner'
import { NoContent } from '../Common/NoContent'
import { Svg } from '../Common/Svg'
import { mdHourglass, mdiSortAlphabeticalAscending, mdiSortClockAscending } from '../Common/svgPaths'

import { ScheduleListItem } from './ScheduleListItem'

const touchableStyle = 'text-neutral-400 hover:text-neutral-500 active:text-neutral-300 transition-all'

export const ScheduleList: FC = () => {
    const tenantUserAuth = useTenantUserAuth()

    if (!tenantUserAuth) {
        return (
            <div className='h-full'>
                <LoadingSpinner />
            </div>
        )
    }

    return <ScheduleListContent tenantUserId={tenantUserAuth.id} />
}

export const ScheduleListContent: FC<ScheduleListDataProps> = ({ tenantUserId }) => {
    const { x } = useValidRoute()
    const router = useRouter()
    const headlessProps: HeadlessProps = {
        tenantUserId,
        renderList: ({ schedules, subCategory, subCategoryToggleLabel, sentCount, sortingMethod, setSearchTerm, toggleSort, toggleSubCategory }) => {
            const inputProps: ComponentProps<'input'> = {
                placeholder: 'Search scheduled messages',
                className: 'w-full outline-none py-1 text-black bg-transparent',
                onChange: evt => setSearchTerm(evt.currentTarget.value)
            }
            const sentProps: ComponentProps<'button'> = {
                className: 'justify-self-start whitespace-nowrap',
                onClick: () => {
                    toggleSubCategory()
                    x && router.push(composeUrlState({ f: 'ScheduledMessages' }))
                }
            }

            return (
                <div className='grid grid-rows-[repeat(2,_min-content),_1fr] gap-2 overflow-hidden'>
                    <div className='grid grid-cols-[min-content_1fr_min-content] gap-2 items-center mx-2 px-2 rounded-lg overflow-hidden bg-neutral-100 text-[#00000075]'>
                        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-5 h-5'>
                            <path strokeLinecap='round' strokeLinejoin='round' d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z' />
                        </svg>
                        <input {...inputProps} />
                        <Svg d={sortingMethod === 'Alphabetical' ? mdiSortAlphabeticalAscending : mdiSortClockAscending} fill='currentColor' className={`${touchableStyle} cursor-pointer w-5 h-5`} onClick={toggleSort} />
                    </div>
                    <div className='grid grid-cols-[min-content_1fr_min-content] gap-2 items-center px-5 py-2 text-sm text-[#65BA8E] bg-[#F5F7FA]'>
                        <Svg d={mdHourglass} fill='currentColor' className='w-3 h-3' />
                        <button {...sentProps}>{subCategoryToggleLabel}</button>
                        {subCategory === 'Active' && <span>{sentCount}</span>}
                    </div>
                    <div className='overflow-y-auto'>
                        {schedules.map((schedule, idx) => (
                            <ScheduleListItem id={schedule.id} tenantUserId={tenantUserId} key={idx} />
                        ))}
                    </div>
                </div>
            )
        },
        renderEmpty: () => <NoContent />,
        renderLoading: () => <LoadingSpinner />,
        renderErrors: () => null
    }

    return <HeadlessList {...headlessProps} />
}
