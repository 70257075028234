import { labelRoomListStore } from '@closer/headless-components/components'
import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { ChatRoomSummary, TableName } from '@closer/watermelondb'
import { FC, useEffect, useMemo, useState } from 'react'

interface SelectRoomScrollViewItemProps {
    id: string
}

export const SelectRoomScrollViewItem: FC<SelectRoomScrollViewItemProps> = ({ id }) => {
    const [chatRoomSummary, setChatRoomSummary] = useState<ChatRoomSummary>()
    // FIXME: remove store from front-end
    const [handleUnSelect] = labelRoomListStore(state => [state.handleUnSelect])
    const { tableCurrentAction } = useWDBOps()

    const { findOne } = useMemo(() => {
        return tableCurrentAction<ChatRoomSummary>(TableName.CHAT_ROOM_SUMMARIES)
    }, [tableCurrentAction])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await findOne(id)
                if (result) {
                    setChatRoomSummary(result)
                }
            } catch (error) {}
        }
        fetchData()
    }, [findOne, id])

    return (
        <span className='mx-px inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800 whitespace-nowrap'>
            <span>{`${chatRoomSummary?.name ?? null}`}</span>
            <button onClick={() => handleUnSelect(id)} type='button' className='ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:text-gray-500 focus:text-white focus:outline-none'>
                <span className='sr-only'>Remove large option</span>
                <svg className='h-2 w-2' stroke='currentColor' fill='none' viewBox='0 0 8 8'>
                    <path strokeLinecap='round' strokeWidth='1.5' d='M1 1l6 6m0-6L1 7' />
                </svg>
            </button>
        </span>
    )
}
