import { AccountRoomTag } from '@closer/watermelondb'
import { api } from '@closer/api'
import { captureException } from '@sentry/nextjs'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { LabelList } from './LabelList'
import { LabelSelectRoomList } from './LabelSelectRoomList'
import { labelService } from 'apps/web/pages/_app'
import { LoadingSpinner } from '../Common'
import { FC, useState } from 'react'
import { LabelList as HeadlessLabelList, LabelListRenderProps } from '@closer/headless-components/components'

interface LabelModalViewProps {
    setOpenModal: (isOpen: boolean) => void
}

export const LabelModalView: FC<LabelModalViewProps> = ({ setOpenModal }) => {
    const Render = (props: LabelListRenderProps) => {
        const { accountRoomTags } = props
        const [isCreating, setIsCreating] = useState(false)
        const onCompleteCreate = (completed: boolean) => {
            setIsCreating(!completed)
        }
        const [isRefreshing, setIsRefreshing] = useState(false)

        const [currentRoomTag, setCurrentRoomTag] = useState<AccountRoomTag | null>()

        const refreshLabelDbRecords = async () => {
            try {
                console.log('refreshLabelDbRecords')
                const newRecords = await api.labelRecords.read()
                const newTags = await api.accountRoomTag.readV2()
                await labelService.clearAllLabelsInLocalDb()
                await labelService.clearAllLabelRecordInLocalDb()
                if (newTags) {
                    await labelService.updateAllLabels(newTags)
                }
                if (newRecords) {
                    await labelService.updateAllLabelRecords(newRecords)
                }
            } catch (error) {
                captureException(error)
            }
        }

        const onPressRefresh = async () => {
            // TODO: translation
            setIsRefreshing(true)
            await refreshLabelDbRecords()
            setIsRefreshing(false)
        }
        return (
            <>
                <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 '>
                    <div className='w-auto my-6 mx-auto max-w-3xl'>
                        <div className='opacity-25 fixed inset-0 bg-black' onClick={() => setOpenModal(false)} />
                        <div className='w-[680px] border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none'>
                            <div className='flex justify-center p-5 border-b border-solid border-slate-200 rounded-t'>
                                <div className='absolute'>
                                    <h3 className='text-2xl flex-grow text-center text-[#00000090]'>Labels</h3>
                                </div>
                                <div className='flex justify-between w-full'>
                                    <div>
                                        <button className={`${currentRoomTag ? 'visible' : 'invisible'} p-2`} onClick={() => setCurrentRoomTag(null)}>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='m12 20l-8-8l8-8l1.425 1.4l-5.6 5.6H20v2H7.825l5.6 5.6L12 20Z' />
                                            </svg>
                                        </button>
                                    </div>
                                    <div>
                                        <button onClick={onPressRefresh} className={`${!currentRoomTag ? 'visible' : 'invisible'} p-2`}>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 15 15'>
                                                <path
                                                    fill='none'
                                                    stroke='currentColor'
                                                    d='M7.5 14.5A7 7 0 0 1 3.17 2M7.5.5A7 7 0 0 1 11.83 13m-.33-3v3.5H15M0 1.5h3.5V5'
                                                />
                                            </svg>
                                        </button>
                                        <button className={`${!currentRoomTag ? 'visible' : 'invisible'} p-2`} onClick={() => setIsCreating(!isCreating)}>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                                                <path fill='currentColor' d='M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z' />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {isRefreshing ? (
                                <div className='h-[500px]'>
                                    <LoadingSpinner />
                                </div>
                            ) : currentRoomTag ? (
                                currentRoomTag && <LabelSelectRoomList tag={currentRoomTag} onComplete={() => setCurrentRoomTag(null)} />
                            ) : (
                                <div className='relative p-4 flex-auto h-[500px] overflow-y-auto'>
                                    <DndProvider backend={HTML5Backend}>
                                        <LabelList
                                            accountRoomTags={accountRoomTags}
                                            isCreating={isCreating}
                                            onCompleteCreate={onCompleteCreate}
                                            setCurrentRoomTag={setCurrentRoomTag}
                                        />
                                    </DndProvider>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return <HeadlessLabelList render={Render} />
}
