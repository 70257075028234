import { captureException } from '@sentry/nextjs'
import { IStateEvent } from 'matrix-js-sdk'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'

import { ChatRoomSummary } from '@closer/watermelondb'
import { isMessage } from '@closer/utils'
import { useMatrix } from '@closer/headless-components/hooks'
import { EventContent, IMessageContent, Q, RoomEvent } from '@closer/types'
import { ForwardList as HeadlessList, ForwardListProps as HeadlessListProps } from '@closer/headless-components/components/Room/ForwardList'

import { composeUrlState, useValidRoute } from '../../hooks/router/useValidRoute'

import { Stub } from '../Common/Stub'
import { Avatar, AvatarProps } from '../Common/Avatar'

import { RoomListSearchBar } from '.'

interface ForwardListProps {}

export const ForwardList: FC<ForwardListProps> = () => {
    const router = useRouter()
    const queryClient = useQueryClient()
    const [filter, setFilter] = useState('')
    const { r, isValid } = useValidRoute({ f: _f => _f === 'RoomList', x: _x => _x === 'Forward' })

    if (!isValid) {
        return <Stub label='Invalid Route' />
    }

    const resource = JSON.parse(r)
    const roomId = resource[0]
    const eventId = resource[1]
    const timelines = queryClient.getQueryData<InfiniteData<{ start: string; end: string; state: IStateEvent[]; chunk: RoomEvent<EventContent>[] } | undefined>>([Q.ROOM_TIMELINES, roomId])
    if (!timelines || !timelines.pages || timelines.pages.length === 0) {
        return <Stub label='Invalid Route' />
    }
    const events = timelines.pages.reduce((acc, page) => {
        if (!page) {
            return []
        }
        return [...acc, ...page.chunk]
    }, [] as RoomEvent<EventContent>[])
    const messageEvents = events.filter(_event => isMessage(_event, ['m.room.message']) && _event.event_id === eventId) as RoomEvent<IMessageContent>[]
    if (messageEvents.length === 0) {
        return <Stub label='Invalid Route' />
    }
    const event = messageEvents[0]

    const headlessListProps: HeadlessListProps = {
        event,
        filter,
        onOpenRoom: targetRoomId => router.replace(composeUrlState({ f: 'RoomList', x: 'View', r: targetRoomId })),
        onCaptureError: error => {
            console.log(error)
            captureException(error)
        },
        render: ({ chatRoomSummaries, isSending, forwardEvent }) => (
            <div className='grid grid-rows-[min-content,_1fr] overflow-hidden'>
                <RoomListSearchBar scope='target' setSearch={setFilter} />
                <div className='overflow-y-auto divide-y'>
                    {chatRoomSummaries.map(chat => (
                        <ForwardListItem chatRoomSummary={chat} isSending={isSending} forwardEvent={forwardEvent} key={chat.id} />
                    ))}
                </div>
            </div>
        )
    }

    return <HeadlessList {...headlessListProps} />
}

interface ForwardListItemProps {
    chatRoomSummary: ChatRoomSummary
    isSending: boolean
    forwardEvent: (id: string) => void
}

const ForwardListItem: FC<ForwardListItemProps> = ({ chatRoomSummary, isSending, forwardEvent }) => {
    const { avatar, id, name } = chatRoomSummary
    const { client } = useMatrix()
    const imageUrl = (avatar && client?.mxcUrlToHttp(avatar, 50, 50, 'scale')) ?? undefined
    const avatarProps: AvatarProps = {
        imageUrl,
        name: name
    }

    const handleClick = () => {
        if (isSending) {
            return
        }
        forwardEvent(id)
    }

    const itemContainerStyle = `p-2 grid grid-cols-[min-content,_1fr] items-center gap-2 ${isSending ? 'cursor-wait bg-[#00000015]' : 'cursor-pointer hover:bg-[#00000015]'}`

    return (
        <div onClick={handleClick} className={itemContainerStyle}>
            <Avatar {...avatarProps} />
            <div className='overflow-hidden'>
                <div className='truncate'>{name}</div>
            </div>
        </div>
    )
}
