// Custom KBarSearch component to fix cannot input Chinese issue
// A replacement of KBarSearch component from kbar
// import { KBarSearch } from 'kbar';
// Copied from: https://github.com/timc1/kbar/issues/237#issuecomment-1253691644

import { FC, InputHTMLAttributes, useEffect, useMemo, useState } from 'react'
import { useKBar, VisualState } from 'kbar'

const getListboxItemId = (id: number) => `kbar-listbox-item-${id}`
type KBarSearchProps = InputHTMLAttributes<HTMLInputElement> & { defaultPlaceholder?: string }
export const KBarSearch: FC<KBarSearchProps> = props => {
    const { query, searchQuery, actions, currentRootActionId, activeIndex, showing, options } = useKBar(state => ({
        searchQuery: state.searchQuery,
        currentRootActionId: state.currentRootActionId,
        actions: state.actions,
        activeIndex: state.activeIndex,
        showing: state.visualState === VisualState.showing
    }))
    const [search, setSearch] = useState(searchQuery)

    const { defaultPlaceholder, ...rest } = props

    useEffect(() => {
        query.setSearch('')
        query.getInput().focus()
        return () => query.setSearch('')
    }, [currentRootActionId, query])

    useEffect(() => {
        query.setSearch(search)
    }, [query, search])

    const placeholder = useMemo((): string => {
        const defaultText = defaultPlaceholder ?? 'Type a command or search…'
        return currentRootActionId && actions[currentRootActionId] ? actions[currentRootActionId].name : defaultText
    }, [actions, currentRootActionId, defaultPlaceholder])

    return (
        <input
            {...rest}
            ref={query.inputRefSetter}
            autoFocus
            autoComplete='off'
            role='combobox'
            spellCheck='false'
            aria-expanded={showing}
            aria-controls='kbar-listbox'
            aria-activedescendant={getListboxItemId(activeIndex)}
            value={search}
            placeholder={placeholder}
            onChange={event => {
                props.onChange?.(event)
                setSearch(event.target.value)
                options?.callbacks?.onQueryChange?.(event.target.value)
            }}
            onKeyDown={event => {
                props.onKeyDown?.(event)
                if (currentRootActionId && !search && event.key === 'Backspace') {
                    const parent = actions[currentRootActionId].parent
                    query.setCurrentRootAction(parent)
                }
            }}
        />
    )
}
