import { FC } from 'react'

import { ReminderList as HeadlessList, ReminderListProps as HeadlessProps, PartialReminderListProps } from '@closer/headless-components/components'

import { LoadingSpinner, NoContent } from '../Common'

import { ReminderGroup, ReminderGroupProps } from './ReminderGroup'
import { ReminderListItem, ReminderListItemProps } from './ReminderListItem'

interface ReminderListProps extends PartialReminderListProps {}

export const ReminderList: FC<ReminderListProps> = ({ filter, subCategory }) => {
    const headlessProps: HeadlessProps = {
        filter,
        subCategory,
        renderList: ({ roomMap, reminders, accessibilityLabel }) => (
            <div id={accessibilityLabel}>
                {reminders.map((item, idx) => {
                    if (Array.isArray(item)) {
                        const room = (roomMap || {})[item[0].roomId]

                        if (!room) {
                            return null
                        }

                        const groupProps: ReminderGroupProps = {
                            room,
                            reminders: item,
                            subCategory
                        }

                        return <ReminderGroup {...groupProps} key={idx} />
                    }

                    const props: ReminderListItemProps = {
                        reminder: item,
                        subCategory,
                        showRoomName: true
                    }

                    return <ReminderListItem {...props} key={idx} />
                })}
            </div>
        ),
        renderEmpty: () => <NoContent />,
        renderLoading: () => <LoadingSpinner />,
        renderErrors: errors => (
            <>
                {errors.map((error, idx) => (
                    <p key={idx}>{JSON.stringify(error)}</p>
                ))}
            </>
        )
    }

    return <HeadlessList {...headlessProps} />
}
