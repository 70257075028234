import { ComponentProps, FC } from 'react'

import { AudioContentDataProps, AudioContent as HeadlessContent, AudioContentProps as HeadlessProps } from '@closer/headless-components/components'
import { convertTextToColour, pad } from '@closer/utils'
import { useContactOrGroupName, useMatrix } from '@closer/headless-components/hooks'

import { Avatar, AvatarProps, hiMicrophone, Svg } from '../../Common'

export const AudioContent: FC<AudioContentDataProps> = dataProps => {
    const headlessProps: HeadlessProps = {
        ...dataProps,
        render: ({ url, avatar }) => {
            if (dataProps.isReplyEvent) {
                return <CompactAudioContent event={dataProps.event} />
            }

            const audioProps: ComponentProps<'audio'> = {
                src: url,
                controls: true,
                className: 'outline-none'
            }
            const avatarProps: AvatarProps = {
                imageUrl: avatar?.url,
                name: avatar?.title || '',
                isLoading: !avatar
            }

            return (
                <div className='grid grid-flow-col gap-x-2 place-items-center'>
                    <Avatar {...avatarProps} />
                    <audio {...audioProps} />
                </div>
            )
        }
    }

    return <HeadlessContent {...headlessProps} />
}

export const CompactAudioContent: FC<AudioContentDataProps> = ({ event }) => {
    const { info } = event.content
    const { client } = useMatrix()
    const { contactOrGroupName } = useContactOrGroupName({ matrixId: event.sender })
    const isMine = !!(client && client.getUserId() === event.sender)

    const senderName = isMine ? 'You' : contactOrGroupName || event.sender_name
    const duration = info.duration / 1000
    const senderNameProps: ComponentProps<'p'> = {
        className: 'font-bold text-left col-span-2 text-sm',
        style: { color: senderName && (isMine ? '#56BA8E' : convertTextToColour(senderName)) }
    }

    return (
        <div className='grid grid-rows-[min-content,_1fr] grid-cols-[min-content,_1fr] items-center min-h-[50px] p-2'>
            <p {...senderNameProps}>{senderName}</p>
            <Svg d={hiMicrophone} className='w-5 h-5' stroke='none' fill='#65BA8E' fillRule='evenodd' clipRule='evenodd' />
            <p className='px-2 text-neutral-500'>{pad(Math.floor(duration / 60)) + ':' + pad(Math.ceil(duration % 60))}</p>
        </div>
    )
}
