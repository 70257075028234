import { FC } from 'react'

import { IRoomNameContent, RoomEvent } from '@closer/types'
import { useContactOrGroupName, useMatrix } from '@closer/headless-components/hooks'

export const RoomNameChangeAnnouncement: FC<RoomEvent<IRoomNameContent>> = ({ sender, content, prev_content }) => {
    const { client } = useMatrix()
    const { contactOrGroupName } = useContactOrGroupName({ matrixId: sender })
    const isSelf = client?.getUserId() === sender

    return (
        <div className='w-full text-center py-0.5 whitespace-nowrap'>
            <span className='text-xs px-2 py-0.5 rounded text-neutral-500 bg-[#00000010] w-full overflow-hidden'>
                {contactOrGroupName === 'WhatsApp bridge bot' ? (
                    <span>The subject was changed </span>
                ) : (
                    <>
                        <span className='font-semibold'>{isSelf ? 'You' : contactOrGroupName}</span>
                        <span> changed the subject</span>
                    </>
                )}
                {prev_content?.name && (
                    <>
                        <span> from </span>
                        <span className='font-semibold truncate'>{prev_content.name}</span>
                    </>
                )}
                <span> to </span>
                <span className='font-semibold truncate'>{content.name}</span>
            </span>
        </div>
    )
}
