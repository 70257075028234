import Image, { ImageProps } from 'next/image'

import { ComponentProps, FC, useMemo } from 'react'

import { convertTextToColour } from '@closer/utils'
import { VideoContent as HeadlessContent, VideoContentProps as HeadlessProps, VideoContentDataProps } from '@closer/headless-components/components'
import { useContactOrGroupName, useMatrix } from '@closer/headless-components/hooks'

import { Stub } from '../../Common/Stub'
import { hiVideoCamera, Svg } from '../../Common'

import { MessageContent } from '.'

export const VideoContent: FC<VideoContentDataProps> = dataProps => {
    const { event, isReplyEvent } = dataProps
    const headlessProps: HeadlessProps = {
        ...dataProps,
        render: ({ url, width, height, timestamp }) => {
            if (isReplyEvent) {
                return <CompactVideoContent event={event} />
            }

            const videoProps: ComponentProps<'video'> = {
                src: url,
                width,
                height,
                controls: true,
                className: 'rounded-lg',
                style: { width, height }
            }
            const videoElement = url ? <video {...videoProps} /> : <Stub label='Invalid video URL' />

            return (
                <div className='grid'>
                    {videoElement}
                    {timestamp && <span className='pl-2 text-right text-sm text-[#737D8C]'>{timestamp}</span>}
                    {(event.co_events || []).map(coEvent => {
                        const coEventContentKey = coEvent.content.msgtype || coEvent.type
                        const CoEventContent = coEventContentKey in MessageContent ? MessageContent[coEventContentKey] : Stub

                        return (
                            <div className='px-1' style={{ width }} key={coEvent.event_id}>
                                <CoEventContent event={coEvent} />
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    return <HeadlessContent {...headlessProps} />
}

export const CompactVideoContent: FC<VideoContentDataProps> = ({ event }) => {
    const { info } = event.content
    const { client } = useMatrix()
    const { contactOrGroupName } = useContactOrGroupName({ matrixId: event.sender })
    const isMine = !!(client && client.getUserId() === event.sender)
    const senderName = isMine ? 'You' : contactOrGroupName || event.sender_name
    const previewProps = useMemo<Omit<ImageProps, 'alt'> | undefined>(() => {
        const src = info.thumbnail_url && client?.mxcUrlToHttp(info.thumbnail_url)

        if (!src) {
            return
        }

        return { src, fill: true, style: { objectFit: 'cover' } }
    }, [client, info.thumbnail_url])
    const senderNameProps: ComponentProps<'p'> = {
        className: 'font-bold text-left col-span-2 text-sm truncate',
        style: { color: senderName && (isMine ? '#56BA8E' : convertTextToColour(senderName)) }
    }

    return (
        <div className='grid grid-cols-[1fr,min-content] gap-1 items-center min-h-[50px] pl-2'>
            <div className='grid grid-rows-[min-content,_1fr] grid-cols-[min-content,_1fr]'>
                <div {...senderNameProps}>{senderName}</div>
                <Svg d={hiVideoCamera} className='w-5 h-5' stroke='none' fill='#65BA8E' fillRule='evenodd' clipRule='evenodd' />
                <p className='text-[#737D8C] px-2'>Video</p>
            </div>
            <div className='relative w-12 h-14'>{previewProps && <Image {...previewProps} alt='image preview' />}</div>
        </div>
    )
}
