import { FC } from 'react'
import { MdShowChart } from 'react-icons/md/'

interface NewSmartflowProps {
    roomId: string
    closeMenu: () => void
    containerStyle: string
    iconStyle: string
}

export const NewSmartflow: FC<NewSmartflowProps> = ({ roomId, closeMenu, containerStyle, iconStyle }) => {
    const onClick = () => {
        closeMenu()
    }

    return (
        <button onClick={onClick} className={containerStyle}>
            <MdShowChart className={iconStyle} />
            Smartflow
        </button>
    )
}
