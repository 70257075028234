import Calendar, { CalendarProps } from 'react-calendar'

import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { ComponentProps, FC, useEffect, useRef } from 'react'

import { useWDBOps } from '@closer/headless-components/hooks/useWDBOps'
import { getNextScheduleSendTime, ScheduleEditor as HeadlessEditor, ScheduleEditorProps as HeadlessProps } from '@closer/headless-components/components/ScheduleSend'

import { composeUrlState, useValidRoute } from '../../hooks/router/useValidRoute'

import { hiBell } from '../Common/svgPaths'
import { Stub } from '../Common/Stub'
import { Svg, SvgProps } from '../Common/Svg'

const iconProps: SvgProps = {
    stroke: 'currentColor',
    fill: 'none',
    className: 'w-4 h-4'
}

// TODO: indicate whether the schedule can be saved & enable/disable the save button accordingly
export const ScheduleEditor: FC = () => {
    const router = useRouter()
    const queryClient = useQueryClient()
    const messageRef = useRef<HTMLTextAreaElement>(null)
    const timeRef = useRef<HTMLInputElement>(null)
    const { readChatRoomSummary, updateChatRoomSummary } = useWDBOps()
    const { f, x, r, isValid } = useValidRoute([
        {
            f: 'RoomList',
            x: _x => _x === 'NewSchedule' || _x === 'EditSchedule',
            r: _r => _r.length > 0
        },
        {
            f: 'ScheduledMessages',
            x: _x => _x === 'Create' || _x === 'Edit',
            r: _r => _r.length > 0
        }
    ])

    useEffect(() => {
        messageRef.current && messageRef.current.focus()
    }, [])

    if (!isValid) {
        console.warn('ScheduleEditor', 'failed to load scheduled message', r)
        return <Stub label='Invalid Route' />
    }

    const now = new Date()
    const isCreating = r.startsWith('!')
    const headlessProps: HeadlessProps = {
        id: isCreating ? undefined : r,
        roomId: isCreating ? r : undefined,
        scheduleMutation: {
            onSuccess: async schedule => {
                const chatRoomSummary = await readChatRoomSummary<{ nextScheduleSendTime: Date | null }>(schedule.roomId)
                const nextScheduleSendTime = getNextScheduleSendTime(schedule, chatRoomSummary?.nextScheduleSendTime || null, queryClient)

                nextScheduleSendTime && updateChatRoomSummary(schedule.roomId, nextScheduleSendTime)

                // schedule has been deleted
                if (schedule.status === 'deleted') {
                    // return to room screen
                    if (x === 'NewSchedule') {
                        router.replace(composeUrlState({ f, x: 'View', r: schedule.roomId }))
                    }
                    // return to schedule list
                    else {
                        router.replace(composeUrlState({ f }))
                    }
                }
                // go to edit route
                else if (x === 'Create') {
                    router.replace(composeUrlState({ f, x: 'Edit', r: schedule.id }))
                }
                // return to room screen
                else if (x === 'NewSchedule') {
                    router.replace(composeUrlState({ f, x: 'View', r: schedule.roomId }))
                }
            }
        },
        render: ({ isMutating, message, messageType, sendTime, isValidTime, testId, updateMessage, updateTime, toggleMessageType, saveSchedule, deleteSchedule }) => {
            const messageProps: ComponentProps<'textarea'> = {
                className: 'border-[#56BA8E] focus:border-[#56BA8E] focus:ring-0 rounded-xl min-h-[42px]',
                value: message,
                placeholder: 'Message',
                onChange: evt => updateMessage(evt.currentTarget.value)
            }
            const calendarProps: CalendarProps = {
                value: sendTime,
                minDate: now,
                minDetail: 'decade',
                prevLabel: <Svg {...iconProps} className='w-4 h-4' d='M15.75 19.5L8.25 12l7.5-7.5' />,
                prev2Label: <Svg {...iconProps} className='w-4 h-4' d='M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5' />,
                nextLabel: <Svg {...iconProps} className='w-4 h-4' d='M8.25 4.5l7.5 7.5-7.5 7.5' />,
                next2Label: <Svg {...iconProps} className='w-4 h-4' d='M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5' />,
                onChange: (date: Date) => {
                    timeRef.current && updateTime(date, ...timeRef.current.value.split(':'))
                }
            }
            const timeProps: ComponentProps<'input'> = {
                value: `${sendTime.getHours().toString().padStart(2, '0')}:${sendTime.getMinutes().toString().padStart(2, '0')}`,
                type: 'time',
                min: '00:00',
                max: '23:59',
                className: 'text-lg font-semibold border-none bg-transparent focus:ring-0',
                onChange: evt => updateTime(sendTime, ...evt.currentTarget.value.split(':'))
            }
            const messageTypeProps: ComponentProps<'div'> = {
                tabIndex: 0,
                className: 'grid grid-cols-[1fr_min-content] gap-2 px-4 py-2 rounded-lg bg-[#00000008] cursor-pointer',
                onKeyDown: evt => evt.key === 'Enter' && toggleMessageType(),
                onClick: toggleMessageType
            }
            const saveProps: ComponentProps<'button'> = {
                disabled: isMutating,
                className: `rounded-xl bg-[#00000008] text-lg font-bold p-2 mt-2 ${isMutating || !isValidTime ? 'text-neutral-400 pointer-events-none' : 'text-[#56BA8E]'}`,
                onClick: () => {
                    if (!message.trim().length) {
                        // TODO: make branded alert
                        // eslint-disable-next-line no-alert
                        return alert('Message can not be empty')
                    }

                    saveSchedule()
                }
            }
            const deleteProps: ComponentProps<'button'> = {
                disabled: isMutating,
                className: `rounded-xl bg-[#00000008] text-lg font-bold p-2 ${isMutating ? 'text-neutral-400' : 'text-[#FF3B30]'}`,
                onClick: deleteSchedule
            }

            return (
                <div className='grid grid-rows-[repeat(6,_min-content)] gap-2 p-5 max-w-[300px] h-full overflow-auto m-auto' id={testId}>
                    <textarea {...messageProps} ref={messageRef} />
                    <Calendar {...calendarProps} />
                    <div className={`text-right rounded-lg ${isValidTime ? 'bg-[#00000008]' : 'bg-[#FF3B30]/20'}`}>
                        <input {...timeProps} ref={timeRef} />
                    </div>
                    <div {...messageTypeProps}>
                        <p>Confirm before send out</p>
                        <Svg d={hiBell} fill={messageType === 'auto_send' ? 'none' : '#65BA8E'} stroke={messageType === 'auto_send' ? '#65BA8E' : 'none'} className='w-5 h-6' />
                    </div>
                    <button {...saveProps}>Save</button>
                    {x === 'Edit' && <button {...deleteProps}>Delete</button>}
                </div>
            )
        },
        renderError: error => <Stub label={error.message} />
    }

    return <HeadlessEditor {...headlessProps} />
}
