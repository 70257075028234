import { useRouter } from 'next/router'
import { ComponentProps, FC, useEffect, useRef } from 'react'

import { concatenateBody, Mention } from '@closer/utils'
import { RoomInput as HeadlessInput, RoomInputProps as HeadlessProps, RoomInputDataProps, RoomInputRenderableData } from '@closer/headless-components/components'

import { composeUrlState } from '../../hooks/router/useValidRoute'
import { useModRightFocusHotKey } from '../../hooks'

import { faVcard, FlexInput, FlexInputProps, hiCancel, hiPaperPlane, mdCamera, mdHourglass, mdImage, miAttachment, Svg, SvgProps } from '../Common'
import { useFocusElementRefContext, useSetRoomActionsActiveContext, useSetRoomActionsContext } from '../CommandPalette'

import { MessageBubble } from './MessageBubble'

const removeReplyStyle = 'w-6 h-6 p-1 rounded-full bg-[#00000025] hover:bg-[#00000050] transition-all cursor-pointer'

interface RoomInputProps extends RoomInputDataProps {
    onAddFiles: (formData: FormData) => void
}

export const RoomInput: FC<RoomInputProps> = ({ onAddFiles, ...dataProps }) => {
    const headlessProps: HeadlessProps = {
        ...dataProps,
        render: renderProps => <RoomInputContent {...renderProps} {...dataProps} onAddFiles={onAddFiles} />
    }

    return <HeadlessInput {...headlessProps} />
}

const RoomInputContent: FC<RoomInputProps & RoomInputRenderableData> = ({ roomId, eventDraft, replyEvent, inputMode, mentions, isSending, isUploading, isPickingMention, toggleInputMode, removeMedia, updateEventDraft, removeReplyEvent, sendEvent, onAddFiles }) => {
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const isBusy = isSending || isUploading
    const focusElementRef = useFocusElementRefContext()
    const setRoomId = useSetRoomActionsContext()
    const setIsActive = useSetRoomActionsActiveContext()
    const status = isSending ? 'Sending...' : isUploading ? 'Uploading...' : ''
    const handleMentionSelection = (mention: Mention) => {
        if (!inputRef.current) {
            return
        }

        mentions.insert(mention, inputRef.current.selectionStart)
        inputRef.current.focus()
    }
    const handleFocus = () => {
        focusElementRef.current = { element: inputRef.current, type: 'room' }
        setRoomId(roomId)
        setIsActive(true)
    }
    const inputProps: FlexInputProps = {
        placeholder: 'Message',
        disabled: isSending || isUploading,
        value: status || concatenateBody(eventDraft?.content.body || [], { useAtSymbol: true }),
        containerStyle: 'row-start-3 col-start-2 ',
        className: `w-full h-full px-2 py-1 border rounded-lg bg-white outline-none ${isBusy ? 'text-[#00000050]' : 'text-black'}`,
        onKeyDown: ({ code, ctrlKey }) => {
            ctrlKey && code === 'Enter' && sendEvent()
        },
        onInput: ({ currentTarget: { value } }) => {
            removeMedia()
            updateEventDraft({ content: { body: [value] } })
        },
        onSelect: ({ currentTarget: { value, selectionStart, selectionEnd } }) => {
            const beforeCursor = value.slice(selectionStart - 1)

            if (selectionStart && !(selectionEnd - selectionStart) && (beforeCursor.startsWith('@ ') || beforeCursor === '@')) {
                mentions.show()
            }
            //
            else {
                mentions.hide()
            }
        },
        onFocus: handleFocus
    }
    const extraInputProps: ExtraInputProps = { roomId, onAddFiles }
    const moreButtonProps: SvgProps = {
        stroke: '#00000075',
        className: 'row-start-3 w-8 h-8 p-1.5 bg-slate-300 rounded-full cursor-pointer',
        d: inputMode === 'Extra' ? 'M6 18L18 6M6 6l12 12' : 'M12 4.5v15m7.5-7.5h-15',
        onClick: toggleInputMode
    }
    const sendButtonProps: SvgProps = {
        stroke: 'white',
        fill: 'none',
        className: `row-start-3 col-start-3 w-8 h-8 p-1.5 rounded-full ${!isBusy && eventDraft?.content.body ? 'bg-[#56BA8E] cursor-pointer' : 'bg-[#00000050]'} ${inputMode === 'Extra' ? 'hidden' : ''}`,
        d: hiPaperPlane,
        onClick: sendEvent
    }

    useEffect(() => {
        if (replyEvent && inputRef.current) {
            inputRef.current.focus()
        }
    }, [replyEvent])

    useEffect(() => {
        if (!inputRef.current || isBusy) {
            return
        }

        inputRef.current.focus()
    }, [isBusy, roomId])

    useModRightFocusHotKey(inputRef.current)

    return (
        <div className='p-2 grid grid-rows-[min-content_min-content] gap-x-2 grid-cols-[min-content_1fr_min-content] items-center bg-[#00000008]'>
            <Svg {...moreButtonProps} />
            {inputMode === 'Extra' ? (
                <ExtraInput {...extraInputProps} />
            ) : (
                <>
                    {isPickingMention && (
                        <div className='col-start-2 col-span-full grid justify-items-start gap-1 pb-2'>
                            {mentions.members.current.map(mention => {
                                const mentionProps: ComponentProps<'button'> = {
                                    className: 'w-full text-left hover:bg-neutral-200 px-2 py-1 rounded transition-colors',
                                    onClick: () => handleMentionSelection(mention)
                                }

                                return (
                                    <button {...mentionProps} key={mention.matrixId}>
                                        {mention.text}
                                    </button>
                                )
                            })}
                        </div>
                    )}
                    {replyEvent && (
                        <div className='col-start-2 grid grid-cols-[min-content_min-content] gap-2 place-items-center'>
                            <MessageBubble event={replyEvent} isRelatedContent isReplyEvent />
                            <Svg d={hiCancel} stroke='white' className={removeReplyStyle} onClick={removeReplyEvent} />
                        </div>
                    )}
                    <FlexInput {...inputProps} ref={inputRef} />
                </>
            )}
            <Svg {...sendButtonProps} />
        </div>
    )
}

interface ExtraInputProps extends Pick<RoomInputDataProps, 'roomId'>, Pick<RoomInputProps, 'onAddFiles'> {
    //
}

const ExtraInput: FC<ExtraInputProps> = ({ roomId, onAddFiles }) => {
    const router = useRouter()
    // const [showVideoFeed, setShowVideoFeed] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)
    const fileRef = useRef<HTMLInputElement>(null)
    const svgProps: SvgProps = {
        fill: '#56BA8E',
        stroke: 'none',
        className: 'w-6 h-6 cursor-pointer'
    }
    // const toggleVideoFeed = () => {
    //     navigator.mediaDevices
    //         .getUserMedia({ video: { width: 320 } })
    //         .then(stream => {
    //             for (const track of stream.getTracks()) {
    //                 track.stop()
    //             }
    //         })
    //         .catch(err => {
    //             console.warn('toggleVideoFeed', err)
    //         })

    //     setShowVideoFeed(!showVideoFeed)
    // }
    const attachmentProps: ComponentProps<'input'> = {
        type: 'file',
        className: 'hidden',
        onChange: ({ target }) => {
            if (!target.files || target.files.length === 0 || !formRef.current) {
                return
            }

            const formData = new FormData(formRef.current)

            Array.from(target.files).forEach(file => formData.append(file.name, file))
            onAddFiles(formData)
        }
    }

    return (
        <div className='col-start-2 row-start-3 px-2 py-[9px] grid gap-x-8 grid-cols-[repeat(5,_min-content)] items-center'>
            <Svg {...svgProps} fill='#00000050' d={mdCamera} />
            <Svg {...svgProps} d={mdImage} onClick={() => fileRef.current && fileRef.current.click()} />
            <Svg {...svgProps} d={miAttachment} onClick={() => fileRef.current && fileRef.current.click()} />
            <Svg {...svgProps} d={mdHourglass} onClick={() => router.push(composeUrlState({ f: 'RoomList', x: 'NewSchedule', r: roomId }))} />
            <Svg {...svgProps} fill='#00000050' box={{ x: 0, y: 0, w: 2048, h: 1536 }} d={faVcard} />

            <form ref={formRef}>
                <input {...attachmentProps} ref={fileRef} />
            </form>

            {/* {showVideoFeed && <VideoFeed addMedia={addMedia} />} */}
        </div>
    )
}

// TODO: enable camera feature
// const VideoFeed: FC<Pick<ExtraInputProps, 'addMedia'>> = ({ addMedia }) => {
//     const fileRef = useRef<HTMLInputElement>(null)
//     const videoRef = useRef<HTMLVideoElement>(null)
//     const photoRef = useRef<HTMLCanvasElement>(null)
//     const svgProps: SvgProps = {
//         fill: '#56BA8E',
//         stroke: 'none',
//         className: 'w-6 h-6 cursor-pointer'
//     }
//     const attachmentProps: ComponentProps<'input'> = {
//         type: 'file',
//         className: 'hidden',
//         onChange: ({ target }) => {
//             if (!target.files || target.files.length === 0) {
//                 return
//             }

//             addMedia(target.files[0], 'm.file')
//         }
//     }
//     const takePhoto = () => {
//         let ctx = photoRef.current?.getContext('2d')

//         if (!videoRef.current || !photoRef.current || !ctx) {
//             return
//         }

//         const width = 320
//         const height = 240

//         photoRef.current.width = width
//         photoRef.current.height = height

//         ctx.drawImage(videoRef.current, 0, 0, width, height)

//         const data = photoRef.current.toDataURL('image/jpeg')

//         console.log('takePhoto', data)
//     }

//     useEffect(() => {
//         navigator.mediaDevices
//             .getUserMedia({ video: { width: 320 } })
//             .then(stream => {
//                 if (videoRef.current?.srcObject) {
//                     for (const track of stream.getTracks()) {
//                         track.stop()
//                     }

//                     videoRef.current.srcObject = null
//                 } else if (videoRef.current) {
//                     videoRef.current.srcObject = stream
//                     videoRef.current.play()
//                 }
//             })
//             .catch(err => {
//                 console.warn('toggleVideoFeed', err)
//             })
//     }, [])

//     return (
//         <div className='absolute border top-2 right-2'>
//             <input {...attachmentProps} ref={fileRef} />
//             <video ref={videoRef} />
//             <canvas ref={photoRef} />
//             <Svg {...svgProps} className='w-12 h-12 ' d={mdCamera} onClick={takePhoto} />
//         </div>
//     )
// }
